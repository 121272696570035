import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/delete.svg';
import React, { useContext, useState } from 'react';
import { OxygenTimestampRecord } from '../../../../models/oxygen';
import { AddOxygenDialog, AddOxygenDialogResponse } from './AddOxygenDialog';
import { RecordId } from '../../../../backendModels/records.model';
import { CardId } from '../../../../models/diviCard';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { NodeType } from '../../../../backendModels/report.model';
import { getFormattedTime } from '../../../../utils/util';

interface OxygenProps {
  nodeType: NodeType;
}

export function OxygenCard({ nodeType }: OxygenProps) {
  const [addOxygenDialogOpen, setAddOxygenDialogOpen] = useState<boolean>(false);

  const reportAPI = useContext(ReportsAPIContext);
  const nodes = reportAPI.findNodeWithRecord(nodeType, defaultRecords.oxygen.type);
  const records = getSortedRecords();

  function getSortedRecords(): OxygenTimestampRecord[] {
    const recordsWithTimestamp = nodes.flatMap((node) =>
      node.records.map((record) => ({
        effectiveTimestamp: node.effectiveTimestamp,
        record,
      })),
    );

    const sortedRecordsWithTimestamp = recordsWithTimestamp.sort((a, b) => {
      const timeA = a.effectiveTimestamp ?? 0;
      const timeB = b.effectiveTimestamp ?? 0;
      return timeA - timeB;
    });

    return sortedRecordsWithTimestamp.map(({ effectiveTimestamp, record }) => ({
      timestamp: getFormattedTime(effectiveTimestamp),
      ...record,
    })) as OxygenTimestampRecord[];
  }

  function openAddOxygenDialog() {
    setAddOxygenDialogOpen(true);
  }

  function addOxygenCallback(dialogResponse: AddOxygenDialogResponse) {
    const timestamp = dialogResponse.timestamp.valueOf();

    // Delete nodes with same timestamp
    nodes
      .filter((node) => node.effectiveTimestamp === timestamp)
      .forEach((node) => {
        reportAPI.deleteNode(node.id);
      });

    reportAPI.createNodeWithRecord(
      { type: defaultRecords.oxygen.type, oxygenFlow: { value: dialogResponse.oxygenFlowValue } },
      nodeType,
      timestamp,
    );
  }

  function deleteOxygen(recordId: RecordId) {
    const node = nodes.find((node) => node.records.some((record) => record.id === recordId));
    if (node != null) {
      if (node.records.length > 1) {
        throw new Error('Node for deletion contains more than the oxygen record');
      }
      reportAPI.deleteNode(node.id);
    }
  }

  return (
    <DiviFieldCard cardType={CardId.CourseOxygen}>
      <AddOxygenDialog
        isDialogOpen={addOxygenDialogOpen}
        onOpenChanged={setAddOxygenDialogOpen}
        onConfirm={addOxygenCallback}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Zeitpunkt</TableCell>
            <TableCell align='left'>Menge</TableCell>
            <TableCell align='right'>
              <Button variant='outlined' endIcon={<AddCircleIcon />} onClick={openAddOxygenDialog}>
                Sauerstoff hinzufügen
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((entry) => (
            <TableRow key={(typeof entry.id === 'object' ? `optimistic${entry.id.optimistic}` : entry.id) ?? -1}>
              <TableCell align='left'>{entry.timestamp}</TableCell>
              <TableCell align='left'>{entry.oxygenFlow?.value} l/m</TableCell>
              <TableCell align='right'>
                <IconButton aria-label='delete' onClick={() => deleteOxygen(entry.id!)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DiviFieldCard>
  );
}
