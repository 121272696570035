export enum MissionTypeKeys {
  primaereinsatz = 'PRIMARY',
  folgeeinsatz = 'FOLLOW_UP',
  fehleinsatz = 'FAILURE',
  notarztnachforderung = 'ADDITIONAL_REQUEST',
  sekundaereinsatz = 'SECONDARY',
  bereitstellung = 'PRECAUTIONARY_PROVISION',
  keinpatient = 'NO_PATIENT',
  abbestellt = 'CANCELLATION',
  abtransportiert = 'PATIENT_ALREADY_TRANSPORTED',
  boeswillig = 'MISUSE',
  technisch = 'MISSION_ABORT_TECHNICAL_REASONS',
  wetter = 'MISSION_ABORT_WEATHER',
  sonstiges = 'MISSION_ABORT_OTHER',
}

const missionTypeLabels: Record<MissionTypeKeys, string> = {
  [MissionTypeKeys.primaereinsatz]: 'Primäreinsatz',
  [MissionTypeKeys.folgeeinsatz]: 'Folgeeinsatz',
  [MissionTypeKeys.fehleinsatz]: 'Fehleinsatz',
  [MissionTypeKeys.notarztnachforderung]: 'Notarzt-Nachforderung',
  [MissionTypeKeys.sekundaereinsatz]: 'Sekundäreinsatz z.B. Verlegung',
  [MissionTypeKeys.bereitstellung]: 'vorsorgliche Bereitstellung',
  [MissionTypeKeys.keinpatient]: 'kein Patient',
  [MissionTypeKeys.abbestellt]: 'abbestellt',
  [MissionTypeKeys.abtransportiert]: 'Pat. bereits abtransportiert',
  [MissionTypeKeys.boeswillig]: 'böswillige Alarmierung',
  [MissionTypeKeys.technisch]: 'technische Gründe',
  [MissionTypeKeys.wetter]: 'Wetter',
  [MissionTypeKeys.sonstiges]: 'Sonstiges',
};

export function getMissionTypeLabelTuple(key: MissionTypeKeys): [MissionTypeKeys, string] {
  return [key, missionTypeLabels[key]];
}

export function getMissionTypeLabelTuplesFor(keys: MissionTypeKeys[]): [MissionTypeKeys, string][] {
  return keys.map((k) => {
    return getMissionTypeLabelTuple(k);
  });
}
