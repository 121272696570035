import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import { SnackbarAlert } from '../snackbarAlert/SnackbarAlert';
import { DateField, DateFieldProps, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DEBOUNCE_TIME = 500;

export type DebouncedDateFieldProps = {
  value?: Dayjs | null;
  onDebounceChange?: (value: Dayjs | null) => void;
} & DateFieldProps<Dayjs>;

/**
 * Datefield with debounce functionality.
 */
export default function DebouncedDateField(props: DebouncedDateFieldProps) {
  const { onDebounceChange, value, onFocus, onBlur, onChange, ...dateFieldProps } = props;
  const [lastValue, setLastValue] = useState<Dayjs | null | undefined>(value);
  const [focused, setFocused] = useState<boolean>(false);
  const [changedValue, setChangedValue] = useState<boolean>(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
    setChangedValue(true);
    setLastValue(value);
    if (value == null || value?.isValid()) {
      onDebounce(value);
    }

    onChange?.(value, context);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    setChangedValue(false);
    onFocus?.(event);
  };

  // We need to set the value in onBlur to prevent resetting to the old value. See COR-253
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const parsedDate = dayjs(event.target.value, 'DD.MM.YYYY');
    setFocused(false);
    if (changedValue) {
      if (parsedDate.isValid()) {
        setLastValue(parsedDate);
        // We need to call onDebounceChange here directly and not onDebounce, this could lead to the value resetting after onBlur otherwise
        onDebounceChange?.(parsedDate);
      } else {
        setErrorMessage(event.target.validationMessage + ' Die Eingabe wurde gelöscht.');
        setShowError(true);
        setLastValue(null);
        onDebounceChange?.(null);
      }
    }
    onBlur?.(event);
  };

  const onDebounce = useMemo(
    () =>
      debounce((value: Dayjs | null) => {
        onDebounceChange?.(value);
      }, DEBOUNCE_TIME),
    [onDebounceChange],
  );

  useEffect(() => {
    if (!focused) {
      setLastValue(value);
    }
  }, [value, focused]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          {...dateFieldProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={lastValue}
          onChange={handleChange}
        />
      </LocalizationProvider>
      <SnackbarAlert open={showError} onClose={() => setShowError(false)} message={errorMessage} severity='error' />
    </>
  );
}
