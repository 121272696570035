import { useCSS } from '../../../provider/CSSProvider';
import style from './PatientRecord.scss';
import React, { useContext } from 'react';
import { Button, List, Typography } from '@mui/material';
import PatientRecordSideBarEntry from './PatientRecordSideBarEntry';
import AddPatientRecordDialog from './AddPatientRecordDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dayjs from 'dayjs';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import AllPatientRecordsDialog from './AllPatientRecordsDialog';
import { Node } from '../../../backendModels/report.model';

function PatientRecord() {
  useCSS(style);

  const nodeType = NodeType.SUPPLEMENTS;

  const [addPatientRecordDialogOpen, setAddPatientRecordDialogOpen] = React.useState<boolean>(false);
  const [allPatientRecordsDialogOpen, setAllPatientRecordsDialogOpen] = React.useState<boolean>(false);
  const [nodeToEdit, setNodeToEdit] = React.useState<Node | undefined>(undefined);
  const reportAPI = useContext(ReportsAPIContext);

  const nodes = reportAPI
    .findNodeWithRecord(nodeType, 'note')
    .sort((first, second) => second.effectiveTimestamp! - first.effectiveTimestamp!);

  function createOrUpdatePatientRecord(text: string, node?: Node) {
    if (node === undefined) {
      reportAPI.createNodeWithRecord({ type: 'note', text: text }, nodeType, dayjs().valueOf());
    } else {
      reportAPI.adaptRecord('note', node.id, (draft) => {
        return { ...draft, text: text };
      });
    }
    setNodeToEdit(undefined);
  }

  function deletePatientRecord(node: Node) {
    reportAPI.deleteNode(node.id);
    setNodeToEdit(undefined);
  }

  function openAddEditDialog(node?: Node) {
    if (node != undefined) {
      setNodeToEdit(node);
    }
    setAddPatientRecordDialogOpen(true);
  }

  return (
    <div id='PatientRecord'>
      <AddPatientRecordDialog
        open={addPatientRecordDialogOpen}
        onOpenChanged={setAddPatientRecordDialogOpen}
        createOrUpdatePatientRecord={createOrUpdatePatientRecord}
        deletePatientRecord={deletePatientRecord}
        nodeToEdit={nodeToEdit}
      />
      <AllPatientRecordsDialog
        open={allPatientRecordsDialogOpen}
        onOpenChanged={setAllPatientRecordsDialogOpen}
        openAddDialog={openAddEditDialog}
        patientRecordNodes={nodes}
      />
      <div id='header'>
        <Typography fontWeight='fontWeightBold'>Patientenakte</Typography>
        <Button
          className='rounded inverted-button'
          variant='outlined'
          onClick={() => setAddPatientRecordDialogOpen(true)}
          endIcon={<AddCircleIcon />}
        >
          Verfassen
        </Button>
      </div>
      <List className='note-list'>
        {nodes.length > 0 &&
          nodes
            .filter((_, index) => index < 3)
            .map((node, index) => (
              <PatientRecordSideBarEntry
                key={index}
                title={node.records.length === 1 && node.records[0].type === 'note' ? node.records[0].text ?? '' : ''}
                time={dayjs(node.effectiveTimestamp).format('HH:mm')}
                showDivider={index < nodes.length - 1}
                onClick={() => openAddEditDialog(node)}
              />
            ))}
      </List>
      <Button
        className=''
        onClick={() => {
          setAllPatientRecordsDialogOpen(true);
        }}
        variant='text'
      >
        Alle {nodes.length} anzeigen
      </Button>
    </div>
  );
}

export default PatientRecord;
