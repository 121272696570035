import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import RadioList from '../../../customs/radioList/RadioList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import {
  MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY,
  MedicalConditionGynecologyRecord,
  getGynecologyTupelsFor,
  MedicalConditionGynecologyKeys,
} from '../../../../models/genericElements/medicalConditions/gynecology';
import _ from 'lodash';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkbox1: [MedicalConditionGynecologyKeys, string][] = getGynecologyTupelsFor([
  MedicalConditionGynecologyKeys.schwangerschaft,
]);

const radios1: [MedicalConditionGynecologyKeys, string][] = getGynecologyTupelsFor([
  MedicalConditionGynecologyKeys.drohendeGeburt,
  MedicalConditionGynecologyKeys.praeklinischeGeburt,
]);

const checkboxes2: [MedicalConditionGynecologyKeys, string][] = getGynecologyTupelsFor([
  MedicalConditionGynecologyKeys.praeeklampsie,
  MedicalConditionGynecologyKeys.vaginaleBlutung,
]);

interface MedicalConditionGynecologyProps {
  nodeType: NodeType;
}

export default function MedicalConditionGynecology({ nodeType }: MedicalConditionGynecologyProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY,
  ) as MedicalConditionGynecologyRecord;
  const conditions = record.inputState === InputState.ENTERED ? record.values.erkrankungenGyn?.options ?? [] : [];

  const conditionsChange = (newConditions: MedicalConditionGynecologyKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionGynecologyRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenGyn: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenGyn;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY,
    );
  };
  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionGynecologyRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenGynSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenGynSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionGynecology}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkbox1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              newConditions.includes(MedicalConditionGynecologyKeys.schwangerschaft)
                ? newConditions
                : _.difference(newConditions, [
                    MedicalConditionGynecologyKeys.drohendeGeburt,
                    MedicalConditionGynecologyKeys.praeklinischeGeburt,
                  ]),
            )
          }
        />
        <RadioList
          className='indented'
          items={radios1}
          value={
            _.intersection(conditions, [
              MedicalConditionGynecologyKeys.drohendeGeburt,
              MedicalConditionGynecologyKeys.praeklinischeGeburt,
            ])[0] ?? null
          }
          onChange={(event) =>
            conditionsChange(
              _.union(
                _.difference(conditions, [
                  MedicalConditionGynecologyKeys.drohendeGeburt,
                  MedicalConditionGynecologyKeys.praeklinischeGeburt,
                ]),
                [event.target.value as MedicalConditionGynecologyKeys, MedicalConditionGynecologyKeys.schwangerschaft],
              ),
            )
          }
        />
        <CheckboxList items={checkboxes2} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={record.inputState === InputState.ENTERED ? record.values.erkrankungenGynSonstige?.text ?? '' : ''}
        onDebounceChange={otherChange}
      />
    </DiviFieldCard>
  );
}
