import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Divider, List } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import { Node } from '../../../backendModels/report.model';
import dayjs from 'dayjs';
import PatientRecordFullEntry from './PatientRecordFullEntry';
import { useCSS } from '../../../provider/CSSProvider';
import style from './AllPatientRecordsDialog.scss';

interface AllPatientRecordsDialogProps {
  open: boolean;
  onOpenChanged: (open: boolean) => void;
  openAddDialog: (node?: Node) => void;
  patientRecordNodes: Node[];
}

export default function AllPatientRecordsDialog({
  open,
  onOpenChanged,
  openAddDialog,
  patientRecordNodes,
}: AllPatientRecordsDialogProps) {
  useCSS(style);

  const close = () => {
    onOpenChanged(false);
  };

  function replaceWithAddDialog() {
    // UX said that both dialogs should be closed after a new Note has been added
    close();
    openAddDialog();
  }

  function editEntry(node: Node) {
    openAddDialog(node);
  }

  return (
    <Dialog open={open} onClose={close} className='AllPatientRecordsDialog'>
      <DialogTitle className='title'>Patientenakte</DialogTitle>
      <Divider variant='middle' />
      <DialogContent>
        <List className='note-list'>
          {patientRecordNodes.length > 0 &&
            patientRecordNodes.map((node, index) => (
              <PatientRecordFullEntry
                key={index}
                text={node.records.length === 1 && node.records[0].type === 'note' ? node.records[0].text ?? '' : ''}
                time={dayjs(node.effectiveTimestamp).format('HH:mm')}
                edit={() => editEntry(node)}
              />
            ))}
        </List>
        <Divider variant='fullWidth' className='bottom-divider' />
        <DialogActions>
          <Button variant='contained' className='grey-button' onClick={close}>
            Abbrechen
          </Button>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => {
              replaceWithAddDialog();
            }}
          >
            Neuer Eintrag
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
