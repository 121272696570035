import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import {
  getSymptomStartNameTuplesFor,
  SymptomStartKeys,
} from '../../../../models/genericElements/operationalData/symptomStart';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
  OperationalDataRecord,
} from '../../../../models/genericElements/operationalData/operationalData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { useFreshCallback } from '../../../../utils/hooks';
import dayjs from 'dayjs';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface SymptomStartProps {
  nodeType: NodeType;
}

const symptomStartItems = getSymptomStartNameTuplesFor([
  SymptomStartKeys.ueber24H,
  SymptomStartKeys.beobachtet,
  SymptomStartKeys.unbekannt,
]);

export default function SymptomStart({ nodeType }: SymptomStartProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, OPERATIONAL_DATA_RECORD_ELEMENT_KEY) as OperationalDataRecord;

  const selectedSymptomStartValues =
    record.inputState === InputState.ENTERED ? record.values?.symptomOnsetOptions?.options ?? [] : [];
  const timeString = record.inputState == InputState.ENTERED ? record.values.symptomOnsetTimestamp?.text ?? null : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  const changeSymptomStart = (values: SymptomStartKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length === 0) {
          delete draft?.values?.symptomOnsetOptions;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            symptomOnsetOptions: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  };

  const handleStartTime = useFreshCallback((value: dayjs.Dayjs | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value != null && value.isValid()) {
          draft.values = {
            ...draft.values,
            symptomOnsetTimestamp: {
              fieldType: 'text',
              text: value.format('HH:mm'),
            },
          };
        } else {
          delete draft.values?.symptomOnsetTimestamp;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.SymptomStart}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          fullWidth
          className='time-of-arrival no-margin-top'
          label='Symptom Beginn (z.B. Kollaps Unfallzeitpunkt)'
          format='HH:mm'
          value={timeDayjs}
          onChange={handleStartTime}
        />
      </LocalizationProvider>
      <CheckboxList
        className='add-margin-top symptoms-list'
        items={symptomStartItems}
        selectedValues={selectedSymptomStartValues}
        onValuesChange={changeSymptomStart}
      />
    </DiviFieldCard>
  );
}
