// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-trends-dialog .MuiPaper-root{width:510px;height:530px}.add-trends-dialog .center-slash{align-items:center;display:flex;padding-top:16px}.add-trends-dialog .add-trends-dialog-confirmed{display:flex;justify-content:center;align-items:center;width:100%;height:100%}.add-trends-dialog .add-trends-dialog-confirmed .check-circle{width:52px;height:52px}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Trends/Trends/AddVitalParamDialog.scss"],"names":[],"mappings":"AACE,kCACE,WAAA,CACA,YAAA,CAGF,iCACE,kBAAA,CACA,YAAA,CACA,gBAAA,CAGF,gDACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAEA,8DACE,UAAA,CACA,WAAA","sourcesContent":[".add-trends-dialog {\n  .MuiPaper-root {\n    width: 510px;\n    height: 530px;\n  }\n\n  .center-slash {\n    align-items: center;\n    display: flex;\n    padding-top: 16px;\n  }\n\n  .add-trends-dialog-confirmed {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n\n    .check-circle {\n      width: 52px;\n      height: 52px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
