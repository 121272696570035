import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { MissionTypeKeys } from './missionType';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { SymptomStartKeys } from './symptomStart';
import { InputState } from '../../../backendModels/general.model';

export const OPERATIONAL_DATA_RECORD_ELEMENT_KEY = 'diviMissionData';
export const OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY = 'diviTransportLocation';

export type OperationalDataRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OPERATIONAL_DATA_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          missionType?: SingleSelectFieldValueTyped<MissionTypeKeys>;
          missionFailure?: SingleSelectFieldValueTyped<MissionTypeKeys>;
          transportLocationPreregistration?: MultiSelectFieldValueTyped<PreregistrationKeys>;
          transportLocationType?: SingleSelectFieldValueTyped<PreregistrationKeys>;
          symptomOnsetOptions?: MultiSelectFieldValueTyped<SymptomStartKeys>;
          symptomOnsetTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export enum PreregistrationKeys {
  preregistration = 'PREREGISTRATION',
  strokeunit = 'STROKE_UNIT',
  cardiaccatheter = 'CARDIAC_CATHETER',
  traumacenter = 'TRAUMA_CENTER',
}

const preregistrationLabels: Record<PreregistrationKeys, string> = {
  [PreregistrationKeys.preregistration]: 'Voranmeldung',
  [PreregistrationKeys.strokeunit]: 'Stroke Unit',
  [PreregistrationKeys.cardiaccatheter]: 'Herzkatheter',
  [PreregistrationKeys.traumacenter]: 'Traumazentrum',
};

export function getPreregistrationTupel(key: PreregistrationKeys): [PreregistrationKeys, string] {
  return [key, preregistrationLabels[key]];
}

export function getPreregistrationTupelsFor(keys: PreregistrationKeys[]): [PreregistrationKeys, string][] {
  return keys.map((k) => {
    return getPreregistrationTupel(k);
  });
}
