import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './PalliativeSituation.scss';
import { CardId } from '../../../../models/diviCard';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  DiagnosisInitialKeys,
  DiagnosisInitialRecord,
  getDiagnosisInitialTuple,
} from '../../../../models/genericElements/diagnosisInitial';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';

interface PalliativeSituationProps {
  nodeType: NodeType;
}

export default function PalliativeSituation({ nodeType }: PalliativeSituationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  ) as DiagnosisInitialRecord;

  function handePallativeSituationChange(value: DiagnosisInitialKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DiagnosisInitialRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            erstdiagnosenPalliativ: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.erstdiagnosenPalliativ;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.PalliativeSituation} showTitle={false} disableElevation={true}>
      <CheckboxList
        className='symptoms-list'
        items={[getDiagnosisInitialTuple(DiagnosisInitialKeys.erstdiagnosenPalliativ)]}
        selectedValues={
          record.inputState === InputState.ENTERED ? record.values?.erstdiagnosenPalliativ?.options ?? [] : []
        }
        onValuesChange={handePallativeSituationChange}
      />
    </DiviFieldCard>
  );
}
