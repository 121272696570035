import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import style from './PatientData.scss?inline';
import globalstyle from '../../../../global.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import {
  BirthDateBackendFormat,
  BirthDateUiFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getAgeFromBirthday,
  getPseudonymTuplesFor,
  PseudonymKeys,
} from '../../../../models/genericElements/extendedPatientData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import dayjs from 'dayjs';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';
import { changePropertyAndMarkTouched, getTouchedClassName } from '../../../../utils/recordWithTouched';
import DebouncedDateField from '../../../customs/debouncedDateField/DebouncedDateField';
import {
  getPrivatelyInsuredTuplesFor,
  PRIVATE_HEALTH_INSURANCE_RECORD_ELEMENT_KEY,
  PrivateHealthInsuranceRecord,
  PrivatelyInsuredKeys,
} from '../../../../models/genericElements/privateHealthInsurance';
import { Typography } from '@mui/material';
import _ from 'lodash';

interface PatientDataProps {
  nodeType: NodeType;
}

export default function PatientData({ nodeType }: PatientDataProps) {
  useCSS(style);
  useCSS(globalstyle);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;
  const privateHealthInsuranceRecord = findRecordOrDefault(
    'generic',
    nodeType,
    PRIVATE_HEALTH_INSURANCE_RECORD_ELEMENT_KEY,
  ) as PrivateHealthInsuranceRecord;

  const extendedRecord = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const birthDate =
    record.values?.birthday?.text != null ? dayjs(record.values?.birthday?.text, BirthDateBackendFormat) : null;

  const handleBirthDateChange = useFreshCallback((value: dayjs.Dayjs | null) => {
    const newBirthdate = value != null && value?.isValid() ? value.format(BirthDateBackendFormat) : null;
    const [age, newbornAge] = getAgeFromBirthday(value);

    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (newBirthdate != null) {
          if (newbornAge != null) {
            draft.values = {
              ...draft.values,
              newbornAge: {
                fieldType: 'singleSelect',
                option: newbornAge,
              },
            };
          } else {
            delete draft.values?.newbornAge;
          }
        } else {
          if (record.values?.birthday) {
            // If there has been a birthday before, we have automatically calculated the age and need to delete it.
            delete draft.values?.newbornAge;
          }
        }

        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );

    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (!_.isEqual(newBirthdate, draft.values?.birthday)) {
          draft.values = {
            ...draft.values,
            touched: {
              options: _.union([PatientPropertyKeys.birthday], draft.values?.touched?.options ?? []),
              fieldType: 'multiSelect',
            },
          };

          if (age != null) {
            draft.values = {
              ...draft.values,
              touched: {
                options: _.union([PatientPropertyKeys.age], draft.values?.touched?.options ?? []),
                fieldType: 'multiSelect',
              },
            };
          }
        }

        if (newBirthdate != null) {
          draft.values = {
            ...draft.values,
            birthday: {
              fieldType: 'text',
              text: newBirthdate,
            },
          };

          if (age != null) {
            draft.values = {
              ...draft.values,
              age: {
                fieldType: 'numeric',
                number: age,
              },
            };
          } else {
            delete draft.values?.age;
          }
        } else {
          if (draft.values?.birthday) {
            // If there has been a birthday before, we have automatically calculated the age and need to delete it.
            delete draft.values?.age;
          }
          delete draft.values?.birthday;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  const handlePseoudonymChange = useFreshCallback((value: PseudonymKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            pseudonym: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.pseudonym;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  const handlePrivatelyInsuredChange = useFreshCallback((value: PrivatelyInsuredKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PrivateHealthInsuranceRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            privatelyInsured: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.privatelyInsured;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      PRIVATE_HEALTH_INSURANCE_RECORD_ELEMENT_KEY,
    );
  });

  function handleChangedPatientProperty(field: PatientPropertyKeys, value: string) {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      PATIENT_DATA_RECORD_ELEMENT_KEY,
      field,
      value !== '' ? { fieldType: 'text', text: value } : undefined,
    );
  }

  const titleContent = (
    <div className='patient-title-content'>
      <Typography align='left' variant='h2'>
        {getCardTitle(CardId.PatientData)}
      </Typography>
      <CheckboxList
        className='privately-insured'
        items={getPrivatelyInsuredTuplesFor([PrivatelyInsuredKeys.isPrivatelyInsured])}
        selectedValues={
          privateHealthInsuranceRecord.inputState === InputState.ENTERED
            ? (privateHealthInsuranceRecord.values.privatelyInsured?.options ?? [])
            : []
        }
        onValuesChange={handlePrivatelyInsuredChange}
      />
    </div>
  );

  return (
    <DiviFieldCard cardType={CardId.PatientData} customTitleContent={titleContent}>
      <div className='patient-data-wireframe'>
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.surname)}
          label='Name'
          autoComplete='off'
          value={record.values?.surname?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.surname, value)}
        />
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.name)}
          label='Vorname'
          autoComplete='off'
          value={record.values?.name?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.name, value)}
        />

        <DebouncedDateField
          fullWidth
          className={`no-margin-top ${getTouchedClassName(record, PatientPropertyKeys.birthday)}`}
          label='Geburtsdatum'
          value={birthDate}
          format={BirthDateUiFormat}
          onDebounceChange={handleBirthDateChange}
        />

        <CheckboxList
          className='symptoms-list'
          items={getPseudonymTuplesFor([PseudonymKeys.pseudonym])}
          selectedValues={
            extendedRecord.inputState === InputState.ENTERED ? (extendedRecord.values.pseudonym?.options ?? []) : []
          }
          onValuesChange={handlePseoudonymChange}
        />
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.street)}
          label='Straße'
          value={record.values?.street?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.street, value)}
        />
        <div className='row patient-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.postalCode)}
            fullWidth
            label='PLZ'
            value={record.values?.postalCode?.text ?? ''}
            inputProps={{ min: 1, max: 99999, step: 1, maxLength: 5 }}
            type='number'
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.postalCode, value)}
          />
          <DebouncedTextField
            className={`patient-data-city ${getTouchedClassName(record, PatientPropertyKeys.city)}`}
            fullWidth
            label='Ort'
            value={record.values?.city?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.city, value)}
          />
        </div>
        <div className='row insurance-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.insuranceName)}
            label='Kasse'
            value={record.values?.insuranceName?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.insuranceName, value)}
          />
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.insuranceNumber)}
            label='Kasse / Nr.'
            value={record.values?.insuranceNumber?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.insuranceNumber, value)}
          />
        </div>

        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.policyNumber)}
          label='Versicherungs Nr.'
          value={record.values?.policyNumber?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.policyNumber, value)}
        />
      </div>
    </DiviFieldCard>
  );
}
