import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';
import { REMARKS_RECORD_ELEMENT_KEY, RemarksRecord } from '../../../../models/genericElements/remarks';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import { Draft } from 'immer';
import { ReactComponent as SignatureIcon } from '../../../../assets/images/icons/signature.svg';
import { Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './RemarksNote.scss';

interface RemarksNoteProps {
  nodeType: NodeType;
}

export default function RemarksNote({ nodeType }: RemarksNoteProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, REMARKS_RECORD_ELEMENT_KEY) as RemarksRecord;

  const handleNoteChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<RemarksRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value !== '') {
          draft.values = {
            ...draft.values,
            bemerkungenText: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.bemerkungenText;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REMARKS_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.Remarks} showTitle={false}>
      <DebouncedTextField
        id='remarks-filled-textarea'
        label={getCardTitle(CardId.Remarks)}
        placeholder={getCardTitle(CardId.Remarks)}
        multiline
        fullWidth
        rows={10}
        onDebounceChange={handleNoteChange}
        value={record.inputState === InputState.ENTERED ? record.values?.bemerkungenText?.text ?? '' : ''}
        className='no-margin-top'
      />
      <div className='row signature'>
        <SignatureIcon />
        <Typography variant='body2'>Eine digitale Signatur wird automatisch generiert.</Typography>
      </div>
    </DiviFieldCard>
  );
}
