export enum CirculationKeys {
  kreislaufstillstand = 'CARDIAC_ARREST',
  hypovolaemie = 'HYPOVOLAEMIA',
  hypotonie = 'HYPOTENSION',
  hypertonie = 'HYPERTENSION',
  tachykardie = 'TACHYCARDIA',
  bradykardie = 'BRADYCARDIA',
  ventrikulaeretachykardie = 'VT',
  kammerflimmern = 'VF',
  pulsloseelektrischeaktivitaet = 'PEA',
  asystolie = 'ASYSTOLE',
  akuteskoronarsyndrom = 'ACS',
  apbeschwerden = 'AP_COMPLAINTS',
  herzrhythmusstoerungen = 'ARRHYTHMIA',
  avblocki = 'AV_BLOCK_I',
  avblockiiwenkebach = 'AV_BLOCK_II_WENKEBACH',
  avblockiimobitz = 'AV_BLOCK_II_MOBITZ',
  avblockiii = 'AV_BLOCK_III',
  myokardinfarkt = 'MYOCARDIAL_INFARCTION',
  schock = 'SHOCK',
  blaesse = 'PALENESS',
  rekap2seconds = 'CAPILLARY_REFILL_TIME_2_SEC',
  radialispulsnichttastbar = 'RADIAL_PULSE_NOT_PALPABLE',
  unregelmaessigerpuls = 'IRREGULAR_PULSE',
  rhythmisch = 'RHYTHMISCH',
  arrhythmisch = 'ARRHYTHMISCH',
}

export type UsedCirculationKeys = CirculationKeys.rhythmisch | CirculationKeys.arrhythmisch;

const circulationLabels: Record<UsedCirculationKeys, string> = {
  [CirculationKeys.rhythmisch]: 'rhythmisch',
  [CirculationKeys.arrhythmisch]: 'arrhythmisch',
};

export function getCirculationTupel(key: UsedCirculationKeys): [UsedCirculationKeys, string] {
  return [key, circulationLabels[key]];
}

export function getCirculationTuplesFor(keys: UsedCirculationKeys[]): [UsedCirculationKeys, string][] {
  return keys.map((k) => {
    return getCirculationTupel(k);
  });
}
