import { useContext } from 'react';
import DiviFieldCard from '../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../models/diviCard';
import { FormControl } from '@mui/material';
import CheckboxList from '../../customs/checkboxList/CheckboxList';
import {
  getSpecificsTuplesFor,
  SPECIFICS_RECORD_ELEMENT_KEY,
  SpecificsKeys,
  SpecificsRecord,
} from '../../../models/genericElements/specifics';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../backendModels/general.model';
import RadioList from '../../customs/radioList/RadioList';
import { isGenericRecordDeletable } from '../../../models/generic';
import _ from 'lodash';

const situationItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitensituationhygiene,
  SpecificsKeys.besonderheitensituationpatzugang,
  SpecificsKeys.besonderheitensituationtechrettung,
  SpecificsKeys.besonderheitensituationschwertransport,
]);

const majorLossItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitengrossschadenslagelna,
  SpecificsKeys.besonderheitengrossschadenslagemanv,
  SpecificsKeys.besonderheitengrossschadenslagemehrerepatienten,
]);

const handoverItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitenuebernahmevonarzt,
  SpecificsKeys.besonderheitenuebergabeanarzt,
]);

const transportParentItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitentransportinkh,
]);

const transportItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitentransportmarzt,
  SpecificsKeys.besonderheitentransportmrth,
  SpecificsKeys.besonderheitentransportmsignal,
  SpecificsKeys.besonderheitentransportkliniknimmtnichtauf,
  SpecificsKeys.besonderheitentransportzwangsbelegung,
  SpecificsKeys.besonderheitentransportzwangsunterbringung,
]);

const treatmentParentItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitennurbehandlung,
]);

const treatmentItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitennurbehandlungvereigerung,
  SpecificsKeys.besonderheitennurbehandlungpatnichttransportfaehig,
  SpecificsKeys.besonderheitennurbehandlungtherapieverzicht,
]);

interface SpecificsProps {
  nodeType: NodeType;
}
export default function Specifics({ nodeType }: SpecificsProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, SPECIFICS_RECORD_ELEMENT_KEY) as SpecificsRecord;

  const transportValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenTransport?.options ?? []) : [];
  const treatmentValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenNurBehandlung?.option ?? null) : null;
  const majorLossValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenGrossschadenslage?.options ?? []) : [];
  const situationValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenSituation?.options ?? []) : [];

  const changeHandover = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            besonderheitenUebergabe: {
              fieldType: 'singleSelect',
              option: value as SpecificsKeys,
            },
          };
        } else {
          delete draft.values?.besonderheitenUebergabe;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTransportRadio = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          delete draft.values?.besonderheitenNurBehandlung;
          draft.values = {
            ...draft.values,
            besonderheitenTransport: {
              fieldType: 'multiSelect',
              options: _.union(draft.values?.besonderheitenTransport?.options, [
                SpecificsKeys.besonderheitentransportinkh,
              ]),
            },
          };
        } else {
          delete draft.values?.besonderheitenTransport;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTransport = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>) => {
        draft.inputState = InputState.ENTERED;
        delete draft.values?.besonderheitenNurBehandlung;
        draft.values = {
          ...draft.values,
          besonderheitenTransport: {
            fieldType: 'multiSelect',
            options: _.union(values, [SpecificsKeys.besonderheitentransportinkh]),
          },
        };
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTreatmentOnlyExamination = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          delete draft.values?.besonderheitenTransport;
          draft.values = {
            ...draft.values,
            besonderheitenNurBehandlung: {
              fieldType: 'singleSelect',
              option: value as SpecificsKeys,
            },
          };
        } else {
          delete draft.values?.besonderheitenNurBehandlung;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTreatment = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>) => {
        draft.inputState = InputState.ENTERED;
        delete draft.values?.besonderheitenTransport;
        draft.values = {
          ...draft.values,
          besonderheitenNurBehandlung: {
            fieldType: 'singleSelect',
            option: (value ?? SpecificsKeys.besonderheitennurbehandlung) as SpecificsKeys,
          },
        };
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const handleSituationCheckbox = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length == 0) {
          delete draft.values?.besonderheitenSituation;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            besonderheitenSituation: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const handleGrossschadenCheckbox = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length == 0) {
          delete draft.values?.besonderheitenGrossschadenslage;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            besonderheitenGrossschadenslage: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Specifics}>
      <div className='row two-row symptoms-list'>
        <div>
          <RadioList
            items={handoverItems}
            value={
              record.inputState === InputState.ENTERED ? (record.values.besonderheitenUebergabe?.option ?? null) : null
            }
            onChange={changeHandover}
          />
          <FormControl className='add-margin-top'>
            <RadioList
              items={transportParentItems}
              value={transportValue?.length > 0 ? SpecificsKeys.besonderheitentransportinkh : null}
              onChange={changeTransportRadio}
            />
            <CheckboxList
              className='indented'
              items={transportItems}
              selectedValues={transportValue}
              onValuesChange={changeTransport}
            ></CheckboxList>
            <RadioList
              items={treatmentParentItems}
              value={treatmentValue != null ? SpecificsKeys.besonderheitennurbehandlung : null}
              onChange={changeTreatmentOnlyExamination}
            />
            <RadioList
              className='indented'
              items={treatmentItems}
              value={treatmentValue}
              onChange={changeTreatment}
            ></RadioList>
          </FormControl>
        </div>
        <div>
          <CheckboxList
            items={situationItems}
            selectedValues={situationValue}
            onValuesChange={handleSituationCheckbox}
          ></CheckboxList>
          <CheckboxList
            items={majorLossItems}
            selectedValues={majorLossValue}
            onValuesChange={handleGrossschadenCheckbox}
          ></CheckboxList>
        </div>
      </div>
    </DiviFieldCard>
  );
}
