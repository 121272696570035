import React, { useCallback, useContext, useEffect } from 'react';
import globalStyle from '../../../global.scss';
import { useCSS } from '../../../provider/CSSProvider';
import MedicalScoreDisplay from '../medicalScoreDisplay/MedicalScoreDisplay';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import {
  convertVitalParametersToMapping,
  VitalParameterKeys,
  VitalParametersMappingType,
  VitalParametersRecord,
} from '../../../models/vitalParameters';
import { defaultRecords } from '../../../DefaultRecords';
import { NodeType } from '../../../backendModels/report.model';
import { Draft } from 'immer';
import { QSOFA_RECORD_ELEMENT_KEY, QSOFARecord } from '../../../models/genericElements/qsofa';
import { InputState } from '../../../backendModels/general.model';

interface QSofaTypeProps {
  nodeType: NodeType;
}

export default function QSofa({ nodeType }: QSofaTypeProps) {
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const vitalRecord: VitalParametersRecord = reportAPI.findRecordOrDefault(
    defaultRecords.vitalParameters.type,
    nodeType,
  );
  const gcsRecord = reportAPI.findRecordOrDefault(defaultRecords.gcs.type, nodeType);
  const vitalParameters: VitalParametersMappingType = convertVitalParametersToMapping(vitalRecord.vitalParameters);
  const totalBreathRate = vitalParameters[VitalParameterKeys.totalBreathRate];
  const nonInvasiveSystolicArterialPressure = vitalParameters[VitalParameterKeys.nonInvasiveSystolicArterialPressure];

  const qSofaRecord = reportAPI.findRecordOrDefault('generic', nodeType, QSOFA_RECORD_ELEMENT_KEY) as QSOFARecord;

  let qSofa: number | null = null;
  if (
    totalBreathRate?.value != null &&
    nonInvasiveSystolicArterialPressure?.value != null &&
    gcsRecord.oralResponse != null &&
    gcsRecord.ocularResponse != null &&
    gcsRecord.motoricResponse != null
  ) {
    qSofa = gcsRecord.oralResponse + gcsRecord.ocularResponse + gcsRecord.motoricResponse < 15 ? 1 : 0;
    qSofa += totalBreathRate.value > 22 ? 1 : 0;
    qSofa += nonInvasiveSystolicArterialPressure.value < 100 ? 1 : 0;
  }

  const updateqSofaRecord = useCallback(() => {
    if (qSofa !== qSofaRecord.values?.qSofaScore?.number) {
      reportAPI.adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<QSOFARecord>, deleteRecord) => {
          if (qSofa != null) {
            draft.inputState = InputState.ENTERED;
            draft.values = {
              ...draft.values,
              qSofaScore: {
                fieldType: 'numeric',
                number: qSofa!,
              },
            };
          } else {
            deleteRecord();
          }
        },
        QSOFA_RECORD_ELEMENT_KEY,
      );
    }
  }, [nodeType, qSofa, qSofaRecord.values?.qSofaScore?.number, reportAPI]);

  useEffect(() => {
    updateqSofaRecord();
  }, [updateqSofaRecord]);

  return (
    <MedicalScoreDisplay
      asHeader={false}
      className='qsofa-score'
      header={'qSOFA'}
      medicalScore={qSofa}
      description={'je 1 Punkt für: GCS<15, AF>22, RRsys<100mmHg'}
    />
  );
}
