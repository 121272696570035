import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../generic';
import { PatientGender } from '../../backendModels/patient.model';
import { NumericFieldValue, TextFieldValue } from '../../backendModels/generic-element.model';

export const PATIENT_DATA_RECORD_ELEMENT_KEY = 'diviPatientData';

export enum PatientPropertyKeys {
  name = 'name',
  surname = 'surname',
  birthday = 'birthday',
  street = 'street',
  postalCode = 'postalCode',
  city = 'city',
  insuranceNumber = 'insuranceNumber',
  insuranceName = 'insuranceName',
  policyNumber = 'policyNumber',
  age = 'age',
  gender = 'gender',
}

export type PatientDataRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof PATIENT_DATA_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          surname?: TextFieldValue;
          name?: TextFieldValue;
          gender?: SingleSelectFieldValueTyped<PatientGender>;
          age?: NumericFieldValue;
          birthday?: TextFieldValue;
          street?: TextFieldValue;
          postalCode?: TextFieldValue;
          city?: TextFieldValue;
          policyNumber?: TextFieldValue;
          insuranceName?: TextFieldValue;
          insuranceNumber?: TextFieldValue;
          touched?: MultiSelectFieldValueTyped<PatientPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
