import { Route, Routes } from 'react-router-dom';
import { Neurology } from './Neurology/Neurology';
import React from 'react';
import { PageType, getPageRoute } from '../../models/diviPage';
import { DataInitial } from './DataInitial/DataInitial';
import { PatientMasterData } from './PatientMasterData/PatientMasterData';
import { Anamnese } from './Anamnese/Anamnese';
import { OperationalData } from './OperationalData/OperationalData';
import { MedicalConditions } from './MedicalConditions/MedicalConditions';
import { Injuries } from './Injuries/Injuries';
import { DiagnosisInitial } from './DiagnosisInitial/DiagnosisInitial';
import { Medication } from './Medication/Medication';
import { Remarks } from './Remarks/Remarks';
import { Handover } from './Handover/Handover';
import { HandoverTo } from './HandoverTo/HandoverTo';
import { ReanimationDeath } from './ReanimationDeath/ReanimationDeath';
import { Treatments } from './Treatments/Treatments';
import { Specifics } from './Specifics/Specifics';
import { Course } from './Trends/Course';
import { Acronym } from './Acronym/Acronym';
import { SAMPLERPlusS } from './SAMPLER+S/SAMPLERPlusS';

export function PageRouter() {
  return (
    <Routes>
      <Route path={getPageRoute(PageType.PatientMasterData)} element={<PatientMasterData />} />
      <Route path={getPageRoute(PageType.OperationalData)} element={<OperationalData />} />
      <Route path={getPageRoute(PageType.Anamnese)} element={<Anamnese />} />
      <Route path={getPageRoute(PageType.Neurology)} element={<Neurology />} />
      <Route path={getPageRoute(PageType.DataInitial)} element={<DataInitial />} />
      <Route path={getPageRoute(PageType.MedicalConditions)} element={<MedicalConditions />} />
      <Route path={getPageRoute(PageType.Injuries)} element={<Injuries />} />
      <Route path={getPageRoute(PageType.DiagnosisInitial)} element={<DiagnosisInitial />} />
      <Route path={getPageRoute(PageType.Course)} element={<Course />} />
      <Route path={getPageRoute(PageType.Medication)} element={<Medication />} />
      <Route path={getPageRoute(PageType.Measures)} element={<Treatments />} />
      <Route path={getPageRoute(PageType.HandoverTo)} element={<HandoverTo />} />
      <Route path={getPageRoute(PageType.Remarks)} element={<Remarks />} />
      <Route path={getPageRoute(PageType.Handover)} element={<Handover />} />
      <Route path={getPageRoute(PageType.Specifics)} element={<Specifics />} />
      <Route path={getPageRoute(PageType.ReanimationDeath)} element={<ReanimationDeath />} />
      <Route path={getPageRoute(PageType.Acronym) + '/*'} element={<Acronym />} />
      <Route path={getPageRoute(PageType.SAMPLERPlusS)} element={<SAMPLERPlusS />} />
    </Routes>
  );
}
