import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { SingleSelectFieldValueTyped } from '../../generic';

export enum InjuriesCategorySeverityKeys {
  minor = 'MINOR',
  medium = 'MEDIUM',
  major = 'MAJOR',
}
export enum InjuriesCategoryKeys {
  headbackoffen = 'HEAD_BACK_OFFEN',
  headbackgeschlossen = 'HEAD_BACK_GESCHLOSSEN',
  headoffen = 'HEAD_OFFEN',
  headgeschlossen = 'HEAD_GESCHLOSSEN',
  neckoffen = 'NECK_OFFEN',
  neckgeschlossen = 'NECK_GESCHLOSSEN',
  thoraxoffen = 'THORAX_OFFEN',
  thoraxgeschlossen = 'THORAX_GESCHLOSSEN',
  abdomenoffen = 'ABDOMEN_OFFEN',
  abdomengeschlossen = 'ABDOMEN_GESCHLOSSEN',
  cervicalspineoffen = 'CERVICAL_SPINE_OFFEN',
  cervicalspinegeschlossen = 'CERVICAL_SPINE_GESCHLOSSEN',
  sacrumcoccyxoffen = 'SACRUM_COCCYX_OFFEN',
  sacrumcoccyxgeschlossen = 'SACRUM_COCCYX_GESCHLOSSEN',
  shoulderoffen = 'SHOULDER_OFFEN',
  shouldergeschlossen = 'SHOULDER_GESCHLOSSEN',
  femuroffen = 'FEMUR_OFFEN',
  femurgeschlossen = 'FEMUR_GESCHLOSSEN',
  genitalsoffen = 'GENITALS_OFFEN',
  genitalsgeschlossen = 'GENITALS_GESCHLOSSEN',
}

export const INJURIES_CATEGORY_RECORD_ELEMENT_KEY = 'injuriesOffenGeschlossen';

export type InjuriesCategoryRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof INJURIES_CATEGORY_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          headBackOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          headOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          neckOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          thoraxOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          abdomenOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          cervicalSpineOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          sacrumCoccyxOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          shoulderOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          femurOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
          genitalsOffenGeschlossen?: SingleSelectFieldValueTyped<InjuriesCategoryKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

export const injuryLocationLabels: string[] = [
  'Schädel-Hirn',
  'Gesicht',
  'Hals',
  'Thorax',
  'Abdomen',
  'Wirbelsäule',
  'Becken',
  'Obere Extremitäten',
  'Untere Extremitäten',
  'Weichteile',
];

export function getInjuriesCategoryTuplesWithoutLabelFor(keys: InjuriesCategoryKeys[]): [InjuriesCategoryKeys, ''][] {
  return keys.map((k) => {
    return [k, ''];
  });
}
