import { useCSS } from '../../../provider/CSSProvider';
import style from './TimePicker.scss?inline';
import { Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';

export interface TimePickerProps {
  className?: string;
  label?: string;
  required?: boolean; // defaults to false
  step?: number; // defaults to 60s
  value: string;
  onChange: (value: string) => void;
}

export function TimePicker({ className, label, value, onChange, required, step }: TimePickerProps) {
  useCSS(style);

  const [invalidDescription, setInvalidDescription] = useState<string | null>(null);

  return (
    <fieldset className={`timepicker-fieldset ${className ?? ''}`}>
      {label && <legend className='timepicker-legend'>{label}</legend>}
      <input
        type='time'
        className='timepicker-input'
        value={value}
        aria-label={label ?? 'Uhrzeit'}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onBlur={(event) =>
          setInvalidDescription(
            event.target.validity.valid
              ? null
              : 'Bitte geben Sie eine vollständige Uhrzeit ein' +
                  (!required ? ' oder löschen Sie die Eingabe komplett' : ''),
          )
        }
        onFocus={() => setInvalidDescription(null)}
        required={required ?? false}
        step={step ?? 60}
      />
      {invalidDescription && (
        <Tooltip arrow title={invalidDescription}>
          <WarningIcon className='warning-symbol' color='warning' />
        </Tooltip>
      )}
    </fieldset>
  );
}
