import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export enum SAMPLERPlusSKeys {
  S = 'samplersS',
  A = 'samplersA',
  M = 'samplersM',
  P = 'samplersP',
  L = 'samplersL',
  E = 'samplersE',
  R = 'samplersR',
  plusS = 'SAMPLERSPS',
}

export const SAMPLER_PLUS_S_RECORD_ELEMENT_KEY = 'samplers';

export type SAMPLERPlusSRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof SAMPLER_PLUS_S_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          samplersS?: TextFieldValue;
          samplersA?: TextFieldValue;
          samplersM?: TextFieldValue;
          samplersP?: TextFieldValue;
          samplersL?: TextFieldValue;
          samplersE?: TextFieldValue;
          samplersR?: TextFieldValue;
          samplersPS?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
