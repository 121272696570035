import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import Participants from './Participants/Participants';
import SymptomStart from './SymptomStart/SymptomStart';
import MissionType from './MissionType/Type';
import MissionLocation from './MissionLocation/Location';
import Destination from './Destination/Destination';
import { useCSS } from '../../../provider/CSSProvider';
import style from './OperationalData.scss?inline';
import { Typography } from '@mui/material';
import { OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/transportLocation';
import { OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/missionLocation';
import { EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/extendedOperationalData';
import { OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/participants';
import { SYMPTOM_ONSET_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/symptomOnset';

export function OperationalData() {
  useCSS(style);

  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.METADATA;
  const elementKeysOfPage: string[] = [
    OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY,
    OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
    EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY,
    SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
  ];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.OperationalData)} noClearAllButton />;

  const pageFooter = <PageFooter pageType={PageType.OperationalData} diviId={DiviNavigationIds.IncidentData} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.IncidentData}
    >
      <div className='legend row'>
        <div className='indicator'></div>
        <Typography variant='subtitle1'>Automatisch übertragene Daten</Typography>
      </div>
      <div className='operational-data-wireframe'>
        <div className='participants'>
          <Participants nodeType={nodeTypeOfPage} />
        </div>
        <div className='type'>
          <MissionType nodeType={nodeTypeOfPage} />
        </div>
        <div className='location'>
          <MissionLocation nodeType={nodeTypeOfPage} />
        </div>
        <div className='symptom-start'>
          <SymptomStart nodeType={nodeTypeOfPage} />
        </div>
        <div className='destination'>
          <Destination nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
