import React, { useCallback, useContext, useEffect } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import PatientData from './PatientData/PatientData';
import style from './PatientMasterData.scss';
import { useCSS } from '../../../provider/CSSProvider';
import Age from './Age/Age';
import Gender from './Gender/Gender';
import Bmi from './Bmi/Bmi';
import Mission from './Mission/Mission';
import { Typography } from '@mui/material';
import {
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
} from '../../../models/genericElements/extendedPatientData';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { Draft } from 'immer';
import { InputState } from '../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../models/generic';
import { useFreshCallback } from '../../../utils/hooks';
import _ from 'lodash';
import { CONTROL_CENTER_RECORD_ELEMENT_KEY } from '../../../models/genericElements/controlCenter';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../models/genericElements/patientData';

export interface HandleChangedPatientProperty {
  <T extends PatientPropertyKeys>(
    property: T,
    value?: (ExtendedPatientDataRecord & { inputState: InputState.ENTERED })['values'][T],
  ): void;
}

export function PatientMasterData() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.METADATA;
  const elementKeysOfPage = [
    PATIENT_DATA_RECORD_ELEMENT_KEY,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    CONTROL_CENTER_RECORD_ELEMENT_KEY,
  ];

  const { findRecordOrDefault, findNodes, adaptRecord, patient } = useContext(ReportsAPIContext);

  const patientDataRecord = findRecordOrDefault(
    'generic',
    nodeTypeOfPage,
    PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as PatientDataRecord;
  const extendedPatientDataRecord = findRecordOrDefault(
    'generic',
    nodeTypeOfPage,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  useEffect(() => {
    if (patient != null && findNodes(nodeTypeOfPage).length > 0) {
      adaptRecord(
        'generic',
        nodeTypeOfPage,
        (draft: Draft<PatientDataRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.name, patient.name);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.surname, patient.surname);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.birthday, patient.birthday);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.street, patient.street);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.postalCode, patient.postalCode);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.city, patient.city);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.insuranceNumber, patient.insuranceNumber);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.insuranceName, patient.insuranceName);
          handleUntouchedPatientTextFieldUpdates(draft, PatientPropertyKeys.policyNumber, patient.policyNumber);
          handleUntouchedPatientNumericFieldUpdates(draft, PatientPropertyKeys.age, patient.age);
          handleUntouchedPatientSingleSelectFieldUpdates(draft, PatientPropertyKeys.gender, patient.gender);

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        PATIENT_DATA_RECORD_ELEMENT_KEY,
      );
    }
  }, [patient, adaptRecord, findNodes, nodeTypeOfPage]);

  function handleUntouchedPatientTextFieldUpdates<T extends PatientPropertyKeys>(
    draft: Draft<PatientDataRecord>,
    property: T,
    value: string | null | undefined,
  ): void {
    if (!draft.values?.touched?.options.includes(property)) {
      if (value != null && value !== '') {
        draft.values = {
          ...draft.values,
          [property]: {
            text: value,
            fieldType: 'text',
          },
        };
      } else {
        delete draft.values?.[property];
      }
    }
  }

  function handleUntouchedPatientNumericFieldUpdates<T extends PatientPropertyKeys>(
    draft: Draft<PatientDataRecord>,
    property: T,
    value: number | null | undefined,
  ): void {
    if (!draft.values?.touched?.options.includes(property)) {
      if (value != null) {
        draft.values = {
          ...draft.values,
          [property]: {
            number: value,
            fieldType: 'numeric',
          },
        };
      } else {
        delete draft.values?.[property];
      }
    }
  }

  function handleUntouchedPatientSingleSelectFieldUpdates<T extends PatientPropertyKeys>(
    draft: Draft<PatientDataRecord>,
    property: T,
    value: string | null | undefined,
  ): void {
    if (!draft.values?.touched?.options.includes(property)) {
      if (value != null) {
        draft.values = {
          ...draft.values,
          [property]: {
            option: value,
            fieldType: 'singleSelect',
          },
        };
      } else {
        delete draft.values?.[property];
      }
    }
  }

  const handleChangedPatientProperty: HandleChangedPatientProperty = useFreshCallback((property, value) => {
    adaptRecord(
      'generic',
      nodeTypeOfPage,
      (draft: Draft<PatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (!_.isEqual(value, draft.values?.[property])) {
          draft.values = {
            ...draft.values,
            touched: { options: _.union([property], draft.values?.touched?.options ?? []), fieldType: 'multiSelect' },
          };
        }

        if (value != null) {
          draft.values = {
            ...draft.values,
            [property]: value,
          };
        } else {
          delete draft.values?.[property];
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  const getTouchedClassName = useCallback(
    (property: PatientPropertyKeys) => {
      return !patientDataRecord.values?.touched?.options.includes(property) &&
        patientDataRecord.values?.[property] != null
        ? 'prefilled'
        : '';
    },
    [patientDataRecord],
  );

  const pageHeader = <PageHeader heading={getPageTitle(PageType.PatientMasterData)} noClearAllButton />;

  const pageFooter = (
    <PageFooter
      noBackButton
      pageType={PageType.PatientMasterData}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    >
      <div className='legend row'>
        <div className='indicator'></div>
        <Typography variant='subtitle1'>Automatisch übertragene Daten</Typography>
      </div>
      <div className='patient-master-data-wireframe'>
        <div className='patient-data'>
          <PatientData
            nodeType={nodeTypeOfPage}
            patientDataRecord={patientDataRecord}
            extendedPatientDataRecord={extendedPatientDataRecord}
            handleChangedPatientProperty={handleChangedPatientProperty}
            getTouchedClassName={getTouchedClassName}
          />
        </div>
        <Gender
          patientDataRecord={patientDataRecord}
          handleChangedPatientProperty={handleChangedPatientProperty}
          getTouchedClassName={getTouchedClassName}
        />
        <Bmi nodeType={nodeTypeOfPage} />
        <Age
          nodeType={nodeTypeOfPage}
          patientDataRecord={patientDataRecord}
          extendedPatientDataRecord={extendedPatientDataRecord}
          getTouchedClassName={getTouchedClassName}
        />
        <div className='mission'>
          <Mission nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
