import { Node } from '../backendModels/report.model';
import { NodeRecordTypeMap } from '../backendModels/records.model';
import { isNormalGcs } from '../backendModels/gcs.model';
import { InputState } from '../backendModels/general.model';
import { isNormalPupils } from '../backendModels/pupils.model';
import { EcgKeys } from './ecgFindings';
import { LIMB_MOVEMENTS_ELEMENT_KEY, LimbMovementsRecord } from './genericElements/limbMovements';

export interface Nodes {
  readonly nodes: Node[];
}

// Returns the enhanced input state, i.e. also uses local storage for record types that miss input state or deduce it from the other properties.
export function getEnhancedInputState(
  expectedRecordType: keyof NodeRecordTypeMap,
  records: NodeRecordTypeMap[keyof NodeRecordTypeMap][],
): InputState | null {
  if (records.length === 0) {
    return null;
  }

  const firstRecord = records[0];
  const recordType = firstRecord.type;

  if (recordType !== expectedRecordType) {
    throw new Error(
      `The records passed to getEnhancedInputState (${recordType}) should be of expectedRecordType (${expectedRecordType})`,
    );
  }

  if (recordType === 'gcs') {
    return isNormalGcs(firstRecord)
      ? InputState.NORMAL
      : firstRecord.motoricResponse != null || firstRecord.ocularResponse != null || firstRecord.oralResponse != null
        ? InputState.ENTERED
        : null;
  } else if (recordType === 'pupils') {
    return isNormalPupils(firstRecord) ? InputState.NORMAL : InputState.ENTERED;
  } else if (recordType === 'situationAndSymptoms') {
    return firstRecord.note == null &&
      (firstRecord.situationAndSymptoms == null || firstRecord.situationAndSymptoms.length === 0)
      ? InputState.NORMAL
      : InputState.ENTERED;
  } else if (recordType === 'note') {
    return firstRecord.text === '' || firstRecord.text == null ? InputState.NORMAL : InputState.ENTERED;
  } else if (recordType === 'medication') {
    return InputState.ENTERED;
  } else if (recordType === 'accessRoute') {
    return InputState.ENTERED;
  } else if (recordType === 'ecgFindings') {
    return firstRecord.inputState === InputState.ENTERED
      ? firstRecord.symptoms?.includes(EcgKeys.noEkg)
        ? InputState.UNKNOWN
        : InputState.ENTERED
      : InputState.NORMAL;
  } else if (recordType === 'oxygen') {
    return InputState.ENTERED;
  } else if (recordType === 'airwayManagement') {
    return firstRecord.treatments.length > 0 ? InputState.ENTERED : null;
  } else if (recordType === 'vitalParameters') {
    return firstRecord.vitalParameters.length > 0 ? InputState.ENTERED : null;
  } else if (recordType === 'intubation') {
    return InputState.ENTERED;
  } else if (recordType === 'generic' && firstRecord.elementKey === LIMB_MOVEMENTS_ELEMENT_KEY) {
    return firstRecord.inputState !== InputState.ENTERED
      ? firstRecord.inputState ?? null
      : (firstRecord as LimbMovementsRecord).values?.extremitaetenbewegungReArm?.number === 1
        ? InputState.NORMAL
        : InputState.ENTERED;
  } else if (recordType === 'trends') {
    return firstRecord.trends.length > 0 ? InputState.ENTERED : null;
  } else if (recordType === 'infusion' || recordType === 'traumaManagement' || recordType === 'temperatureManagement') {
    throw new Error(`hasInputEntered is not implemented for ${recordType}`);
  }
  return firstRecord.inputState ?? null;
}
