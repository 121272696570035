import { IconButton, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import style from './Events.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import AddEventDialog from './AddEventDialog';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import { MANUAL_EVENT_RECORD_ELEMENT_KEY, ManualEventRecord } from '../../../models/genericElements/events/manualEvent';
import _ from 'lodash';
import dayjs from 'dayjs';
import { getIconForName } from '../../../models/manualEvents/iconMap';
import {
  PredefinedEvent,
  predefinedEventsWithQuickButton,
  removeDuplicateUniquePredefinedEvents,
} from '../../../models/manualEvents/predefinedEvent';
import { getFormattedTime, sortByHHmm } from '../../../utils/util';
import { Add } from '@mui/icons-material';
import {
  END_EVENT_RECORD_ELEMENT_KEY,
  EndEventRecord,
  endEventsToVirtualManualEvents,
} from '../../../models/genericElements/events/endEvent';
import {
  ALARM_EVENT_RECORD_ELEMENT_KEY,
  AlarmEventRecord,
  alarmEventsToVirtualManualEvents,
} from '../../../models/genericElements/events/alarmEvent';
import {
  ARRIVAL_AT_LOCATION_EVENT_RECORD_ELEMENT_KEY,
  ArrivalAtLocationEventRecord,
  arrivalAtLocationEventsToVirtualManualEvents,
} from '../../../models/genericElements/events/arrivalAtLocationEvent';
import { ManualEventType } from '../../../models/manualEvents/config';

export function Events() {
  useCSS(style);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [dialogPresetEvent, setDialogPresetEvent] = useState<ManualEventType | null>(null);

  const { findMultipleRecords, manualEventTypes } = useContext(ReportsAPIContext);
  const eventRecords = findMultipleRecords(
    'generic',
    NodeType.GENERIC,
    MANUAL_EVENT_RECORD_ELEMENT_KEY,
  ) as ManualEventRecord[];

  removeDuplicateUniquePredefinedEvents(eventRecords);

  eventRecords.push(
    ...alarmEventsToVirtualManualEvents(
      findMultipleRecords('generic', NodeType.GENERIC, ALARM_EVENT_RECORD_ELEMENT_KEY) as AlarmEventRecord[],
    ),
    ...arrivalAtLocationEventsToVirtualManualEvents(
      findMultipleRecords(
        'generic',
        NodeType.GENERIC,
        ARRIVAL_AT_LOCATION_EVENT_RECORD_ELEMENT_KEY,
      ) as ArrivalAtLocationEventRecord[],
    ),
    ...endEventsToVirtualManualEvents(
      findMultipleRecords('generic', NodeType.GENERIC, END_EVENT_RECORD_ELEMENT_KEY) as EndEventRecord[],
    ),
  );

  const eventRecordsSorted = _.reverse(
    sortByHHmm(
      eventRecords.map((record) => ({
        timestamp: getFormattedTime(dayjs(record.values?.eventTimestamp?.text ?? '').valueOf()),
        record: record,
      })),
      'timestamp',
    ),
  );

  function openDialogWith(event: ManualEventType | null) {
    setDialogPresetEvent(event);
    setAddDialogOpen(true);
  }

  return (
    <div className='events-sidebar'>
      <AddEventDialog open={addDialogOpen} onOpenChanged={setAddDialogOpen} presetEvent={dialogPresetEvent} />
      <div className='header'>
        <Typography fontWeight='fontWeightBold'>Ereignisse</Typography>
      </div>
      <div className='quick-buttons'>
        {manualEventTypes?.map(
          (event) =>
            predefinedEventsWithQuickButton.has(event.name as PredefinedEvent) && (
              <Tooltip
                key={event.name}
                title={
                  <>
                    <Typography fontWeight='fontWeightBold' variant={'subtitle2'}>
                      {event.name}
                    </Typography>
                    {event.explanation !== undefined && <Typography variant={'body2'}>{event.explanation}</Typography>}
                  </>
                }
                disableInteractive
                arrow
              >
                <IconButton
                  className='quick-button'
                  onClick={() => {
                    openDialogWith(event);
                  }}
                >
                  {getIconForName(event.name, 'quick-button-icon')}
                </IconButton>
              </Tooltip>
            ),
        )}
        <IconButton
          className='add-button'
          onClick={() => {
            openDialogWith(null);
          }}
        >
          <Add />
        </IconButton>
      </div>
      <div className='event-timeline'>
        {eventRecordsSorted.map(({ record }, index) => (
          <div key={index} className='event'>
            <div>{record.values?.eventName?.text ?? ''}</div>
            <div className='event-timestamp'>
              {getFormattedTime(dayjs(record.values?.eventTimestamp?.text ?? '').valueOf())}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
