import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import RadioList from '../../../customs/radioList/RadioList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import {
  MEDICAL_CONDITION_OTHER_ELEMENT_KEY,
  MedicalConditionOtherRecord,
  getMedicalConditionOtherTupelsFor,
  MedicalConditionOtherKeys,
} from '../../../../models/genericElements/medicalConditions/other';
import _ from 'lodash';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkboxes1: [MedicalConditionOtherKeys, string][] = getMedicalConditionOtherTupelsFor([
  MedicalConditionOtherKeys.anaphylaktischeReaktion,
]);

const anaphylaktischKeys = [
  MedicalConditionOtherKeys.anaphylaktischeReaktionI_II,
  MedicalConditionOtherKeys.anaphylaktischeReaktionIII_IV,
];

const anaphylaktischTuples: [MedicalConditionOtherKeys, string][] =
  getMedicalConditionOtherTupelsFor(anaphylaktischKeys);

const checkboxes2: [MedicalConditionOtherKeys, string][] = getMedicalConditionOtherTupelsFor([
  MedicalConditionOtherKeys.hitzschlag,
  MedicalConditionOtherKeys.erfrierung,
  MedicalConditionOtherKeys.sepsis,
  MedicalConditionOtherKeys.covid,
  MedicalConditionOtherKeys.influenza,
  MedicalConditionOtherKeys.hepatitis,
  MedicalConditionOtherKeys.lumbago,
  MedicalConditionOtherKeys.epistaxis,
  MedicalConditionOtherKeys.sozialesProblem,
  MedicalConditionOtherKeys.iatrogen,
]);

interface MedicalConditionOtherProps {
  nodeType: NodeType;
}

export default function MedicalConditionOther({ nodeType }: MedicalConditionOtherProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_OTHER_ELEMENT_KEY,
  ) as MedicalConditionOtherRecord;
  const conditions =
    record.inputState === InputState.ENTERED ? (record.values.erkrankungenSonstige?.options ?? []) : [];

  const radioValue =
    _.intersection(conditions, [
      MedicalConditionOtherKeys.anaphylaktischeReaktionI_II,
      MedicalConditionOtherKeys.anaphylaktischeReaktionIII_IV,
    ])[0] ?? null;

  const conditionsChange = (newConditions: MedicalConditionOtherKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionOtherRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenSonstige: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_OTHER_ELEMENT_KEY,
    );
  };

  const radioChange = (value: string | null) => {
    const filteredConditions = _.difference(conditions, anaphylaktischKeys);

    const newConditions =
      value !== null
        ? _.union(filteredConditions, [
            value as MedicalConditionOtherKeys,
            MedicalConditionOtherKeys.anaphylaktischeReaktion,
          ])
        : _.union(filteredConditions, [MedicalConditionOtherKeys.anaphylaktischeReaktion]);

    conditionsChange(newConditions);
  };

  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionOtherRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenSonstigeSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenSonstigeSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_OTHER_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionOther}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_OTHER_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_OTHER_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkboxes1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              newConditions.includes(MedicalConditionOtherKeys.anaphylaktischeReaktion)
                ? newConditions
                : _.difference(newConditions, [
                    MedicalConditionOtherKeys.anaphylaktischeReaktionI_II,
                    MedicalConditionOtherKeys.anaphylaktischeReaktionIII_IV,
                  ]),
            )
          }
        />
        <RadioList className='indented' items={anaphylaktischTuples} value={radioValue} onChange={radioChange} />
        <CheckboxList items={checkboxes2} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={record.inputState === InputState.ENTERED ? (record.values.erkrankungenSonstigeSonstige?.text ?? '') : ''}
        onDebounceChange={otherChange}
        inputProps={{ maxLength: 18 }}
      />
    </DiviFieldCard>
  );
}
