import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../generic';

export enum PrivatelyInsuredKeys {
  isPrivatelyInsured = 'isPrivatelyInsured',
}

const privatelyInsuredLabels: Record<PrivatelyInsuredKeys, string> = {
  [PrivatelyInsuredKeys.isPrivatelyInsured]: 'Privatpatient',
};

export const PRIVATE_HEALTH_INSURANCE_RECORD_ELEMENT_KEY = 'privateHealthInsurance';

export type PrivateHealthInsuranceRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof PRIVATE_HEALTH_INSURANCE_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          privatelyInsured?: MultiSelectFieldValueTyped<PrivatelyInsuredKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getPrivatelyInsuredTuple(key: PrivatelyInsuredKeys): [PrivatelyInsuredKeys, string] {
  return [key, privatelyInsuredLabels[key]];
}

export function getPrivatelyInsuredTuplesFor(keys: PrivatelyInsuredKeys[]): [PrivatelyInsuredKeys, string][] {
  return keys.map((k) => {
    return getPrivatelyInsuredTuple(k);
  });
}
