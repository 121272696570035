import { useCSS } from '../../provider/CSSProvider';
import style from './SideBar.scss';
import React from 'react';
import PatientRecord from './PatientRecord/PatientRecord';

function SideBar() {
  useCSS(style);

  return (
    <div id='SideBar'>
      <PatientRecord />
    </div>
  );
}

export default SideBar;
