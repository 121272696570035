import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import {
  BmiKeys,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getBmiTuplesFor,
} from '../../../../models/genericElements/extendedPatientData';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { useFreshCallback } from '../../../../utils/hooks';
import { isGenericRecordDeletable } from '../../../../models/generic';

const bmiRadioItems: [BmiKeys, string][] = getBmiTuplesFor([BmiKeys.unterOderGleich40, BmiKeys.ueber40]);

interface BmiProps {
  nodeType: NodeType;
}

export default function Bmi({ nodeType }: BmiProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const handleBmiChange = useFreshCallback((value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            bmi: {
              fieldType: 'singleSelect',
              option: value as BmiKeys,
            },
          };
        } else {
          delete draft.values?.bmi;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.Bmi}>
      <RadioList
        className='symptoms-list'
        items={bmiRadioItems}
        value={record.values?.bmi?.option ?? null}
        onChange={handleBmiChange}
      />
    </DiviFieldCard>
  );
}
