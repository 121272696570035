import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { getGenderTuplesFor } from '../../../../models/genericElements/extendedPatientData';
import { PatientGender } from '../../../../backendModels/patient.model';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';
import { NodeType } from '../../../../backendModels/report.model';
import { changePropertyAndMarkTouched, getTouchedClassName } from '../../../../utils/recordWithTouched';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';

const genderRadioItems: [PatientGender, string][] = getGenderTuplesFor([
  PatientGender.MALE,
  PatientGender.FEMALE,
  PatientGender.UNKNOWN,
]);

interface GenderProps {
  nodeType: NodeType;
}

export default function Gender({ nodeType }: GenderProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;

  const handleGenderChange = (value: string | null) => {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      PATIENT_DATA_RECORD_ELEMENT_KEY,
      PatientPropertyKeys.gender,
      value != null ? { option: value as PatientGender, fieldType: 'singleSelect' } : undefined,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Gender}>
      <RadioList
        className={`symptoms-list ${getTouchedClassName(record, PatientPropertyKeys.gender)}`}
        items={genderRadioItems}
        value={record?.values?.gender?.option ?? null}
        onChange={handleGenderChange}
      />
    </DiviFieldCard>
  );
}
