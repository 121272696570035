import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PageHeaderProps } from './PageHeader/PageHeader';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { getEnhancedInputState } from '../../../models/custom';
import { NodeType } from '../../../backendModels/report.model';
import { PageFooterProps } from './PageFooter/PageFooter';
import {
  DIVI_PROGRESS_STATE_STORAGE_KEY,
  DiviNavigationIds,
  DiviProgress,
  DiviProgressStatesType,
} from '../../../models/diviProgress';
import { LocalStorageContext } from '../../../provider/LocalStorageProvider';
import { useFreshCallback } from '../../../utils/hooks';
import { defaultRecords } from '../../../DefaultRecords';
import { IntubationTypeKeys } from '../../../models/intubation';

interface DiviPageProps {
  pageHeader: ReactElement<PageHeaderProps>;
  pageFooter: ReactElement<PageFooterProps>;
  recordTypes: (keyof BaseNodeRecordValueTypeMap)[];
  elementKeys?: string[]; // Do not include generic in the recordTypes
  nodeType: NodeType;
  children?: ReactNode;
  noPathButton?: boolean;
  diviId: DiviNavigationIds;
}

export type ResetFunctionType = () => void;

export interface DiviPageContextType {
  subscribeToReset: (f: ResetFunctionType) => void;
  unsubscribeFromReset: (f: ResetFunctionType) => void;
  handleNoPathologicalFindings: () => void;
  handleClearAll: () => void;
  divPageContent: HTMLDivElement | null;
}

const contextDefaultValue: DiviPageContextType = {
  subscribeToReset: () => {
    throw new Error('Uninitialized');
  },
  unsubscribeFromReset: () => {
    throw new Error('Uninitialized');
  },
  handleNoPathologicalFindings: () => {
    throw new Error('Uninitialized');
  },
  handleClearAll: () => {
    throw new Error('Uninitialized');
  },
  divPageContent: null,
};

export const DiviPageContext = createContext<DiviPageContextType>(contextDefaultValue);

export default function DiviPage({
  pageHeader,
  pageFooter,
  children,
  recordTypes,
  nodeType,
  elementKeys,
  diviId,
}: DiviPageProps) {
  const localStorage = useContext(LocalStorageContext);
  const [state, setState] = localStorage.asState<DiviProgressStatesType>(DIVI_PROGRESS_STATE_STORAGE_KEY);
  const [divPageContent, setDivPageContent] = useState<HTMLDivElement | null>(null);

  const resetFunctions = useRef(new Set<ResetFunctionType>());
  const reportAPI = useContext(ReportsAPIContext);
  const medicationNodes = reportAPI.findNodeWithRecord(nodeType, 'medication');

  const hasAnyEntered =
    recordTypes.some(
      (recordType) => getEnhancedInputState(recordType, reportAPI.findMultipleRecords(recordType, nodeType)) != null,
    ) ||
    (elementKeys?.some(
      (elementKey) =>
        getEnhancedInputState('generic', reportAPI.findMultipleRecords('generic', nodeType, elementKey)) != null,
    ) ??
      false);

  useEffect(() => {
    if (hasAnyEntered && (state?.[diviId] ?? DiviProgress.todo) === DiviProgress.todo) {
      setState((oldData) => {
        return { ...oldData, [diviId]: DiviProgress.touched };
      });
    } else if (!hasAnyEntered && state?.[diviId] === DiviProgress.touched) {
      setState((oldData) => {
        return { ...oldData, [diviId]: DiviProgress.todo };
      });
    }
  }, [hasAnyEntered, diviId, state, setState]);

  const subscribeToReset = useCallback((resetFunction: ResetFunctionType) => {
    resetFunctions.current.add(resetFunction);
  }, []);

  const unsubscribeFromReset = useCallback((resetFunction: ResetFunctionType) => {
    resetFunctions.current.delete(resetFunction);
  }, []);

  const handleNoPathologicalFindings = useFreshCallback(() => {
    for (const resetFunction of resetFunctions.current) {
      resetFunction();
    }
  });

  const handleClearAll = useFreshCallback(() => {
    recordTypes.forEach((type) => {
      if (type === 'medication') {
        medicationNodes.forEach((node) => reportAPI.deleteNode(node.id));
      } else if (type === 'airwayManagement') {
        reportAPI.adaptRecord(
          defaultRecords.intubation.type,
          nodeType,
          (draft, deleteRecord) => {
            deleteRecord();
          },
          (record) => record.intubation === IntubationTypeKeys.endotracheal,
        );
        reportAPI.adaptRecord(
          defaultRecords.intubation.type,
          nodeType,
          (draft, deleteRecord) => {
            deleteRecord();
          },
          (record) =>
            record.intubation === IntubationTypeKeys.laryngealMask ||
            record.intubation === IntubationTypeKeys.laryngealTube,
        );
        reportAPI.adaptRecord(defaultRecords.airwayManagement.type, nodeType, (draft, deleteRecord) => {
          deleteRecord();
        });
      } else {
        reportAPI.adaptRecord(type, nodeType, (draft, deleteRecord) => {
          deleteRecord();
        });
      }
    });
    elementKeys?.forEach((type) => {
      reportAPI.adaptRecord(
        'generic',
        nodeType,
        (draft, deleteRecord) => {
          deleteRecord();
        },
        type,
      );
    });
  });

  const contextValue = useMemo<DiviPageContextType>(
    () => ({
      subscribeToReset,
      unsubscribeFromReset,
      handleNoPathologicalFindings,
      handleClearAll,
      divPageContent,
    }),
    [subscribeToReset, unsubscribeFromReset, handleNoPathologicalFindings, handleClearAll, divPageContent],
  );

  return (
    <>
      <DiviPageContext.Provider value={contextValue}>
        {pageHeader}
        <div className='area-page-content' ref={setDivPageContent}>
          {children}
        </div>
        {pageFooter}
      </DiviPageContext.Provider>
    </>
  );
}
export const useDiviPageContext = () => useContext(DiviPageContext);
