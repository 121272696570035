import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, RadioGroupProps, RadioProps } from '@mui/material';
import * as React from 'react';

export type RadioLabel = string;

interface RadioListProps<T> extends Omit<RadioGroupProps, 'onChange'> {
  /**
   * Items consisting of tuples: (value, RadioLabel).
   */
  items: [T, RadioLabel][];
  /**
   * Props for the form control label.
   */
  formControlLabelProps?: Omit<FormControlLabelProps, 'key' | 'value' | 'control' | 'label'>;
  /**
   * Props for the radio.
   */
  radioProps?: RadioProps;
  /**
   * Callback fired when a radio button is selected.
   *
   * @param {string} value The value of the selected radio button.
   */
  onChange?: (value: string | null) => void;
}

export default function RadioList<T>(props: RadioListProps<T>) {
  const { items, formControlLabelProps, radioProps, value, onChange, children, ...radioGroupProps } = props;

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const target = event.target as HTMLInputElement;
    if (target.value === value) {
      onChange?.(null);
    } else {
      onChange?.(target.value);
    }
  }

  return (
    <RadioGroup {...radioGroupProps} value={value}>
      {items.map(([val, label], index) => (
        <FormControlLabel
          {...formControlLabelProps}
          key={index}
          value={val}
          control={<Radio {...radioProps} onClick={handleClick} />}
          label={label}
        />
      ))}
      {children}
    </RadioGroup>
  );
}
