import React from 'react';
import { ToggleButton } from '@mui/material';
import { useCSS } from '../../../provider/CSSProvider';
import style from './SmileyRating.scss?inline';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InjuriesCategorySeverityKeys } from '../../../models/genericElements/injuries/InjuriesCategory';
import { Severity } from '../../../backendModels/injuries.model';

interface SmileyRatingProps {
  onChange: (value: Severity | null) => void;
  value: Severity | null;
}
export default function SmileyRating({ onChange, value }: SmileyRatingProps) {
  useCSS(style);

  return (
    <ToggleButtonGroup className='similey-rating' value={value ?? null} exclusive>
      <ToggleButton
        className='smiley-rating-positive'
        value={InjuriesCategorySeverityKeys.minor}
        onClick={() =>
          onChange(value === InjuriesCategorySeverityKeys.minor ? null : InjuriesCategorySeverityKeys.minor)
        }
      >
        <SentimentSatisfiedAltIcon />
      </ToggleButton>
      <ToggleButton
        className='smiley-rating-neutral'
        value={InjuriesCategorySeverityKeys.medium}
        onClick={() =>
          onChange(value === InjuriesCategorySeverityKeys.medium ? null : InjuriesCategorySeverityKeys.medium)
        }
      >
        <SentimentNeutralIcon />
      </ToggleButton>
      <ToggleButton
        className='smiley-rating-negative'
        value={InjuriesCategorySeverityKeys.major}
        onClick={() =>
          onChange(value === InjuriesCategorySeverityKeys.major ? null : InjuriesCategorySeverityKeys.major)
        }
      >
        <SentimentVeryDissatisfiedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
