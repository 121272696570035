import React, { useContext, useState } from 'react';
import globalStyle from '../../../../global.scss?inline';
import { Button, ButtonGroup, FormControl, Typography } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { useCSS } from '../../../../provider/CSSProvider';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import TextFieldSelectGroup from '../../../customs/textFieldSelectGroup/TextFieldSelectGroup';
import style from './VitalParameter.scss?inline';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import {
  BloodGlucoseUnitLabels,
  convertVitalParametersToMapping,
  defaultUnit,
  VITAL_PARAMETER_UNIT_STORAGE_KEY,
  VitalParameterKeys,
  VitalParametersMappingType,
  VitalParametersRecord,
  VitalParameterUnitKeys,
  VitalParameterUnitType,
} from '../../../../models/vitalParameters';
import { VitalParameterValue } from '../../../../backendModels/vital-parameters.model';
import { NodeType } from '../../../../backendModels/report.model';
import { mutateArray } from '../../../../utils/util';
import { CardId } from '../../../../models/diviCard';
import { LocalStorageContext } from '../../../../provider/LocalStorageProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import QSofa from '../../../customs/qSofa/qsofa';
import { InputState } from '../../../../backendModels/general.model';
import { CirculationKeys, getCirculationTuplesFor, UsedCirculationKeys } from '../../../../models/circulaton';
import { Draft } from 'immer';
import {
  BloodGlucoseAdditionKeys,
  BloodGlucoseAdditionRecord,
  BLUTZUCKER_ERWEITERT_ELEMENT_KEY,
  getBloodGlucoseAdditionTupel,
} from '../../../../models/genericElements/bloodGlucose';
import {
  getVitalParameterAdditionTuplesFor,
  VITALPARAMETER_ERWEITERT_ELEMENT_KEY,
  VitalParameterAdditionKeys,
  VitalParameterAdditionRecord,
} from '../../../../models/genericElements/vitalParameter';
import RadioList from '../../../customs/radioList/RadioList';
import { isGenericRecordDeletable } from '../../../../models/generic';
import _ from 'lodash';

interface VitalParameterProps {
  nodeType: NodeType;
}

const vitalParametersAdditionItems: [VitalParameterAdditionKeys, string][] = getVitalParameterAdditionTuplesFor([
  VitalParameterAdditionKeys.raumluft,
  VitalParameterAdditionKeys.o2gabe,
]);

const rhytmicArrythmicKeys: UsedCirculationKeys[] = [CirculationKeys.rhythmisch, CirculationKeys.arrhythmisch];
const rhythmicArrythmicItems = getCirculationTuplesFor(rhytmicArrythmicKeys);

function roundToThreeDigits(number: number) {
  if (number > 999) {
    return 999;
  }
  if (number > 100) {
    return Math.round(number);
  }
  if (number > 10) {
    return Math.round(number * 10) / 10;
  }
  return Math.round(number * 100) / 100;
}

const conversionNumber = 18.0182;

function convertMilligrammPerDeciliterToMillimolePerLiter(value?: number) {
  if (value) return roundToThreeDigits(Number(value) / conversionNumber);
}

function convertMillimolePerLiterToMilligrammPerDeciliter(value?: number) {
  if (value) return roundToThreeDigits(Number(value) * conversionNumber);
}

export default function VitalParameter({ nodeType }: VitalParameterProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record: VitalParametersRecord = reportAPI.findRecordOrDefault(defaultRecords.vitalParameters.type, nodeType);
  const genericRecordBZ = findRecordOrDefault(
    'generic',
    nodeType,
    BLUTZUCKER_ERWEITERT_ELEMENT_KEY,
  ) as BloodGlucoseAdditionRecord;
  const genericRecordVP = findRecordOrDefault(
    'generic',
    nodeType,
    VITALPARAMETER_ERWEITERT_ELEMENT_KEY,
  ) as VitalParameterAdditionRecord;

  const circulationRecord = reportAPI.findRecordOrDefault(defaultRecords.circulation.type, nodeType);

  const vitalParameters: VitalParametersMappingType = convertVitalParametersToMapping(record.vitalParameters);
  const nonInvasiveSystolicArterialPressure = vitalParameters[VitalParameterKeys.nonInvasiveSystolicArterialPressure];
  const nonInvasiveDiastolicArterialPressure = vitalParameters[VitalParameterKeys.nonInvasiveDiastolicArterialPressure];
  const heartRate = vitalParameters[VitalParameterKeys.heartRate];

  const totalBreathRate = vitalParameters[VitalParameterKeys.totalBreathRate];
  const capillaryOxygenSaturation = vitalParameters[VitalParameterKeys.capillaryOxygenSaturation];
  const temperature = vitalParameters[VitalParameterKeys.temperature];
  const endTidalCarbonDioxideTension = vitalParameters[VitalParameterKeys.endTidalCarbonDioxideTension];

  const localStorageBGKey = VitalParameterKeys.bloodGlucose;

  const localStorage = useContext(LocalStorageContext);
  const [vitalUnits, setVitalUnit] = localStorage.asState<VitalParameterUnitType>(VITAL_PARAMETER_UNIT_STORAGE_KEY);
  const bloodGlucoseUnit = vitalUnits?.[localStorageBGKey] || VitalParameterUnitKeys.milligramPerDeciliter;

  const bloodGlucoseValue = vitalParameters[VitalParameterKeys.bloodGlucose];

  const bloodGlucose =
    bloodGlucoseUnit === VitalParameterUnitKeys.millimolePerLiter &&
    vitalParameters[VitalParameterKeys.bloodGlucose] &&
    bloodGlucoseValue?.value != null
      ? convertMilligrammPerDeciliterToMillimolePerLiter(bloodGlucoseValue?.value)
      : bloodGlucoseValue?.value;
  const [displayedBGValue, setDisplayedBGValue] = useState(bloodGlucose ?? null);

  const lowHighState = genericRecordBZ.values?.blutzuckerLowHigh?.option || undefined;
  const o2State = genericRecordVP.values?.spo2Gemessen?.option || null;

  const [showWarning, setShowWarning] = useState(false);

  function getRhythmicArrhythmic(): string | null {
    return circulationRecord.inputState === InputState.ENTERED
      ? (circulationRecord.symptoms?.find(
          (s) => s === CirculationKeys.rhythmisch || s === CirculationKeys.arrhythmisch,
        ) ?? null)
      : null;
  }

  function setRhythmicArrhythmic(value: string | null) {
    reportAPI.adaptRecord(defaultRecords.circulation.type, nodeType, (draft, deleteRecord) => {
      const symptoms = circulationRecord.inputState === InputState.ENTERED ? circulationRecord.symptoms : [];
      const symptomsWithoutRadio = _.difference(symptoms, rhytmicArrythmicKeys);
      if (value == null && symptomsWithoutRadio.length === 0) {
        deleteRecord();
      } else {
        return {
          ...draft,
          inputState: InputState.ENTERED,
          symptoms: [...symptomsWithoutRadio, ...(value !== null ? [value] : [])],
        };
      }
    });
  }

  function showHint() {
    if (record.vitalParameters.length === 0) {
      setShowWarning(true);
    }
  }

  function createOrUpdateRecord(vitalParameterType: VitalParameterKeys, value: string, unit?: VitalParameterUnitKeys) {
    setShowWarning(false);
    reportAPI.adaptRecord(defaultRecords.vitalParameters.type, nodeType, (draft, deleteRecord) => {
      if (value === '') {
        const newVitalParameters = draft.vitalParameters.filter((parameter) => parameter.type !== vitalParameterType);

        if (newVitalParameters.length > 0) {
          draft.vitalParameters = newVitalParameters;
        } else {
          deleteRecord();
        }
        return;
      }

      const vitalParameterValue: VitalParameterValue = {
        type: vitalParameterType,
        unit: unit ?? defaultUnit[vitalParameterType],
        value: Number(value),
      };

      draft.vitalParameters = mutateArray(
        draft.vitalParameters,
        (vitalParameter) => vitalParameter.type === vitalParameterType,
        vitalParameterValue,
      );
    });
  }

  const handleNonInvasiveSystolicArterialPressureChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.nonInvasiveSystolicArterialPressure, value);
  });

  const handleNonInvasiveDiastolicArterialPressureChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.nonInvasiveDiastolicArterialPressure, value);
  });

  const handleHeartRateChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.heartRate, value);
  });

  const handleBloodGlucoseChange = useFreshCallback((value: string) => {
    if (!value || value === '') {
      handleBloodGlucoseChangeToBackend('');
      setDisplayedBGValue(null);
      return;
    }
    const numberValue = Number(value);
    const roundedValue = roundToThreeDigits(numberValue);
    setDisplayedBGValue(roundedValue);

    if (bloodGlucoseUnit === VitalParameterUnitKeys.milligramPerDeciliter) {
      handleBloodGlucoseChangeToBackend(roundedValue.toString());
    } else if (bloodGlucoseUnit === VitalParameterUnitKeys.millimolePerLiter) {
      handleBloodGlucoseChangeToBackend(
        Number(convertMillimolePerLiterToMilligrammPerDeciliter(numberValue)).toString(),
      );
    }
    setShowWarning(false);
  });

  const handleBloodGlucoseChangeToBackend = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.bloodGlucose, value, VitalParameterUnitKeys.milligramPerDeciliter);
  });

  const handleBloodGlucoseUnitChange = useFreshCallback((value: VitalParameterUnitKeys) => {
    setShowWarning(false);
    setVitalUnit((oldData) => {
      return {
        ...oldData,
        [localStorageBGKey]: value,
      };
    });
    if (value === VitalParameterUnitKeys.milligramPerDeciliter && displayedBGValue) {
      handleBloodGlucoseChangeToBackend(Number(displayedBGValue)?.toString());
    } else if (value === VitalParameterUnitKeys.millimolePerLiter && displayedBGValue) {
      handleBloodGlucoseChangeToBackend(
        Number(convertMillimolePerLiterToMilligrammPerDeciliter(displayedBGValue)).toString(),
      );
    }
  });

  const setLowHigh = useFreshCallback((value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<BloodGlucoseAdditionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            blutzuckerLowHigh: {
              fieldType: 'singleSelect',
              option: value as BloodGlucoseAdditionKeys,
            },
          };
        } else {
          delete draft.values?.blutzuckerLowHigh;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      BLUTZUCKER_ERWEITERT_ELEMENT_KEY,
    );
  });

  const setVitalParamAddition = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VitalParameterAdditionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value != null) {
          draft.values = {
            ...draft.values,
            spo2Gemessen: {
              fieldType: 'singleSelect',
              option: value as VitalParameterAdditionKeys,
            },
          };
        } else {
          delete draft.values?.spo2Gemessen;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VITALPARAMETER_ERWEITERT_ELEMENT_KEY,
    );
  };

  const handleTotalBreathRateChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.totalBreathRate, value);
  });

  const handleCapillaryOxygenSaturationChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.capillaryOxygenSaturation, value);
  });

  const handleTemperatureChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.temperature, value);
  });

  const handleEndTidalCarbonDioxideTensionChange = useFreshCallback((value: string) => {
    createOrUpdateRecord(VitalParameterKeys.endTidalCarbonDioxideTension, value);
  });

  return (
    <DiviFieldCard
      cardType={CardId.VitalParameter}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={showHint}
      warningMessage={showWarning ? 'Es wurden noch keine Vitalparameter erfasst.' : undefined}
    >
      <div id='vitalparameter-area'>
        <div className='vital-param-wireframe no-margin-top'>
          <div>
            <DebouncedTextField
              fullWidth
              type='number'
              inputProps={{ step: 1, min: 1 }}
              label='RR [mmHg]'
              variant='outlined'
              onDebounceChange={handleNonInvasiveSystolicArterialPressureChange}
              value={nonInvasiveSystolicArterialPressure?.value?.toString() ?? ''}
            />
            <Typography className='center-slash'>/</Typography>
          </div>
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label='RR [mmHg]'
            variant='outlined'
            onDebounceChange={handleNonInvasiveDiastolicArterialPressureChange}
            value={nonInvasiveDiastolicArterialPressure?.value?.toString() ?? ''}
          />
          <div>
            <DebouncedTextField
              fullWidth
              type='number'
              inputProps={{ step: 1, min: 1 }}
              label='HF [1/min]'
              variant='outlined'
              onDebounceChange={handleHeartRateChange}
              value={heartRate?.value?.toString() ?? ''}
            />
            <FormControl className='additional-info'>
              <RadioList
                className='row'
                items={rhythmicArrythmicItems}
                value={getRhythmicArrhythmic()}
                onChange={setRhythmicArrhythmic}
              />
            </FormControl>
          </div>
          <div className='row'>
            <TextFieldSelectGroup
              fullWidth
              type='number'
              inputProps={{ max: 999, min: 0.1, step: 'any' }}
              label={`BZ [${BloodGlucoseUnitLabels[bloodGlucoseUnit]}]`}
              variant='outlined'
              options={Object.entries(BloodGlucoseUnitLabels) as [VitalParameterUnitKeys, string][]}
              selectedOption={bloodGlucoseUnit}
              onDebounceChange={handleBloodGlucoseChange}
              onSelectedOptionChange={handleBloodGlucoseUnitChange}
              value={bloodGlucoseValue === undefined ? '' : (displayedBGValue?.toString() ?? '')}
            />
            <ButtonGroup orientation='vertical' variant='outlined' size='small'>
              <Button
                key='low'
                onClick={() =>
                  setLowHigh(lowHighState == BloodGlucoseAdditionKeys.bzlow ? null : BloodGlucoseAdditionKeys.bzlow)
                }
                variant={lowHighState == BloodGlucoseAdditionKeys.bzlow ? 'contained' : 'outlined'}
              >
                {getBloodGlucoseAdditionTupel(BloodGlucoseAdditionKeys.bzlow)[1]}
              </Button>
              <Button
                key='high'
                onClick={() =>
                  setLowHigh(lowHighState == BloodGlucoseAdditionKeys.bzhigh ? null : BloodGlucoseAdditionKeys.bzhigh)
                }
                variant={lowHighState == BloodGlucoseAdditionKeys.bzhigh ? 'contained' : 'outlined'}
              >
                {getBloodGlucoseAdditionTupel(BloodGlucoseAdditionKeys.bzhigh)[1]}
              </Button>
            </ButtonGroup>
          </div>
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label='AF [1/min]'
            variant='outlined'
            onDebounceChange={handleTotalBreathRateChange}
            value={totalBreathRate?.value?.toString() ?? ''}
          />
          <div>
            <DebouncedTextField
              fullWidth
              type='number'
              inputProps={{ step: 1, min: 1 }}
              label={
                <>
                  SpO<sub>2</sub> [%]
                </>
              }
              variant='outlined'
              onDebounceChange={handleCapillaryOxygenSaturationChange}
              value={capillaryOxygenSaturation?.value?.toString() ?? ''}
            />
            <FormControl className='additional-info'>
              <RadioList
                name='vitalparam-addition-group'
                className='row'
                items={vitalParametersAdditionItems}
                value={o2State}
                onChange={setVitalParamAddition}
              />
            </FormControl>
          </div>
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 0.1, min: 1 }}
            label='Temp [°C]'
            variant='outlined'
            onDebounceChange={handleTemperatureChange}
            value={temperature?.value?.toString() ?? ''}
          />
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label={
              <>
                etCO<sub>2</sub> [mmHg]
              </>
            }
            variant='outlined'
            onDebounceChange={handleEndTidalCarbonDioxideTensionChange}
            value={endTidalCarbonDioxideTension?.value?.toString() ?? ''}
          />
        </div>
        <QSofa nodeType={nodeType} />
      </div>
    </DiviFieldCard>
  );
}
