import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { CardId } from '../../../../models/diviCard';
import { NodeType } from '../../../../backendModels/report.model';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  DiagnosisInitialRecord,
} from '../../../../models/genericElements/diagnosisInitial';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface DiagnosisNoteProps {
  nodeType: NodeType;
}

export default function DiagnosisNote({ nodeType }: DiagnosisNoteProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  ) as DiagnosisInitialRecord;

  const handleNoteChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DiagnosisInitialRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value !== '') {
          draft.values = {
            ...draft.values,
            erstdiagnosen: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erstdiagnosen;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.DiagnosisNote}>
      <DebouncedTextField
        className='no-margin-top'
        placeholder='...'
        multiline
        fullWidth
        rows={15}
        onDebounceChange={handleNoteChange}
        value={record.inputState === InputState.ENTERED ? record.values?.erstdiagnosen?.text ?? '' : ''}
      />
    </DiviFieldCard>
  );
}
