import _ from 'lodash';

function nonNull<T>(value: T | undefined | null): value is T {
  return value != null;
}

function nonEmptyArray<T>(value: readonly T[] | undefined | null): value is [T, ...T[]] {
  return value != null && value.length > 0;
}

// Returns true if the passed string is (case-insensitive) "true" or a nonzero,non-nan number
function parseBoolean(value: string | null | undefined) {
  if (value == null) {
    return false;
  }

  return value.toLowerCase() === 'true' || !!Number(value);
}

// Convert an ArrayBuffer to a string that contains the Base64 representation of the ArrayBuffer
function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  let binaryString = '';

  // We have to use a loop here. ... exceeds the maximum stack size
  for (let i = 0; i < uint8Array.length; i += 1) {
    binaryString += String.fromCharCode(uint8Array[i]);
  }

  return btoa(binaryString);
}

// Returns a copy of the array with the item at index replaced with newItem.
// It is possible to pass a filter function instead of index.
// If index is -1 or no old item matches the filter, newItem will be appended to the array.
function mutateArray<T>(baseArray: readonly T[], index: number | ((oldItem: T) => boolean), newItem: T) {
  if (typeof index === 'function') {
    index = baseArray.findIndex(index);
  }
  if (index === -1) {
    return [...baseArray, newItem];
  }
  return Object.assign([], baseArray, { [index]: newItem });
}

// Recursively freezes an object, including all properties and array items.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepFreeze(value: any) {
  // Contrary to the type inlay, this also iterates arrays indices.
  for (const property of Object.keys(value)) {
    const propertyValue = value[property];
    // The type of an array is also "object"
    if (propertyValue != null && typeof propertyValue === 'object' && !Object.isFrozen(propertyValue)) {
      deepFreeze(propertyValue);
    }
  }

  return Object.freeze(value);
}

/**
 * Functions like _.merge, but creates a new object instead of mutating the object.
 */
function cloneAndMerge<O, S>(object: O, sources: S): O & S {
  const objectClone = _.cloneDeep(object);
  return _.merge(objectClone, sources);
}

/**
 * Converts a number to a roman numeral string.
 */
function convertToRomanNumber(number: number): string {
  if (number > 10) {
    throw Error('Roman numerals > 10 are not supported');
  }
  const lookup: { [key: string]: number } = {
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let roman = '';
  for (const i in lookup) {
    while (number >= lookup[i]) {
      roman += i;
      number -= lookup[i];
    }
  }
  return roman;
}

// Returns a copy with all properties that are null or undefined recursively removed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeNullAndUndefinedRecursively(data: any): any {
  if (_.isArray(data)) {
    return data.filter((value) => value != null).map((value) => removeNullAndUndefinedRecursively(value));
  } else if (_.isObject(data)) {
    return Object.fromEntries(
      Object.entries(data)
        .filter(([, value]) => value != null)
        .map(([key, value]) => [key, removeNullAndUndefinedRecursively(value)]),
    );
  }
  return data;
}

// Compare two object recursively, while ignoring the id.
// Null, undefined and undefined property are all treated as the same.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEqualSoftNullIgnoreId(left: any, right: any) {
  return _.isEqualWith(
    removeNullAndUndefinedRecursively(left),
    removeNullAndUndefinedRecursively(right),
    (left, right, key) => (key === 'id' ? true : undefined),
  );
}

// Call this function with the condition variable in a switch case/if when the switch case/if should be exhaustive.
// It will cause a compiler error when the switch/case if is not exhaustive.
function assertUnreachable(x: never): never {
  throw new Error(`Didn't expect to get here, parameter: ${x}`);
}

// Add key to set if value is true and set does not already contain key.
// Remove key from set if value is false.
// Accepts an array for key.
// Does not modify set and returns a copy instead.
function updateSet<T>(set: T[], key: T | T[], value: boolean) {
  if (!_.isArray(key)) {
    key = [key];
  }
  if (value) {
    return _.union(set, key);
  } else {
    return _.difference(set, key);
  }
}

// Also unchecks children if the parent is unchecked.
// Use the return value of this function for the update function of the record symptoms/conditions.
function uncheckChildren<ENUM_TYPE>(newValues: ENUM_TYPE[], parent: ENUM_TYPE, children: ENUM_TYPE[]) {
  if (newValues.includes(parent)) {
    return newValues;
  } else {
    return _.difference(newValues, children);
  }
}

// Also check the parent if one child is checked.
// Use the return value of this function for the update function of the record symptoms/conditions.
function checkParent<ENUM_TYPE>(newValues: ENUM_TYPE[], parent: ENUM_TYPE, children: ENUM_TYPE[]) {
  if (children.some((child) => newValues.includes(child))) {
    return _.union(newValues, [parent]);
  } else {
    return newValues;
  }
}

/**
 * Sorts map by key and returns array of key value pairs.
 */
function sortMapByKey<K, V>(map: Map<K, V>): [K, V][] {
  const entriesArray = Array.from(map.entries());
  return _.sortBy(entriesArray, [0]);
}

function HHmmToMinutesSinceMidnight(HHmm: string) {
  return Number(HHmm.substring(0, 2)) * 60 + Number(HHmm.substring(3, 5));
}

// Sorts an array by a timestamp given in the HH:mm format.
// The resulting array will be rotated in a way that the largest time difference is between the last and the first element.
// I.e. the function can handle timespans which include midnight as long as the total duration does not exceed 12h.
function sortByHHmm<
  TIMESTAMP_PROPERTY extends string | number,
  ELEMENT_TYPE extends { [key in TIMESTAMP_PROPERTY]: string },
>(values: ELEMENT_TYPE[], timestampProperty: TIMESTAMP_PROPERTY): ELEMENT_TYPE[] {
  if (values.length < 2) {
    return values;
  }

  let withMinutesSinceMidnight: [number, ELEMENT_TYPE][] = values.map((oneValue) => [
    HHmmToMinutesSinceMidnight(oneValue[timestampProperty]),
    oneValue,
  ]);
  withMinutesSinceMidnight = _.sortBy(withMinutesSinceMidnight, 0);

  let maxDifferenceIndex = withMinutesSinceMidnight.length - 1;
  let maxDifference =
    24 * 60 + withMinutesSinceMidnight[0][0] - withMinutesSinceMidnight[withMinutesSinceMidnight.length - 1][0];
  for (let i = 0; i < withMinutesSinceMidnight.length - 1; i++) {
    if (withMinutesSinceMidnight[i + 1][0] - withMinutesSinceMidnight[i][0] > maxDifference) {
      maxDifference = withMinutesSinceMidnight[i + 1][0] - withMinutesSinceMidnight[i][0];
      maxDifferenceIndex = i;
    }
  }

  withMinutesSinceMidnight = _.concat(
    _.slice(withMinutesSinceMidnight, maxDifferenceIndex + 1),
    _.slice(withMinutesSinceMidnight, 0, maxDifferenceIndex + 1),
  );
  return withMinutesSinceMidnight.map((valueWithMinutes) => valueWithMinutes[1]);
}

function getFormattedTime(timestamp: number): string;
function getFormattedTime(timestamp: number | null): string | null;

/**
 * Formates a timestamp to hh:mm.
 * @param timestamp Timestamp in milliseconds.
 */
function getFormattedTime(timestamp: number | null): string | null {
  if (timestamp === null) return null;
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

export {
  nonNull,
  nonEmptyArray,
  parseBoolean,
  arrayBufferToBase64,
  mutateArray,
  deepFreeze,
  cloneAndMerge,
  convertToRomanNumber,
  isEqualSoftNullIgnoreId,
  assertUnreachable,
  updateSet,
  uncheckChildren,
  checkParent,
  sortMapByKey,
  HHmmToMinutesSinceMidnight,
  sortByHHmm,
  getFormattedTime,
};
