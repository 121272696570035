import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import {
  getPreregistrationTupel,
  getPreregistrationTupelsFor,
  OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY,
  OperationalDataRecord,
  PreregistrationKeys,
} from '../../../../models/genericElements/operationalData/operationalData';
import { InputState } from '../../../../backendModels/general.model';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { Draft } from 'immer';
import { isGenericRecordDeletable } from '../../../../models/generic';
import RadioList from '../../../customs/radioList/RadioList';

interface DestinationProps {
  nodeType: NodeType;
}

const radioListItems = getPreregistrationTupelsFor([
  PreregistrationKeys.strokeunit,
  PreregistrationKeys.cardiaccatheter,
  PreregistrationKeys.traumacenter,
]);

export default function Destination({ nodeType }: DestinationProps) {
  const { findRecordOrDefault, adaptRecord, missionData } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY,
  ) as OperationalDataRecord;

  const destinationName = missionData?.transportDestination?.name ?? null;
  const transportLocationType =
    record.inputState === InputState.ENTERED ? record.values?.transportLocationType?.option ?? null : null;
  const transportPreregistration =
    record.inputState === InputState.ENTERED ? record.values?.transportLocationPreregistration?.options ?? [] : [];

  function changePreregistration(value: PreregistrationKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length !== 0) {
          draft.values = {
            ...draft.values,
            transportLocationPreregistration: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.transportLocationPreregistration;
          delete draft.values?.transportLocationType;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY,
    );
  }

  function changeTransportLocationType(value: React.ChangeEvent<HTMLInputElement>) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        if (value !== null) {
          draft.values = {
            ...draft.values,
            transportLocationType: {
              fieldType: 'singleSelect',
              option: value.target.value as PreregistrationKeys,
            },
            transportLocationPreregistration: {
              fieldType: 'multiSelect',
              options: [PreregistrationKeys.preregistration],
            },
          };
        }
      },
      OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.Destination}>
      <DebouncedTextField
        fullWidth
        className='no-margin-top'
        label='Name der Einrichtung'
        value={destinationName ?? ''}
      />
      <FormControl className='symptoms-list add-margin-top' fullWidth>
        <CheckboxList
          items={[getPreregistrationTupel(PreregistrationKeys.preregistration)]}
          selectedValues={transportPreregistration}
          onValuesChange={changePreregistration}
        />
        <div className='indented'>
          <RadioList items={radioListItems} value={transportLocationType} onChange={changeTransportLocationType} />
        </div>
      </FormControl>
    </DiviFieldCard>
  );
}
