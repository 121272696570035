import { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

interface DiviNavigationSymbolProps {
  tooltip: string;
  children: ReactElement;
}

export function DiviNavigationSymbol({ tooltip, children }: DiviNavigationSymbolProps) {
  return (
    <Tooltip
      title={tooltip}
      disableInteractive
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <span className='divi-navigation-symbol-overlay'>{children}</span>
    </Tooltip>
  );
}
