import { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { debounce, TextField, TextFieldProps } from '@mui/material';
import { SnackbarAlert } from '../snackbarAlert/SnackbarAlert';

const DEBOUNCE_TIME = 500;

export type DebouncedTextFieldProps = {
  value?: string;
  onDebounceChange?: (value: string) => void;
  // onChange is prohibited, since we can not guarantee that it is called when invalid
  onChange?: undefined;
  // Optionally, use this debounce time instead of the default (500ms). Should be constant.
  debounceTime?: number;
} & TextFieldProps;

/**
 * Textfield with debounce functionality.
 */
export default function DebouncedTextField(props: DebouncedTextFieldProps) {
  const { onDebounceChange, value, debounceTime, onFocus, onBlur, InputLabelProps, ...textFieldProps } = props;
  const [lastValue, setLastValue] = useState<string | undefined>(value);
  const [focused, setFocused] = useState<boolean>(false);
  const [changedValue, setChangedValue] = useState<boolean>(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setChangedValue(true);
    setLastValue(event.target.value);
    if (event.target.validity.valid) {
      onDebounce(event.target.value);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    setChangedValue(false);
    onFocus?.(event);
  };

  // We need to set the value in onBlur to prevent resetting to the old value. See COR-253
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (changedValue) {
      if (event.target.validity.valid) {
        setLastValue(event.target.value);
        // We need to call onDebounceChange here directly and not onDebounce, this could lead to the value resetting after onBlur otherwise
        onDebounceChange?.(event.target.value);
      } else {
        setErrorMessage(event.target.validationMessage + ' Die Eingabe wurde gelöscht.');
        setShowError(true);
        setLastValue('');
        onDebounceChange?.('');
      }
    }
    onBlur?.(event);
  };

  const onDebounce = useMemo(
    () =>
      debounce((value: string) => {
        onDebounceChange?.(value);
      }, debounceTime ?? DEBOUNCE_TIME),
    [onDebounceChange, debounceTime],
  );

  useEffect(() => {
    if (!focused) {
      setLastValue(value);
    }
  }, [value, focused]);

  return (
    <>
      <TextField
        InputLabelProps={InputLabelProps}
        {...textFieldProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={lastValue}
        onChange={handleChange}
        autoComplete='off'
      />
      <SnackbarAlert open={showError} onClose={() => setShowError(false)} message={errorMessage} severity='error' />
    </>
  );
}
