import { useContext, useEffect, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { InputAdornment, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './InjuriesCause.scss?inline';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import {
  getInjuriesAdditionTuplesFor,
  INJURIES_ADDITION_RECORD_ELEMENT_KEY,
  InjuriesAdditionKeys,
  InjuriesAdditionRecord,
} from '../../../../models/genericElements/injuries/injuriesAdditional';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';
import _ from 'lodash';
import { useFreshCallback } from '../../../../utils/hooks';
import WarningIcon from '@mui/icons-material/Warning';

const burnCheckboxItems = getInjuriesAdditionTuplesFor([InjuriesAdditionKeys.verbrennung]);

const typeItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.veraetzung,
  InjuriesAdditionKeys.verschuettung,
  InjuriesAdditionKeys.einklemmung,
  InjuriesAdditionKeys.inhalationstrauma,
  InjuriesAdditionKeys.electrounfall,
  InjuriesAdditionKeys.beinahmeertrinken,
  InjuriesAdditionKeys.tauchunfall,
  InjuriesAdditionKeys.haemorrhagischerschock,
]);

const burnItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.verbrennungengradeins,
  InjuriesAdditionKeys.verbrennungengradzweia,
  InjuriesAdditionKeys.verbrennungengradzweib,
  InjuriesAdditionKeys.verbrennungengraddrei,
  InjuriesAdditionKeys.verbrennungengradfour,
]);

interface InjuryCauseProps {
  nodeType: NodeType;
}

export default function InjuriesCause({ nodeType }: InjuryCauseProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    INJURIES_ADDITION_RECORD_ELEMENT_KEY,
  ) as InjuriesAdditionRecord;

  const [firstBurnDegreeType, setFirstBurnDegreeType] = useState(
    record.inputState == InputState.ENTERED
      ? record.values.verbrennungenGradEins
        ? InjuriesAdditionKeys.verbrennungengradeins
        : record.values.verbrennungenGradZweiA
          ? InjuriesAdditionKeys.verbrennungengradzweia
          : record.values.verbrennungenGradZweiB
            ? InjuriesAdditionKeys.verbrennungengradzweib
            : record.values.verbrennungenGradDrei
              ? InjuriesAdditionKeys.verbrennungengraddrei
              : record.values.verbrennungenGradVier
                ? InjuriesAdditionKeys.verbrennungengradfour
                : null
      : null,
  );

  const [firstBurnDegreePercentage, setFirstBurnDegreePercentage] = useState(
    record.inputState == InputState.ENTERED && firstBurnDegreeType != null
      ? (record.values.verbrennungenGradEins?.number ??
          record.values.verbrennungenGradZweiA?.number ??
          record.values.verbrennungenGradZweiB?.number ??
          record.values.verbrennungenGradDrei?.number ??
          record.values.verbrennungenGradVier?.number ??
          null)
      : null,
  );

  const [firstBurnHasFocus, setFirstBurnHasFocus] = useState(false);
  const displayFirstBurnWarning =
    !firstBurnHasFocus && (firstBurnDegreeType != null) !== (firstBurnDegreePercentage != null);

  const [secondBurnDegreeType, setSecondBurnDegreeType] = useState(
    record.inputState == InputState.ENTERED && firstBurnDegreeType != null
      ? record.values.verbrennungenGradZweiA && firstBurnDegreeType != InjuriesAdditionKeys.verbrennungengradzweia
        ? InjuriesAdditionKeys.verbrennungengradzweia
        : record.values.verbrennungenGradZweiB && firstBurnDegreeType != InjuriesAdditionKeys.verbrennungengradzweib
          ? InjuriesAdditionKeys.verbrennungengradzweib
          : record.values.verbrennungenGradDrei && firstBurnDegreeType != InjuriesAdditionKeys.verbrennungengraddrei
            ? InjuriesAdditionKeys.verbrennungengraddrei
            : record.values.verbrennungenGradVier && firstBurnDegreeType != InjuriesAdditionKeys.verbrennungengradfour
              ? InjuriesAdditionKeys.verbrennungengradfour
              : null
      : null,
  );

  const [secondBurnDegreePercentage, setSecondBurnDegreePercentage] = useState(
    (secondBurnDegreeType != null && record.inputState == InputState.ENTERED
      ? secondBurnDegreeType == InjuriesAdditionKeys.verbrennungengradzweia
        ? record.values.verbrennungenGradZweiA?.number
        : secondBurnDegreeType == InjuriesAdditionKeys.verbrennungengradzweib
          ? record.values.verbrennungenGradZweiB?.number
          : secondBurnDegreeType == InjuriesAdditionKeys.verbrennungengraddrei
            ? record.values.verbrennungenGradDrei?.number
            : secondBurnDegreeType == InjuriesAdditionKeys.verbrennungengradfour
              ? record.values.verbrennungenGradVier?.number
              : null
      : null) ?? null,
  );

  const [secondBurnHasFocus, setSecondBurnHasFocus] = useState(false);
  const displaySecondBurnWarning =
    !secondBurnHasFocus && (secondBurnDegreeType != null) !== (secondBurnDegreePercentage != null);

  const selectedCheckboxValues =
    record.inputState === InputState.ENTERED ? (record.values.verletzungAndere?.options ?? []) : [];

  const changeTypeCheckbox = (values: InjuriesAdditionKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (!values.includes(InjuriesAdditionKeys.verbrennung)) {
          burnItems.map((degrees) => delete draft.values?.[degrees[0]]);
          setFirstBurnDegreeType(null);
          setFirstBurnDegreePercentage(null);
          setSecondBurnDegreeType(null);
          setSecondBurnDegreePercentage(null);
        }
        if (values.length == 0) {
          delete draft.values?.verletzungAndere;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            verletzungAndere: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  const changeFirstDegreeType = (event: SelectChangeEvent<unknown>) => {
    setFirstBurnDegreeType(event.target.value as InjuriesAdditionKeys);
    sendBurnDegreeRecord(
      event.target.value as InjuriesAdditionKeys,
      firstBurnDegreePercentage,
      secondBurnDegreeType,
      secondBurnDegreePercentage,
    );
  };

  const changeFirstDegreePercentage = (value: string) => {
    setFirstBurnDegreePercentage(value !== '' ? Number(value) : null);
    sendBurnDegreeRecord(
      firstBurnDegreeType,
      value !== '' ? Number(value) : null,
      secondBurnDegreeType,
      secondBurnDegreePercentage,
    );
  };

  const changeSecondDegreeType = (event: SelectChangeEvent<unknown>) => {
    setSecondBurnDegreeType(event.target.value as InjuriesAdditionKeys);
    sendBurnDegreeRecord(
      firstBurnDegreeType,
      firstBurnDegreePercentage,
      event.target.value as InjuriesAdditionKeys,
      secondBurnDegreePercentage,
    );
  };

  const changeSecondDegreePercentage = (value: string) => {
    setSecondBurnDegreePercentage(value !== '' ? Number(value) : null);
    sendBurnDegreeRecord(
      firstBurnDegreeType,
      firstBurnDegreePercentage,
      secondBurnDegreeType,
      value !== '' ? Number(value) : null,
    );
  };

  const sendBurnDegreeRecord = (
    firstType: InjuriesAdditionKeys | null | undefined,
    firstPerc: number | null | undefined,
    secondType: InjuriesAdditionKeys | null | undefined,
    secondPerc: number | null | undefined,
  ) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          verletzungAndere: {
            fieldType: 'multiSelect',
            options: _.union(
              draft.values?.verletzungAndere?.options,
              firstType != null || firstPerc != null || secondType != null || secondPerc != null
                ? [InjuriesAdditionKeys.verbrennung]
                : [],
            ),
          },
        };
        if (draft.values.verletzungAndere?.options.length === 0) {
          delete draft.values.verletzungAndere;
        }
        burnItems.map((degrees) => delete draft.values?.[degrees[0]]);
        if (firstType != null && firstPerc != null) {
          draft.values[firstType] = {
            fieldType: 'numeric',
            number: firstPerc,
          };
        }
        if (secondType != null && secondPerc != null) {
          draft.values[secondType] = {
            fieldType: 'numeric',
            number: secondPerc,
          };
        }
        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  useEffect(() => {
    if (
      !(
        record.inputState === InputState.ENTERED &&
        record.values.verletzungAndere?.options.includes(InjuriesAdditionKeys.verbrennung)
      )
    ) {
      setFirstBurnDegreeType(null);
      setFirstBurnDegreePercentage(null);
      setSecondBurnDegreeType(null);
      setSecondBurnDegreePercentage(null);
    }
  }, [record]);

  const changeOther = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value !== '') {
          draft.values = {
            ...draft.values,
            verletzungenSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.verletzungenSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard
      cardType={CardId.InjuryCause}
      nodeType={nodeType}
      recordType='generic'
      elementKey={INJURIES_ADDITION_RECORD_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, INJURIES_ADDITION_RECORD_ELEMENT_KEY)
      }
    >
      <div
        className='symptoms-list'
        onMouseLeave={() => {
          setFirstBurnHasFocus(false);
          setSecondBurnHasFocus(false);
        }}
      >
        <CheckboxList
          items={burnCheckboxItems}
          selectedValues={selectedCheckboxValues}
          onValuesChange={changeTypeCheckbox}
        />
        <div className='burn-grades'>
          <div className='burn-scald-input'>
            <Select
              className='grade'
              fullWidth
              size='small'
              onFocus={() => setFirstBurnHasFocus(true)}
              onBlur={() => setFirstBurnHasFocus(false)}
              onChange={changeFirstDegreeType}
              value={firstBurnDegreeType ?? ''}
              error={displayFirstBurnWarning && firstBurnDegreeType == null}
            >
              {burnItems.map(
                ([value, label]) =>
                  value != secondBurnDegreeType && (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ),
              )}
            </Select>
            <Typography>Grades</Typography>
            <DebouncedTextField
              className='percentage'
              type='number'
              inputProps={{ step: 1, min: 1, max: 100 }}
              size='small'
              placeholder='––'
              onFocus={() => setFirstBurnHasFocus(true)}
              onBlur={() => setFirstBurnHasFocus(false)}
              value={firstBurnDegreePercentage?.toString() ?? ''}
              onDebounceChange={changeFirstDegreePercentage}
              error={displayFirstBurnWarning && firstBurnDegreePercentage == null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>%</Typography>
                  </InputAdornment>
                ),
              }}
            />
            {displayFirstBurnWarning && (
              <Tooltip
                arrow
                title='Verbrennungen werden nur gespeichert, wenn sowohl Grad als auch Prozentsatz ausgefüllt sind.'
              >
                <WarningIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <div className='burn-scald-input'>
            <Select
              className='grade'
              fullWidth
              size='small'
              onFocus={() => setSecondBurnHasFocus(true)}
              onBlur={() => setSecondBurnHasFocus(false)}
              onChange={changeSecondDegreeType}
              value={secondBurnDegreeType ?? ''}
              error={displaySecondBurnWarning && secondBurnDegreeType == null}
            >
              {burnItems.map(
                ([value, label]) =>
                  value != firstBurnDegreeType && (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ),
              )}
            </Select>
            <Typography>Grades</Typography>
            <DebouncedTextField
              className='percentage'
              type='number'
              inputProps={{ step: 1, min: 1, max: 100 }}
              size='small'
              placeholder='––'
              onFocus={() => setSecondBurnHasFocus(true)}
              onBlur={() => setSecondBurnHasFocus(false)}
              value={secondBurnDegreePercentage?.toString() ?? ''}
              onDebounceChange={changeSecondDegreePercentage}
              error={displaySecondBurnWarning && secondBurnDegreePercentage == null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>%</Typography>
                  </InputAdornment>
                ),
              }}
            />
            {displaySecondBurnWarning && (
              <Tooltip
                arrow
                title='Verbrennungen werden nur gespeichert, wenn sowohl Grad als auch Prozentsatz ausgefüllt sind.'
              >
                <WarningIcon color='warning' />
              </Tooltip>
            )}
          </div>
        </div>
        <CheckboxList items={typeItems} selectedValues={selectedCheckboxValues} onValuesChange={changeTypeCheckbox} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige'
        onDebounceChange={changeOther}
        value={record.inputState === InputState.ENTERED ? (record.values.verletzungenSonstige?.text ?? '') : ''}
        inputProps={{ maxLength: 12 }}
      />
    </DiviFieldCard>
  );
}
