import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import {
  getMissionLocationNameTuplesFor,
  MissionLocationKeys,
} from '../../../../models/genericElements/operationalData/missionLocation';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';

const locationItems = getMissionLocationNameTuplesFor([
  MissionLocationKeys.wohnung,
  MissionLocationKeys.altenheim,
  MissionLocationKeys.arbeitsplatz,
  MissionLocationKeys.sportstaette,
  MissionLocationKeys.arztpraxis,
  MissionLocationKeys.krankenhaus,
  MissionLocationKeys.geburtshaus,
  MissionLocationKeys.oeffentlicherraum,
  MissionLocationKeys.strasse,
  MissionLocationKeys.schule,
  MissionLocationKeys.bildungseinrichtung,
  MissionLocationKeys.massenveranstaltung,
  MissionLocationKeys.sonstige,
]);

const numbersAndDotPattern = /^[0-9]*\.?[0-9]+$/;

export default function MissionLocation() {
  const { missionData, missionDataUpdate } = useContext(ReportsAPIContext);

  const street = missionData?.missionLocation?.address?.street ?? null;
  const city = missionData?.missionLocation?.address?.city ?? null;
  const postalCode = missionData?.missionLocation?.address?.postalCode ?? '';
  const longitude = missionData?.missionLocation?.position?.longitude ?? null;
  const latitude = missionData?.missionLocation?.position?.latitude ?? null;

  const locationType = missionData?.missionLocation?.type ?? null;

  function changeStreetOrLongitude(value: string) {
    if (numbersAndDotPattern.test(value)) {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          position: { ...missionData?.missionLocation?.position, longitude: Number(value) },
          address: { ...missionData?.missionLocation?.address, street: undefined },
        },
      });
    } else {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          position: { ...missionData?.missionLocation?.position, longitude: undefined },
          address: { ...missionData?.missionLocation?.address, street: value },
        },
      });
    }
  }
  function changeCityOrLatitude(value: string) {
    if (numbersAndDotPattern.test(value)) {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          position: { ...missionData?.missionLocation?.position, latitude: Number(value) },
          address: { ...missionData?.missionLocation?.address, city: undefined },
        },
      });
    } else {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          position: { ...missionData?.missionLocation?.position, latitude: undefined },
          address: { ...missionData?.missionLocation?.address, city: value },
        },
      });
    }
  }

  function changePostalCode(value: string) {
    if (value !== '') {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          address: { ...missionData?.missionLocation?.address, postalCode: value },
        },
      });
    } else {
      missionDataUpdate?.({
        ...missionData,
        missionLocation: {
          ...missionData?.missionLocation,
          address: { ...missionData?.missionLocation?.address, postalCode: undefined },
        },
      });
    }
  }

  const changeLocationType = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    missionDataUpdate?.({
      ...missionData,
      missionLocation: { ...missionData?.missionLocation, type: event.target.value },
    });
  });

  return (
    <DiviFieldCard cardType={CardId.MissionLocation}>
      <DebouncedTextField
        label='Straße oder GPS-Breitengrad'
        className='no-margin-top'
        fullWidth
        value={street ?? longitude?.toString() ?? ''}
        onDebounceChange={changeStreetOrLongitude}
      />
      <DebouncedTextField
        label='Ort oder GPS-Längengrad'
        className='add-margin-top'
        fullWidth
        value={city ?? latitude?.toString() ?? ''}
        onDebounceChange={changeCityOrLatitude}
      />
      <DebouncedTextField
        label='PLZ'
        className='add-margin-top'
        fullWidth
        value={postalCode}
        onDebounceChange={changePostalCode}
      />
      <RadioList
        className='symptoms-list add-margin-top'
        items={locationItems}
        value={locationType}
        onChange={changeLocationType}
      />
    </DiviFieldCard>
  );
}
