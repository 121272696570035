import React, { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';

interface HeaderTemplateProps {
  title: ReactNode;
  unit: ReactNode;
}

export default function HeaderCellTemplate({ title, unit }: HeaderTemplateProps) {
  return (
    <TableCell>
      {title}
      <span>{unit}</span>
    </TableCell>
  );
}
