import { useContext, useMemo } from 'react';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../models/genericElements/patientData';
import {
  SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
  SymptomOnsetPropertyKeys,
  SymptomOnsetRecord,
} from '../../../models/genericElements/operationalData/symptomOnset';
import Button from '@mui/material/Button';
import _ from 'lodash';

export function SavePatient() {
  const { findRecordOrDefault, patient, patientUpdate } = useContext(ReportsAPIContext);
  const patientDataRecord = findRecordOrDefault(
    'generic',
    NodeType.METADATA,
    PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as PatientDataRecord;
  const symptomOnsetRecord = findRecordOrDefault(
    'generic',
    NodeType.METADATA,
    SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
  ) as SymptomOnsetRecord;

  const modifiedPatientData = useMemo(() => {
    const modifiedPatientData = {
      ...patient,
    };

    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.patientId)) {
      modifiedPatientData.patientId = patientDataRecord.values?.patientId?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.age)) {
      modifiedPatientData.age = patientDataRecord.values?.age?.number;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.birthday)) {
      modifiedPatientData.birthday = patientDataRecord.values?.birthday?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.name)) {
      modifiedPatientData.name = patientDataRecord.values?.name?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.surname)) {
      modifiedPatientData.surname = patientDataRecord.values?.surname?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.gender)) {
      modifiedPatientData.gender = patientDataRecord.values?.gender?.option;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.insuranceName)) {
      modifiedPatientData.insuranceName = patientDataRecord.values?.insuranceName?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.insuranceNumber)) {
      modifiedPatientData.insuranceNumber = patientDataRecord.values?.insuranceNumber?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.policyNumber)) {
      modifiedPatientData.policyNumber = patientDataRecord.values?.policyNumber?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.street)) {
      modifiedPatientData.street = patientDataRecord.values?.street?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.postalCode)) {
      modifiedPatientData.postalCode = patientDataRecord.values?.postalCode?.text;
    }
    if (patientDataRecord.values?.touched?.options?.includes(PatientPropertyKeys.city)) {
      modifiedPatientData.city = patientDataRecord.values?.city?.text;
    }

    if (symptomOnsetRecord.values?.touched?.options?.includes(SymptomOnsetPropertyKeys.symptomOnsetTimestamp)) {
      modifiedPatientData.symptomOnset = symptomOnsetRecord.values?.symptomOnsetTimestamp?.text;
    }

    return modifiedPatientData;
  }, [patient, patientDataRecord, symptomOnsetRecord]);

  return (
    <Button
      variant='outlined'
      disabled={patientUpdate == null || _.isEqual(patient, modifiedPatientData)}
      onClick={() => patientUpdate?.(modifiedPatientData)}
    >
      Speichern
    </Button>
  );
}
