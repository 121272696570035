import { useCSS } from '../../../provider/CSSProvider';
import style from './PatientRecordSideBarEntry.scss';
import React from 'react';
import { Divider, ListItem, Stack, Typography } from '@mui/material';

interface PatientRecordSideBarEntryProps {
  title: string;
  time: string;
  showDivider: boolean;
  onClick: React.MouseEventHandler<HTMLLIElement>;
}

function PatientRecordSideBarEntry({ title, time, showDivider, onClick }: PatientRecordSideBarEntryProps) {
  useCSS(style);

  return (
    <>
      <ListItem className='PatientRecordSideBarEntry' onClick={onClick}>
        <Stack direction='column'>
          <Typography fontWeight='fontWeightBold'>
            <span className='list-item-title'>{title}</span>
          </Typography>
          <div className='time'>{time}</div>
        </Stack>
      </ListItem>
      {showDivider && <Divider variant='middle' component='li' />}
    </>
  );
}

export default PatientRecordSideBarEntry;
