import React, { useContext } from 'react';
import globalStyle from '../../../../global.scss?inline';
import style from './Pupils.scss?inline';
import { Checkbox, FormControl, FormLabel, Typography } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { InputState } from '../../../../backendModels/general.model';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import {
  getPupilReactionToLightTuplesWithoutLabelFor,
  getPupilSizeTuplesWithoutLabelFor,
  isPupilsRecordDeletable,
  PupilReactionToLightKeys,
  PupilShapeKeys,
  PupilSizeKeys,
  pupilTransitionInputState,
} from '../../../../models/pupils';
import { NodeType } from '../../../../backendModels/report.model';
import { cloneAndMerge } from '../../../../utils/util';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';
import RadioList from '../../../customs/radioList/RadioList';

interface PupilsProps {
  nodeType: NodeType;
}

const pupilSizeItems = getPupilSizeTuplesWithoutLabelFor([
  PupilSizeKeys.narrowed,
  PupilSizeKeys.normal,
  PupilSizeKeys.dilated,
]);

const pupilReactionToLightItems = getPupilReactionToLightTuplesWithoutLabelFor([
  PupilReactionToLightKeys.normal,
  PupilReactionToLightKeys.slow,
  PupilReactionToLightKeys.noReaction,
]);

function Pupils({ nodeType }: PupilsProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.pupils.type, nodeType);

  const changeRightEyeSize = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), { size: { right: value } });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };
  const changeLeftEyeSize = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), { size: { left: value } });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };

  const changeLeftEyeDistorted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        shape: { left: checked ? PupilShapeKeys.distorted : null },
      });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };

  const changeRightEyeDistorted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        shape: { right: checked ? PupilShapeKeys.distorted : null },
      });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };

  const changeRightEyeReactionToLight = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        reactionToLight: { right: value },
      });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };

  const changeLeftEyeReactionToLight = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft, deleteRecord) => {
      const newRecord = cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        reactionToLight: { left: value },
      });
      if (isPupilsRecordDeletable(newRecord)) {
        deleteRecord();
      } else {
        return newRecord;
      }
    });
  };

  const handleNoPathologicalFindings = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      pupilTransitionInputState(draft, InputState.NORMAL),
    );
  });

  return (
    <DiviFieldCard
      cardType={CardId.Pupils}
      onNormalStateButtonClicked={handleNoPathologicalFindings}
      recordType={defaultRecords.pupils.type}
      nodeType={nodeType}
    >
      <div id='pupils-area' className='row'>
        <div id='pupils-size-area'>
          <Typography align='left' className='title'>
            Pupillenweite
          </Typography>
          <div className='row'>
            <FormControl>
              <FormLabel>rechts</FormLabel>
              <RadioList
                name='right-group-pupil'
                className='center-radios'
                items={pupilSizeItems}
                value={record.inputState === InputState.ENTERED ? (record.size?.right ?? null) : null}
                onChange={changeRightEyeSize}
              />
              <Checkbox
                checked={record.inputState === InputState.ENTERED && record.shape?.right === PupilShapeKeys.distorted}
                onChange={changeRightEyeDistorted}
              />
            </FormControl>
            <div className='label-list'>
              <div>eng</div>
              <div>mittel</div>
              <div>weit</div>
              <div>entrundet</div>
            </div>
            <FormControl>
              <FormLabel>links</FormLabel>
              <RadioList
                name='left-group-pupil'
                className='center-radios'
                items={pupilSizeItems}
                value={record.inputState === InputState.ENTERED ? (record.size?.left ?? null) : null}
                onChange={changeLeftEyeSize}
              />
              <Checkbox
                checked={record.inputState === InputState.ENTERED && record.shape?.left === PupilShapeKeys.distorted}
                onChange={changeLeftEyeDistorted}
              />
            </FormControl>
          </div>
        </div>

        <div id='pupils-light-reflex-area'>
          <Typography align='left' className='title'>
            Lichtreaktion
          </Typography>
          <div className='row'>
            <FormControl>
              <FormLabel>rechts</FormLabel>
              <RadioList
                name='right-group-light-reaction'
                className='center-radios'
                items={pupilReactionToLightItems}
                value={record.inputState === InputState.ENTERED ? (record.reactionToLight?.right ?? null) : null}
                onChange={changeRightEyeReactionToLight}
              />
            </FormControl>
            <div className='label-list'>
              <div>prompt</div>
              <div>träge</div>
              <div>keine</div>
            </div>
            <FormControl>
              <FormLabel>links</FormLabel>
              <RadioList
                name='left-group-light-reaction'
                className='center-radios'
                items={pupilReactionToLightItems}
                value={record.inputState === InputState.ENTERED ? (record.reactionToLight?.left ?? null) : null}
                onChange={changeLeftEyeReactionToLight}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default Pupils;
