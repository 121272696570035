import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CheckCircleOutline } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from '../Trends/AddVitalParamDialog.scss';
import WarningIcon from '@mui/icons-material/Warning';
import { TrendInfoMap } from '../../../../models/trends';
import { getFormattedTime } from '../../../../utils/util';

export interface AddVitalParamDialogResponse {
  timestamp: Dayjs;
  puls: number | null;
  nibpSys?: number | null;
  nibpDia?: number | null;
  spo2?: number | null;
  etco2?: number | null;
}

interface AddVitalParamDialogProps {
  isDialogOpen: boolean;
  onOpenChanged: (open: boolean) => void;
  onConfirm: (vitalParam: AddVitalParamDialogResponse) => void;
  manualData: TrendInfoMap;
}

export function AddVitalParamDialog({ isDialogOpen, onOpenChanged, onConfirm, manualData }: AddVitalParamDialogProps) {
  useCSS(style);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [pulsValue, setPulsValue] = useState<number | null>(null);
  const [nibpSysValue, setNibpSysValue] = useState<number | null>(null);
  const [nibpDiaValue, setNibpDiaValue] = useState<number | null>(null);
  const [spo2Value, setSpo2Value] = useState<number | null>(null);
  const [etco2Value, setEtco2Value] = useState<number | null>(null);

  const onPulsValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPulsValue(event.target.value !== '' ? Number(event.target.value) : null);
  };
  const onNibpSysValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNibpSysValue(event.target.value !== '' ? Number(event.target.value) : null);
  };
  const onNibpDiaValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNibpDiaValue(event.target.value !== '' ? Number(event.target.value) : null);
  };
  const onSpo2ValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSpo2Value(event.target.value !== '' ? Number(event.target.value) : null);
  };
  const onEtco2ValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEtco2Value(event.target.value !== '' ? Number(event.target.value) : null);
  };

  const [vitalParamTimestamp, setVitalParamTimestamp] = useState<Dayjs | null>(null);
  const onChangeVitalParamTime = (newTime: Dayjs | null) => {
    setVitalParamTimestamp(newTime);
  };

  function handleClose() {
    onOpenChanged(false);
    setTimeout(() => {
      setPulsValue(null);
      setNibpSysValue(null);
      setNibpDiaValue(null);
      setSpo2Value(null);
      setEtco2Value(null);
      setVitalParamTimestamp(null);
      setIsConfirmed(false);
    }, 200);
  }

  function handleAdd() {
    if (!isAddable()) {
      return;
    }

    onConfirm({
      timestamp: vitalParamTimestamp!,
      puls: pulsValue,
      nibpSys: nibpSysValue,
      nibpDia: nibpDiaValue,
      spo2: spo2Value,
      etco2: etco2Value,
    });

    setIsConfirmed(true);
    setTimeout(handleClose, 1000);
  }

  function isAddable(): boolean {
    return (
      vitalParamTimestamp !== null &&
      vitalParamTimestamp.isValid() &&
      !manualData.has(getFormattedTime(vitalParamTimestamp.valueOf())) &&
      isNibpValid() &&
      (pulsValue != null || etco2Value != null || spo2Value != null || nibpSysValue != null || nibpDiaValue != null)
    );
  }

  function isNibpValid() {
    return (nibpSysValue == null) == (nibpDiaValue == null);
  }

  return (
    <Dialog open={isDialogOpen} className='add-trends-dialog'>
      <DialogTitle>Vitalparameter hinzufügen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-trends-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                fullWidth
                name='vitalParamTimestamp'
                label='Zeitpunkt'
                format='HH:mm'
                value={vitalParamTimestamp}
                onChange={onChangeVitalParamTime}
              />
            </LocalizationProvider>
            <DebouncedTextField
              fullWidth
              type='number'
              label='Puls [1/min]'
              variant='outlined'
              value={pulsValue?.toString() ?? ''}
              onChange={onPulsValueChanged}
              inputProps={{ step: 1, min: 1 }}
            />
            <div className='row'>
              <DebouncedTextField
                fullWidth
                type='number'
                label='NIBP [mmHg]'
                variant='outlined'
                value={nibpSysValue?.toString() ?? ''}
                onChange={onNibpSysValueChanged}
                inputProps={{ step: 1, min: 1 }}
                error={!!nibpDiaValue && nibpSysValue == null}
              />
              <Typography className='center-slash'>/</Typography>
              <DebouncedTextField
                fullWidth
                type='number'
                label='NIBP [mmHg]'
                variant='outlined'
                value={nibpDiaValue?.toString() ?? ''}
                onChange={onNibpDiaValueChanged}
                inputProps={{ step: 1, min: 1 }}
                error={!!nibpSysValue && nibpDiaValue == null}
              />
            </div>
            <DebouncedTextField
              fullWidth
              type='number'
              label={
                <>
                  SpO<sub>2</sub> [%]
                </>
              }
              variant='outlined'
              value={spo2Value?.toString() ?? ''}
              onChange={onSpo2ValueChanged}
              inputProps={{ step: 1, min: 1 }}
            />
            <DebouncedTextField
              fullWidth
              type='number'
              label='etCO2 [mmHg]'
              variant='outlined'
              value={etco2Value?.toString() ?? ''}
              onChange={onEtco2ValueChanged}
              inputProps={{ step: 1, min: 1 }}
            />
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          {vitalParamTimestamp == null || !vitalParamTimestamp.isValid() ? (
            <Tooltip arrow title='Bitte geben Sie einen Zeitpunkt an.'>
              <WarningIcon color='warning' />
            </Tooltip>
          ) : manualData.has(getFormattedTime(vitalParamTimestamp.valueOf())) ? (
            <Tooltip
              arrow
              title='Für den gewünschten Zeitpunkt ist bereits ein Eintrag vorhanden. Bitte passen Sie den Zeitpunkt an oder löschen Sie den bereits vorhanden Eintrag.'
            >
              <WarningIcon color='warning' />
            </Tooltip>
          ) : (
            !isNibpValid() && (
              <Tooltip
                arrow
                title='NIBP ist nicht vollständig befüllt. Bitte befüllen sie beide Werte, sofern sie eine Eintragung wünschen.'
              >
                <WarningIcon color='warning' />
              </Tooltip>
            )
          )}
          <Button className='grey-button' variant='contained' onClick={handleClose}>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} autoFocus disabled={!isAddable()} variant='contained'>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
