import Dialog from '@mui/material/Dialog/Dialog';
import React, { useEffect, useState } from 'react';
import globalStyle from '../../../global.scss?inline';
import style from './TimeDialog.scss?inline';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCSS } from '../../../provider/CSSProvider';
import { TimePicker } from '../timePicker/TimePicker';

export default function TimeDialog(props: {
  timestamp: { timestamp: dayjs.Dayjs; setTimestamp: (t: dayjs.Dayjs) => void };
  dialogVisible: { open: boolean; setOpen: (b: boolean) => void };
}) {
  useCSS(style);
  useCSS(globalStyle);

  const [localDialogDate, setLocalDialogDate] = useState<dayjs.Dayjs>(props.timestamp.timestamp);
  const [localDialogTime, setLocalDialogTime] = useState(props.timestamp.timestamp.format('HH:mm'));

  useEffect(() => {
    setLocalDialogDate(props.timestamp.timestamp);
    setLocalDialogTime(props.timestamp.timestamp.format('HH:mm'));
  }, [props.timestamp.timestamp]);

  function handleClose() {
    props.dialogVisible.setOpen(false);
  }

  function increaseDay() {
    setLocalDialogDate(localDialogDate.add(1, 'day'));
  }

  function decreaseDay() {
    setLocalDialogDate(localDialogDate.subtract(1, 'day'));
  }

  function submit() {
    const [hour, minute] = localDialogTime.split(':');
    props.timestamp.setTimestamp(localDialogDate.set('hour', Number(hour)).set('minute', Number(minute)));
    handleClose();
  }

  return (
    <Dialog
      open={props.dialogVisible.open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: submit,
      }}
      closeAfterTransition={false}
      className='time-dialog'
    >
      <DialogTitle>Zeitpunkt des Events</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display='flex' justifyContent='center'>
              <TimePicker
                className='small-time'
                required={true}
                value={localDialogTime}
                onChange={setLocalDialogTime}
              />
            </Box>
            <Typography textAlign='center'>
              <Button variant='text' onClick={decreaseDay}>
                <Box component='span' fontWeight='bold'>
                  &lt;
                </Box>
              </Button>
              {localDialogDate.format('DD/MM/YYYY')}
              <Button variant='text' onClick={increaseDay}>
                <Box component='span' fontWeight='bold'>
                  &gt;
                </Box>
              </Button>
            </Typography>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' onClick={handleClose} variant='outlined'>
          Abbrechen
        </Button>
        <Button variant='contained' onClick={submit} disabled={localDialogTime === ''}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
