import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import { getSituationsAndSymptomsTuplesFor, SituationsAndSymptomsKeys } from '../../../../models/situationsAndSymptoms';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import _ from 'lodash';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';

interface AnamneseProps {
  nodeType: NodeType;
}

const treatmentSituationKeys = [
  SituationsAndSymptomsKeys.zuvorUnabhaengig,
  SituationsAndSymptomsKeys.zuvorPflegeZuhause,
  SituationsAndSymptomsKeys.zuvorPflegeInInstitution,
];
const treatmentSituationItems = getSituationsAndSymptomsTuplesFor(treatmentSituationKeys);

export default function TreatmentSituation({ nodeType }: AnamneseProps) {
  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType);

  const symptoms = (record.situationAndSymptoms ?? []) as SituationsAndSymptomsKeys[];

  const changeSymptoms = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.situationAndSymptoms.type, nodeType, (draft, deleteRecord) => {
      const symptomsWithoutRadio = _.difference(symptoms, treatmentSituationKeys);
      if (value == null && symptomsWithoutRadio.length === 0) {
        deleteRecord();
      } else {
        return {
          ...draft,
          situationAndSymptoms: [...symptomsWithoutRadio, ...(value !== null ? [value] : [])],
        };
      }
    });
  };

  return (
    <DiviFieldCard cardType={CardId.TreatmentSituation}>
      <div className='symptoms-list'>
        <RadioList
          items={treatmentSituationItems}
          name='treatment-situation-group'
          value={_.intersection(symptoms, treatmentSituationKeys)[0] ?? null}
          onChange={changeSymptoms}
        />
      </div>
    </DiviFieldCard>
  );
}
