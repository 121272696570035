import { useContext } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { CardId } from '../../../models/diviCard';
import { useCSS } from '../../../provider/CSSProvider';
import style from './Acronym.scss?inline';
import { CARD_TYPE_MAP, CARDID_MAP, COMPONENT_MAP } from './AcronymComponentMap';
import { useLocation } from 'react-router-dom';
import { AcronymEntry, SingleContentItem } from '../../../models/acronym';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';

interface DynamicPageProps {
  content: AcronymEntry[];
}

interface RenderComponentByNameProps {
  componentName: string;
  key: string;
  nodeType?: string;
}

function DynamicPage({ content }: DynamicPageProps) {
  return (
    <div>
      {content.map((block, blockIndex) => (
        <div key={blockIndex}>
          <h2 className='acronym-section'>{block.letter}</h2>
          {block.content.map((item, itemIndex) => {
            const key = `${blockIndex}-${itemIndex}`;
            if (Array.isArray(item)) {
              return <RenderAsRow items={item} rowIndex={blockIndex} key={key} />;
            } else {
              return <RenderComponentByName componentName={item.card} key={key} nodeType={item.type} />;
            }
          })}
        </div>
      ))}
    </div>
  );
}

function RenderComponentByName({ componentName, nodeType }: RenderComponentByNameProps) {
  const ComponentToRender = COMPONENT_MAP[componentName];
  if (!ComponentToRender) {
    if (componentName == null) {
      console.error('Keine Card für Komponente angegeben. Bitte JSON überprüfen.');
      return <p className='error'>Keine Card für Komponente angegeben. Bitte JSON überprüfen.</p>;
    } else {
      console.error('Komponente "' + componentName + '" nicht gefunden. Bitte JSON überprüfen.');
      return (
        <p className='error'>
          Komponente &quot;<span className='show-spaces'>{componentName}</span>&quot; nicht gefunden. Bitte JSON
          überprüfen.
        </p>
      );
    }
  }
  const nodeTypeOrDefault = nodeType ?? CARD_TYPE_MAP[componentName];
  if (nodeTypeOrDefault == null) {
    return <p className='error'>Komponente {componentName} benötigt einen Type. Bitte JSON überprüfen.</p>;
  }
  // Act as a wrapper to assign the key prop and pass the nodeType
  return <ComponentToRender key={'key-' + componentName} nodeType={nodeTypeOrDefault} />;
}

function RenderAsRow({ items, rowIndex }: { items: SingleContentItem[]; rowIndex: number }) {
  return (
    <div className='field-row'>
      {items.map((item, index) => (
        <RenderComponentByName
          componentName={item.card}
          key={`${rowIndex}-${index}`}
          nodeType={item.type ?? CARD_TYPE_MAP[item.card]}
        />
      ))}
    </div>
  );
}

function generateFlatIdArray(acronymData: AcronymEntry[]): CardId[] {
  const flatIdArray: CardId[] = [];
  acronymData.forEach((block) => {
    block.content.forEach((item) => {
      if (Array.isArray(item)) {
        item.forEach((nestedItem) => {
          if (CARDID_MAP[nestedItem.card]) {
            flatIdArray.push(CARDID_MAP[nestedItem.card]);
          }
        });
      } else {
        if (CARDID_MAP[item.card]) {
          flatIdArray.push(CARDID_MAP[item.card]);
        }
      }
    });
  });
  return flatIdArray;
}

export function Acronym() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const { acronyms } = useContext(ReportsAPIContext);

  const location = useLocation();
  const chosenAcronym = decodeURIComponent(location.pathname.split('/').at(-1) ?? '');

  if (chosenAcronym === undefined || chosenAcronym === '' || acronyms?.[chosenAcronym] == null) {
    console.error(
      'Dies ist kein valides Akronym. Bitte über die Navigation oder über die Suche ein valides Akronym auswählen.',
    );
    return (
      <>Dies ist kein valides Akronym. Bitte über die Navigation oder über die Suche ein valides Akronym auswählen.</>
    );
  }

  const navigationIds = generateFlatIdArray(acronyms[chosenAcronym]);

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.Acronym) + ' - ' + chosenAcronym}
      ListOfCardIds={navigationIds}
      noClearAllButton={true}
    />
  );
  const pageFooter = (
    <PageFooter
      pageType={PageType.Acronym}
      diviId={DiviNavigationIds.Pat}
      noDoneButton
      backInHistoryOnBack
      noNextButton
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
    >
      <div className='acronym-wireframe'>
        <DynamicPage content={acronyms[chosenAcronym]} />
      </div>
    </DiviPage>
  );
}
