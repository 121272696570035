import React, { useContext } from 'react';
import { FormControl } from '@mui/material';
import { BreathingKeys, getBreathingTupelsFor } from '../../../../models/breathing';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { InputState } from '../../../../backendModels/general.model';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import _ from 'lodash';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Breathing.scss?inline';
import globalStyle from '../../../../global.scss?inline';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';

const breathingRadioItems: [BreathingKeys, string][] = getBreathingTupelsFor([
  BreathingKeys.nichtUntersucht,
  BreathingKeys.dyspnoe,
  BreathingKeys.apnoe,
  BreathingKeys.beatmung,
  BreathingKeys.schnappatmung,
  BreathingKeys.sonstigepathologischeatemmuster,
]);

const breathingCheckboxItems: [BreathingKeys, string][] = getBreathingTupelsFor([
  BreathingKeys.stridor,
  BreathingKeys.zyanose,
  BreathingKeys.spastik,
  BreathingKeys.hyperventilation,
  BreathingKeys.atemwegsverlegung,
  BreathingKeys.rasselgeraeusche,
]);

const breathingRadioKeys: BreathingKeys[] = breathingRadioItems.map((o) => o[0]);

interface BreathingProps {
  nodeType: NodeType;
}

export default function Breathing({ nodeType }: BreathingProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.breathing.type, nodeType);

  const inputState = record.inputState;
  const isInputStateEntered = inputState === InputState.ENTERED;
  const values = (isInputStateEntered ? (record.symptoms ?? []) : []) as BreathingKeys[];
  const radioValue = isInputStateEntered ? (_.intersection(values, breathingRadioKeys)[0] ?? null) : null;

  const handleChangeBreathingCheckbox = (values: BreathingKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.breathing.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      }
    });
  };

  const handleChangeBreathingRadio = (value: string | null) => {
    reportAPI.adaptRecord(defaultRecords.breathing.type, nodeType, (draft, deleteRecord) => {
      const symptomsWithoutRadio = _.difference(values, breathingRadioKeys);
      if (value == null && symptomsWithoutRadio.length === 0) {
        deleteRecord();
      } else {
        return {
          ...draft,
          inputState: InputState.ENTERED,
          symptoms: [...symptomsWithoutRadio, ...(value !== null ? [value] : [])],
        };
      }
    });
  };

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.breathing.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  return (
    <div className='breathing-card'>
      <DiviFieldCard
        cardType={CardId.Breathing}
        recordType={defaultRecords.breathing.type}
        nodeType={nodeType}
        onNormalStateButtonClicked={setToNormal}
        normalStateButtonLabel={'unauffällig'}
      >
        <div id='breathing-area'>
          <div className='row symptoms-list'>
            <FormControl>
              <RadioList
                items={breathingRadioItems}
                name='breathing-classification-group'
                value={radioValue}
                onChange={handleChangeBreathingRadio}
              />
            </FormControl>
            <CheckboxList
              items={breathingCheckboxItems}
              selectedValues={values}
              onValuesChange={handleChangeBreathingCheckbox}
            />
          </div>
        </div>
      </DiviFieldCard>
    </div>
  );
}
