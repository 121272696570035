import { useContext, useEffect } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import PatientData from './PatientData/PatientData';
import style from './PatientMasterData.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import Age from './Age/Age';
import Gender from './Gender/Gender';
import Bmi from './Bmi/Bmi';
import Mission from './Mission/Mission';
import { Typography } from '@mui/material';
import {
  BirthDateBackendFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getAgeFromBirthday,
} from '../../../models/genericElements/extendedPatientData';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { Draft } from 'immer';
import { InputState } from '../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../models/generic';
import { CONTROL_CENTER_RECORD_ELEMENT_KEY } from '../../../models/genericElements/controlCenter';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../models/genericElements/patientData';
import {
  handleUntouchedNumericFieldUpdates,
  handleUntouchedSingleSelectFieldUpdates,
  handleUntouchedTextFieldUpdates,
} from '../../../utils/recordWithTouched';
import { SavePatient } from './SavePatient';
import dayjs from 'dayjs';
import _ from 'lodash';

export interface HandleChangedPatientProperty {
  <T extends PatientPropertyKeys>(
    property: T,
    value?: (ExtendedPatientDataRecord & { inputState: InputState.ENTERED })['values'][T],
  ): void;
}

export function PatientMasterData() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.METADATA;
  const elementKeysOfPage = [
    PATIENT_DATA_RECORD_ELEMENT_KEY,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    CONTROL_CENTER_RECORD_ELEMENT_KEY,
  ];

  const { findNodes, adaptRecord, patient, hidePatientSaveButton, findRecordOrDefault } = useContext(ReportsAPIContext);

  const patientRecord = findRecordOrDefault(
    'generic',
    nodeTypeOfPage,
    PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as PatientDataRecord;

  useEffect(() => {
    if (patient != null && findNodes(nodeTypeOfPage).length > 0) {
      const birthday = patient.birthday != null ? dayjs(patient.birthday, BirthDateBackendFormat) : null;
      const [age, newbornAge] = getAgeFromBirthday(birthday);

      // If the birthday is touched we do not want to perform any age calculation here, since it is already calculated from the overwritten age
      const isBirthdayTouched = patientRecord.values?.touched?.options?.includes(PatientPropertyKeys.birthday) ?? false;

      // We may delete the age if the birthday is untouched and either
      // - there was a synchronized birthday before (and maybe there no longer is one)
      // - there is a synchronized birthday after (and we have to delete the days, since it is further in the past)
      const mayDeleteAge = (patientRecord.values?.birthday?.text != null || birthday?.isValid()) && !isBirthdayTouched;

      adaptRecord(
        'generic',
        nodeTypeOfPage,
        (draft: Draft<PatientDataRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.patientId, patient.patientId);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.name, patient.name);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.surname, patient.surname);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.street, patient.street);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.postalCode, patient.postalCode);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.city, patient.city);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.insuranceNumber, patient.insuranceNumber);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.insuranceName, patient.insuranceName);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.policyNumber, patient.policyNumber);
          handleUntouchedSingleSelectFieldUpdates(draft, PatientPropertyKeys.gender, patient.gender);

          if (mayDeleteAge) {
            delete draft.values?.age;
          }

          if (!isBirthdayTouched) {
            handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.birthday, patient.birthday);
            handleUntouchedNumericFieldUpdates(draft, PatientPropertyKeys.age, patient.age);

            if (age != null) {
              draft.values = {
                ...draft.values,
                age: {
                  fieldType: 'numeric',
                  number: age,
                },
                touched: {
                  fieldType: 'multiSelect',
                  options: _.union(draft.values?.touched?.options ?? [], [PatientPropertyKeys.age]),
                },
              };
            }
          }

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        PATIENT_DATA_RECORD_ELEMENT_KEY,
      );

      if (mayDeleteAge && !isBirthdayTouched) {
        adaptRecord(
          'generic',
          nodeTypeOfPage,
          (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
            draft.inputState = InputState.ENTERED;

            if (newbornAge != null) {
              draft.values = {
                ...draft.values,
                newbornAge: {
                  fieldType: 'singleSelect',
                  option: newbornAge,
                },
              };
            } else if (mayDeleteAge) {
              delete draft.values?.newbornAge;
            }

            if (isGenericRecordDeletable(draft)) {
              deleteRecord();
            }
          },
          EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
        );
      }
    }
  }, [patient, adaptRecord, findNodes, nodeTypeOfPage, patientRecord]);

  const pageHeader = <PageHeader heading={getPageTitle(PageType.PatientMasterData)} noClearAllButton />;

  const pageFooter = (
    <PageFooter
      noBackButton
      pageType={PageType.PatientMasterData}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    >
      <div className='legend-with-save row'>
        <div className='row'>
          <div className='indicator'></div>
          <Typography variant='subtitle1'>Automatisch übertragene Daten</Typography>
        </div>
        {!hidePatientSaveButton && <SavePatient />}
      </div>
      <div className='patient-master-data-wireframe'>
        <div className='patient-data'>
          <PatientData nodeType={nodeTypeOfPage} />
        </div>
        <Gender nodeType={nodeTypeOfPage} />
        <Bmi nodeType={nodeTypeOfPage} />
        <Age nodeType={nodeTypeOfPage} />
        <div className='mission'>
          <Mission nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
