import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DIVI_PROGRESS_STATE_STORAGE_KEY,
  DiviNavigationIds,
  DiviProgress,
  DiviProgressStatesType,
} from '../../models/diviProgress';
import { LocalStorageContext } from '../../provider/LocalStorageProvider';
import StatusDoneIcon from '../../assets/images/icons/status-done.svg?react';
import StatusToDoIcon from '../../assets/images/icons/status-todo.svg?react';
import StatusTouchedIcon from '../../assets/images/icons/status-touched.svg?react';
import { DiviNavigationSymbol } from './DiviNavigationSymbol';

interface DiviNavigationElementProps {
  id: DiviNavigationIds;
  route?: string;
}

export function DiviNavigationElement(props: DiviNavigationElementProps) {
  const { id, route } = props;
  const state = useContext(LocalStorageContext).getData<DiviProgressStatesType>(DIVI_PROGRESS_STATE_STORAGE_KEY);

  return (
    <NavLink id={id} className={state?.[id] ?? DiviProgress.todo} to={route ? route : '/'}>
      {state?.[id] === DiviProgress.done ? (
        <DiviNavigationSymbol tooltip='Vollständig'>
          <StatusDoneIcon />
        </DiviNavigationSymbol>
      ) : state?.[id] === DiviProgress.touched ? (
        <DiviNavigationSymbol tooltip='In Bearbeitung'>
          <StatusTouchedIcon />
        </DiviNavigationSymbol>
      ) : (
        <DiviNavigationSymbol tooltip='Zu bearbeiten'>
          <StatusToDoIcon />
        </DiviNavigationSymbol>
      )}
    </NavLink>
  );
}
