import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { NodeType } from '../../../../backendModels/report.model';
import {
  BirthDateBackendFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getNewBornAgeTuplesFor,
  NewBornAgeKeys,
} from '../../../../models/genericElements/extendedPatientData';
import RadioList from '../../../customs/radioList/RadioList';
import { InputState } from '../../../../backendModels/general.model';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import _ from 'lodash';
import { isGenericRecordDeletable } from '../../../../models/generic';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';
import dayjs from 'dayjs';

const newBornAgeRadioItems: [NewBornAgeKeys, string][] = getNewBornAgeTuplesFor([
  NewBornAgeKeys.eineWocheAlt,
  NewBornAgeKeys.zwischenZweiUndVierWochenAlt,
]);

interface AgeProps {
  nodeType: NodeType;
}

export default function Age({ nodeType }: AgeProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;

  const birthDate =
    record.values?.birthday?.text != null ? dayjs(record.values?.birthday?.text, BirthDateBackendFormat) : null;

  const extendedRecord = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const handleNewBornAgeChange = useFreshCallback((value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            newbornAge: {
              fieldType: 'singleSelect',
              option: value as NewBornAgeKeys,
            },
          };
        } else {
          delete draft.values?.newbornAge;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
    if (value != null) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<PatientDataRecord>) => {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            age: {
              fieldType: 'numeric',
              number: 0,
            },
            touched: {
              options: _.union([PatientPropertyKeys.age], draft.values?.touched?.options ?? []),
              fieldType: 'multiSelect',
            },
          };
        },
        PATIENT_DATA_RECORD_ELEMENT_KEY,
      );
    }
  });

  const handleAgeChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (value !== (draft.values?.age?.number?.toString() ?? '')) {
          draft.values = {
            ...draft.values,
            touched: {
              options: _.union([PatientPropertyKeys.age], draft.values?.touched?.options ?? []),
              fieldType: 'multiSelect',
            },
          };
        }

        if (value != '') {
          draft.values = {
            ...draft.values,
            age: {
              fieldType: 'numeric',
              number: Number(value),
            },
          };
          const newValue = Number(value);
          if (newValue > 0) {
            deleteNewBornAge();
          }
        } else {
          delete draft.values?.age;
        }

        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      },
      PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  function deleteNewBornAge() {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        delete draft.values?.newbornAge;
        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  }

  const age = record?.values?.age?.number ?? null;
  const newbornAge = extendedRecord.values?.newbornAge?.option ?? null;

  function getTouchedClassNameAge(): string {
    const ageTouched = record.values?.touched?.options.includes(PatientPropertyKeys.age);
    return birthDate != null && !ageTouched ? 'prefilled' : '';
  }

  function getTouchedClassNameNewBornAge(): string {
    const ageTouched = getTouchedClassNameAge();
    if (ageTouched !== '' && newbornAge !== null) {
      return ageTouched;
    }
    return '';
  }

  function isDisabled(): boolean {
    return birthDate != null;
  }

  return (
    <DiviFieldCard cardType={CardId.Age}>
      <div className='column'>
        <DebouncedTextField
          className={getTouchedClassNameAge()}
          label='Jahre'
          type='number'
          slotProps={{ input: { inputProps: { step: 1, min: 0 }, disabled: isDisabled() } }}
          value={age?.toString() ?? ''}
          onDebounceChange={handleAgeChange}
        />
        <RadioList
          className={`symptoms-list add-margin-top ${getTouchedClassNameNewBornAge()}`}
          items={newBornAgeRadioItems}
          radioProps={{ disabled: isDisabled() }}
          value={newbornAge}
          onChange={handleNewBornAgeChange}
        />
      </div>
    </DiviFieldCard>
  );
}
