import React, { useContext, useState } from 'react';
import globalStyle from '../../../../global.scss';
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, Typography } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { AccessRouteInfoRow, convertToAccessRouteLabels } from '../../../../models/accessRoute';
import { NodeType } from '../../../../backendModels/report.model';
import AddAccessRouteDialog from './AddAccessRouteDialog';
import { AccessRouteValue } from '../../../../backendModels/access-route.model';
import { RecordId } from '../../../../backendModels/records.model';
import RadioList from '../../../customs/radioList/RadioList';
import {
  ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY,
  AccessRouteDifficultyKeys,
  AccessRouteDifficultyRecord,
  getAccessRouteDifficultyTuplesFor,
} from '../../../../models/genericElements/treatments/accessRouteDifficult';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import style from './AccessRoute.scss';

const accessRouteDifficultItems: [AccessRouteDifficultyKeys, string][] = getAccessRouteDifficultyTuplesFor([
  AccessRouteDifficultyKeys.zugangerschwertmehrzweiversuche,
  AccessRouteDifficultyKeys.zugangerschwertunmoeglich,
]);

interface AccessRouteProps {
  nodeType: NodeType;
}

function AccessRoute({ nodeType }: AccessRouteProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const records = reportAPI.findMultipleRecords(defaultRecords.accessRoute.type, nodeType);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY,
  ) as AccessRouteDifficultyRecord;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const data: AccessRouteInfoRow[] = convertToAccessRouteLabels(records);

  function deleteAccessRoute(recordIds: RecordId[]) {
    for (const singleId of recordIds) {
      reportAPI.deleteRecord(singleId, nodeType);
    }
  }

  function addAccessRoute(accessRoute: AccessRouteValue) {
    reportAPI.createRecord({ type: 'accessRoute', ...accessRoute }, nodeType);
  }

  function openAddAccessRouteDialog() {
    setDialogOpen(true);
  }

  function changeDifficulty(event: React.ChangeEvent<HTMLInputElement>) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<AccessRouteDifficultyRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (
          event.target.value === AccessRouteDifficultyKeys.zugangerschwert &&
          record.values?.zugangErschwertBeschreibung?.option
        ) {
          deleteRecord();
        } else {
          draft.values = {
            ...draft.values,
            zugangErschwertBeschreibung: {
              fieldType: 'singleSelect',
              option: event.target.value as AccessRouteDifficultyKeys,
            },
          };
        }
      },
      ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.AccessRoute} recordType={defaultRecords.accessRoute.type} nodeType={nodeType}>
      <div id='access-route-area'>
        <AddAccessRouteDialog open={dialogOpen} openChanged={setDialogOpen} addAccessRoute={addAccessRoute} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Art</TableCell>
              <TableCell align='left'>Ort / Größe</TableCell>
              <TableCell align='left'>Anzahl</TableCell>
              <TableCell align='right'>
                <Button variant='outlined' endIcon={<AddCircleIcon />} onClick={openAddAccessRouteDialog}>
                  Zugang hinzufügen
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align='center'>
                  Keine Zugänge
                </TableCell>
              </TableRow>
            ) : (
              data.map((accessRoute) => (
                <TableRow
                  key={
                    typeof accessRoute.recordId[0] === 'object'
                      ? `optimistic-${accessRoute.recordId[0].optimistic}`
                      : accessRoute.recordId[0] ?? -1
                  }
                >
                  <TableCell align='left'>
                    {accessRoute.typeLabel}
                    {accessRoute.alreadyPlaced && (
                      <Typography variant='subtitle1' color='textSecondary'>
                        bereits vorhanden
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align='left'>
                    {accessRoute.locationSizeLabels.map((label, index) => (
                      <div key={index}>
                        {label}
                        <br />
                      </div>
                    ))}
                  </TableCell>
                  <TableCell align='left'>{accessRoute.count}</TableCell>
                  <TableCell align='right'>
                    <IconButton aria-label='delete' onClick={() => deleteAccessRoute(accessRoute.recordId)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <div className='symptoms-list add-margin-top'>
          <FormControl fullWidth>
            <FormControlLabel
              value={AccessRouteDifficultyKeys.zugangerschwert}
              label='Zugang erschwert'
              checked={record.values?.zugangErschwertBeschreibung?.option !== undefined}
              control={<Checkbox onChange={changeDifficulty} />}
            />
            <div className='indented symptoms-list'>
              <RadioList
                items={accessRouteDifficultItems}
                value={
                  record.inputState === InputState.ENTERED
                    ? record.values.zugangErschwertBeschreibung?.option ?? null
                    : null
                }
                onChange={changeDifficulty}
              />
            </div>
          </FormControl>
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default AccessRoute;
