import React, { useContext } from 'react';
import globalStyle from '../../../../global.scss?inline';
import style from './HandoverTo.scss?inline';
import { FormControl } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import {
  getHandoverToTuplesFor,
  HANDOVER_TO_RECORD_ELEMENT_KEY,
  HandoverToKeys,
  HandoverToRecord,
} from '../../../../models/genericElements/handoverTo';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';

const handoverToRadioItems: [HandoverToKeys, string][] = getHandoverToTuplesFor([
  HandoverToKeys.einsatzstelle,
  HandoverToKeys.znaina,
  HandoverToKeys.strokeunit,
  HandoverToKeys.intensivstation,
  HandoverToKeys.opdirekt,
  HandoverToKeys.praxis,
  HandoverToKeys.hausarzt,
  HandoverToKeys.fachambulanz,
  HandoverToKeys.schockraum,
  HandoverToKeys.herzkatheterlabor,
  HandoverToKeys.cpu,
  HandoverToKeys.allgemeinstation,
]);

interface HandoverToDetailsProps {
  nodeType: NodeType;
}

function HandoverTo({ nodeType }: HandoverToDetailsProps) {
  useCSS(style);
  useCSS(globalStyle);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, HANDOVER_TO_RECORD_ELEMENT_KEY) as HandoverToRecord;

  const handleRadioChange = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<HandoverToRecord>, deleteRecord) => {
        if (value) {
          draft.inputState = InputState.ENTERED;
          delete draft.values?.uebergabeOrtSonstige;
          draft.values = {
            ...draft.values,
            uebergabeOrt: {
              fieldType: 'singleSelect',
              option: value as HandoverToKeys,
            },
          };
        } else {
          delete draft.values?.uebergabeOrt;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      HANDOVER_TO_RECORD_ELEMENT_KEY,
    );
  };

  const handleAdditionalInfoChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<HandoverToRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          delete draft.values?.uebergabeOrt;
          draft.values = {
            ...draft.values,
            uebergabeOrtSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.uebergabeOrtSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      HANDOVER_TO_RECORD_ELEMENT_KEY,
    );
  });

  const handleNameInfoChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<HandoverToRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            uebergabeOrtName: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.uebergabeOrtName;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      HANDOVER_TO_RECORD_ELEMENT_KEY,
    );
  });

  const setToUnknown = () => {
    adaptRecord(
      'generic',
      nodeType,
      (draft) => {
        return {
          id: draft.id,
          type: draft.type,
          elementKey: draft.elementKey,
          inputState: InputState.UNKNOWN,
        };
      },
      HANDOVER_TO_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.HandoverTo}
      onUnknownStateButtonClicked={setToUnknown}
      unknownStateButtonLabel={'keine'}
      nodeType={nodeType}
      recordType='generic'
      elementKey={HANDOVER_TO_RECORD_ELEMENT_KEY}
    >
      <div id='handover-to-area'>
        <div className='symptoms-list'>
          <FormControl className={'full-width'}>
            <RadioList
              items={handoverToRadioItems}
              name='handover-to-group'
              value={record.inputState === InputState.ENTERED ? (record.values.uebergabeOrt?.option ?? null) : null}
              onChange={handleRadioChange}
            />
          </FormControl>
        </div>
        <DebouncedTextField
          fullWidth
          label='Sonstige...'
          variant='outlined'
          onDebounceChange={handleAdditionalInfoChange}
          value={record.inputState === InputState.ENTERED ? (record.values.uebergabeOrtSonstige?.text ?? '') : ''}
          inputProps={{ maxLength: 20 }}
        />
        <DebouncedTextField
          fullWidth
          label='Übergabe an (Name)'
          variant='outlined'
          onDebounceChange={handleNameInfoChange}
          value={record.inputState === InputState.ENTERED ? (record.values.uebergabeOrtName?.text ?? '') : ''}
          inputProps={{ maxLength: 20 }}
        />
      </div>
    </DiviFieldCard>
  );
}

export default HandoverTo;
