import React, { useContext, useMemo } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NeurologicalFeatureKeys, getNeurologicalFeatureTuplesFor } from '../../../../models/neurologicalFeatures';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { InputState } from '../../../../backendModels/general.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { NodeType } from '../../../../backendModels/report.model';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';
import {
  DISABILITY_EXTENDED_ELEMENT_KEY,
  DisabilityExtendedRecord,
} from '../../../../models/genericElements/disabilityExtended';
import { Draft } from 'immer';

interface NeurologicalFeaturesProps {
  nodeType: NodeType;
}

export default function NeurologicalFeatures({ nodeType }: NeurologicalFeaturesProps) {
  const neurologicalFeaturesItems: [NeurologicalFeatureKeys, string][] = useMemo(
    () =>
      getNeurologicalFeatureTuplesFor([
        NeurologicalFeatureKeys.sideMark,
        NeurologicalFeatureKeys.noSmiling,
        NeurologicalFeatureKeys.speechDisorder,
        NeurologicalFeatureKeys.sightImpediment,
        NeurologicalFeatureKeys.dementia,
        NeurologicalFeatureKeys.paraplegicSymptoms,
        NeurologicalFeatureKeys.babinskiSign,
        NeurologicalFeatureKeys.meningism,
        NeurologicalFeatureKeys.preExistingNeurologicalDeficits,
      ]),
    [],
  );

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.disability.type, nodeType);

  const disabilityExtendedRecord = reportAPI.findRecordOrDefault(
    'generic',
    nodeType,
    DISABILITY_EXTENDED_ELEMENT_KEY,
  ) as DisabilityExtendedRecord;

  // record and disabilityExtendedRecord should share the inputState normal. Therefore,
  // - both have to be set to normal at the same time
  // - if one loses its normal state the other one has to be deleted

  const symptoms = (record.inputState === InputState.ENTERED ? record.symptoms ?? [] : []) as NeurologicalFeatureKeys[];

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.disability.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
    reportAPI.adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DisabilityExtendedRecord>) => {
        draft.inputState = InputState.NORMAL;
        draft.values = null;
      },
      DISABILITY_EXTENDED_ELEMENT_KEY,
    );
  });

  const setToUnknown = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.disability.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.UNKNOWN,
    }));
    reportAPI.adaptRecord(
      'generic',
      nodeType,
      (draft, deleteRecord) => {
        deleteRecord();
      },
      DISABILITY_EXTENDED_ELEMENT_KEY,
    );
  });

  const handleNeurologicalFeaturesChange = (values: NeurologicalFeatureKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.disability.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      }
    });
    if (disabilityExtendedRecord.inputState === InputState.NORMAL) {
      reportAPI.adaptRecord(
        'generic',
        nodeType,
        (draft, deleteRecord) => {
          if (draft.inputState === InputState.NORMAL) {
            deleteRecord();
          }
        },
        DISABILITY_EXTENDED_ELEMENT_KEY,
      );
    }
  };

  const handleAdditionalInfoChange = useFreshCallback((value: string) => {
    reportAPI.adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DisabilityExtendedRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            disabilitySonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          deleteRecord();
        }
      },
      DISABILITY_EXTENDED_ELEMENT_KEY,
    );
    if (record.inputState === InputState.NORMAL) {
      reportAPI.adaptRecord(defaultRecords.disability.type, nodeType, (draft, deleteRecord) => {
        if (draft.inputState === InputState.NORMAL) {
          deleteRecord();
        }
      });
    }
  });

  return (
    <DiviFieldCard
      cardType={CardId.NeurologicalFeatures}
      onNormalStateButtonClicked={setToNormal}
      onUnknownStateButtonClicked={setToUnknown}
      recordType={defaultRecords.disability.type}
      nodeType={nodeType}
    >
      <CheckboxList
        className='symptoms-list'
        items={neurologicalFeaturesItems}
        onValuesChange={handleNeurologicalFeaturesChange}
        selectedValues={symptoms}
      />
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        variant='outlined'
        onDebounceChange={handleAdditionalInfoChange}
        value={
          disabilityExtendedRecord.inputState === InputState.ENTERED
            ? disabilityExtendedRecord.values.disabilitySonstige?.text ?? ''
            : ''
        }
      />
    </DiviFieldCard>
  );
}
