import ReactDOM from 'react-dom/client';
import React from 'react';
import { ReportsAPIProvider } from './provider/ReportsAPIProvider';
import { MemoryRouter } from 'react-router-dom';
import App from './App/App';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CSSContextProvider, CSSContextType } from './provider/CSSProvider';
import createCache from '@emotion/cache';
import { Node } from './backendModels/report.model';
import { SharedData } from './backendModels/sharedData.model';
import _ from 'lodash';
import { NodeChangeEvent } from './models/nodeChange';
import { Patient } from './backendModels/patient.model';
import { MissionData } from './backendModels/mission-data.model';
import { LocalStorageProvider } from './provider/LocalStorageProvider';
import { StartTimerEvent } from './models/startTimerEvent';
import { Timer } from './backendModels/timer';
import { PatientChangeEvent } from './models/patientChange';
import { AcronymGroup } from './models/acronym';
import { MissionDataChangeEvent } from './models/missionDataChange';
import { ControlCenter } from './backendModels/control-center.model';
import { Trends } from './backendModels/trends.model';

export class DiviReportShadowDom extends HTMLElement {
  innerShadowRoot: ShadowRoot;
  root?: ReactDOM.Root;
  cache?: EmotionCache;
  cssContextValue: CSSContextType;
  cssReferenceCounts = new Map<CSSStyleSheet, number>();
  reactContainer: HTMLDivElement;

  static get observedAttributes() {
    return [
      'nodes',
      'patient',
      'missionData',
      'sharedData',
      'trends',
      'controlCenter',
      'acronyms',
      'timers',
      'diviProtocolId',
    ];
  }

  nodes_?: readonly Node[];

  get nodes() {
    return this.nodes_ || [];
  }

  set nodes(nodes) {
    if (!_.isEqual(this.nodes_, nodes)) {
      this.nodes_ = nodes;
      this.render();
    }
  }

  sharedData_?: SharedData;

  get sharedData() {
    return {
      medications: this.sharedData_?.medications || [],
      applicationMethods: this.sharedData_?.applicationMethods || [],
      accessRoutes: this.sharedData_?.accessRoutes || [],
    };
  }

  set sharedData(sharedData) {
    // We will not dispatch an event for changes to shared data, since they are never caused by this code base.
    this.sharedData_ = sharedData;
    this.render();
  }

  trends_?: Trends;

  get trends() {
    return this.trends_;
  }

  set trends(trends) {
    // We will not dispatch an event for changes to trends, since they are never caused by this code base.
    this.trends_ = trends;
    this.render();
  }

  controlCenter_?: ControlCenter;

  get controlCenter() {
    return this.controlCenter_;
  }

  set controlCenter(controlCenter) {
    // We will not dispatch an event for changes to control center, since they are never caused by this code base.
    this.controlCenter_ = controlCenter;
    this.render();
  }

  acronyms_?: AcronymGroup;

  get acronyms() {
    return this.acronyms_ || {};
  }

  set acronyms(acronyms) {
    // We will not dispatch an event for changes to Acronyms, since they are never caused by us.
    this.acronyms_ = acronyms;
    this.render();
  }

  patient_?: Patient;

  get patient() {
    return this.patient_ || {};
  }

  set patient(patient) {
    if (!_.isEqual(this.patient_, patient)) {
      this.patient_ = patient;
      this.dispatchEvent(new PatientChangeEvent('patientChange', patient ?? {}));
      this.render();
    }
  }

  missionData_?: MissionData;

  get missionData() {
    return (
      this.missionData_ || {
        missionCreated: '-1',
        missionLocation: {},
        transportDestination: {},
      }
    );
  }

  set missionData(missionData) {
    if (!_.isEqual(this.missionData_, missionData)) {
      this.missionData_ = missionData;
      this.dispatchEvent(new MissionDataChangeEvent('missionDataChange', missionData ?? {}));
      this.render();
    }
  }

  timers_?: Timer[];

  get timers() {
    return this.timers_ || [];
  }

  set timers(timers) {
    if (!_.isEqual(this.timers, timers)) {
      this.timers_ = timers;
      this.render();
    }
  }

  diviProtocolId_?: string;

  get diviProtocolId(): string {
    return this.diviProtocolId_ ?? 'undefined';
  }

  set diviProtocolId(diviProtocolId: string | undefined) {
    if (!_.isEqual(this.diviProtocolId_, diviProtocolId)) {
      this.diviProtocolId_ = diviProtocolId;
      this.render();
    }
  }

  constructor() {
    super();

    this.innerShadowRoot = this.attachShadow({ mode: 'open' });

    this.reactContainer = document.createElement('div');
    this.reactContainer.className = 'shadow-root';
    this.innerShadowRoot.appendChild(this.reactContainer);

    this.cssContextValue = {
      adoptedStyleSheets: this.innerShadowRoot.adoptedStyleSheets,
      referenceCounts: this.cssReferenceCounts,
      additionalThemeComponents: {
        MuiPopover: {
          defaultProps: {
            container: this.reactContainer,
          },
        },
        MuiPopper: {
          defaultProps: {
            container: this.reactContainer,
          },
        },
        MuiModal: {
          defaultProps: {
            container: this.reactContainer,
          },
        },
      },
    };
  }

  disconnectedCallback() {
    this.root?.unmount();
    this.root = undefined;
  }

  connectedCallback() {
    this.cache = createCache({
      key: 'divi',
      prepend: true,
      container: this.innerShadowRoot,
    });

    this.root = ReactDOM.createRoot(this.reactContainer);
    this.render();
  }

  render() {
    this.root?.render(
      <React.StrictMode>
        <CacheProvider value={this.cache!}>
          <CSSContextProvider value={this.cssContextValue}>
            <ReportsAPIProvider
              serverNodes={this.nodes}
              onNodesUpdated={(newValue, nodeUpdateEvents) => {
                if (nodeUpdateEvents.length > 0) {
                  this.dispatchEvent(new NodeChangeEvent('nodesChange', nodeUpdateEvents));
                }
              }}
              sharedData={this.sharedData}
              trends={this.trends}
              controlCenter={this.controlCenter}
              patient={this.patient}
              missionData={this.missionData}
              acronyms={this.acronyms}
              onStartTimer={(name: string, timestamp: number, duration: number, identifier?: object) =>
                this.dispatchEvent(new StartTimerEvent('startTimer', timestamp, duration, name, identifier))
              }
              onPatientUpdate={(patient: Patient) => (this.patient = patient)}
              onMissionDataUpdate={(missionData: MissionData) => (this.missionData = missionData)}
              timers={this.timers}
            >
              <LocalStorageProvider diviProtocolId={this.diviProtocolId}>
                <MemoryRouter>
                  <App />
                </MemoryRouter>
              </LocalStorageProvider>
            </ReportsAPIProvider>
          </CSSContextProvider>
        </CacheProvider>
      </React.StrictMode>,
    );
  }
}

customElements.define('divi-report', DiviReportShadowDom);
