import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BaseNodeRecordValueTypeMap,
  NodeRecord,
  NodeRecordTypeMap,
  NodeRecordValueTypeMap,
  RecordId,
} from '../backendModels/records.model';
import { defaultRecords } from '../DefaultRecords';
import { debounce } from '@mui/material';
import { Node, NodeType } from '../backendModels/report.model';
import { isEqualSoftNullIgnoreId, mutateArray, removeLinksPropertyRecursively } from '../utils/util';
import { SharedData } from '../backendModels/sharedData.model';
import { NodeChange, NodeChangeKind } from '../models/nodeChange';
import _ from 'lodash';
import { castDraft, Draft, produce } from 'immer';
import { SnackbarAlert } from '../App/customs/snackbarAlert/SnackbarAlert';
import { useStateRefCombo } from '../utils/hooks';
import { Timer } from '../backendModels/timer';
import { InputState } from '../backendModels/general.model';
import { AcronymGroup } from '../models/acronym';
import { Patient } from '../backendModels/patient.model';
import { MissionData } from '../backendModels/mission-data.model';
import { Trends } from '../backendModels/trends.model';
import { ManualEventType } from '../models/manualEvents/config';

// ------------------------------- Provider -------------------------------

export type StartTimerFunction = (name: string, timestamp: number, duration: number, identifier: object) => void;
export type ManualEventEmitFunction = (effectiveTimestamp: number, name: string) => void;

export type AdaptRecordFunction = {
  <RECORD_TYPE_NAME extends keyof BaseNodeRecordValueTypeMap, RECORD_TYPE extends NodeRecordTypeMap[RECORD_TYPE_NAME]>(
    recordType: RECORD_TYPE_NAME,
    nodeType: NodeType,
    changeFunc: (draft: Draft<RECORD_TYPE>, deleteRecord: () => void) => void,
  ): void;
  <RECORD_TYPE_NAME extends keyof NodeRecordValueTypeMap, RECORD_TYPE extends NodeRecordTypeMap[RECORD_TYPE_NAME]>(
    recordType: RECORD_TYPE_NAME,
    nodeType: NodeType,
    changeFunc: (draft: Draft<RECORD_TYPE>, deleteRecord: () => void) => void,
    filter: (record: NodeRecordValueTypeMap[RECORD_TYPE_NAME]) => boolean,
  ): void;
  <RECORD_TYPE_NAME extends keyof NodeRecordValueTypeMap, RECORD_TYPE extends NodeRecordTypeMap[RECORD_TYPE_NAME]>(
    recordType: RECORD_TYPE_NAME,
    nodeId: number,
    changeFunc: (draft: Draft<RECORD_TYPE>, deleteRecord: () => void) => void,
  ): void;
  <RECORD_TYPE extends NodeRecordTypeMap['generic']>(
    recordType: 'generic',
    nodeType: NodeType,
    changeFunc: (draft: Draft<RECORD_TYPE>, deleteRecord: () => void) => void,
    elementKey: string,
  ): void;
};

export type FindRecordFunction = {
  <RECORD_TYPE_NAME extends keyof BaseNodeRecordValueTypeMap>(
    recordType: RECORD_TYPE_NAME,
    nodeType: NodeType,
  ): NodeRecordTypeMap[RECORD_TYPE_NAME];
  <RECORD_TYPE_NAME extends keyof NodeRecordValueTypeMap>(
    recordType: RECORD_TYPE_NAME,
    nodeType: NodeType,
    filter: (record: NodeRecordValueTypeMap[RECORD_TYPE_NAME]) => boolean,
  ): NodeRecordTypeMap[RECORD_TYPE_NAME];
  (recordType: 'generic', nodeType: NodeType, elementKey: string): NodeRecordTypeMap['generic'];
};

export interface ReportsAPIContextType {
  findRecordOrDefault: FindRecordFunction;
  findMultipleRecords: <T extends keyof NodeRecordValueTypeMap>(
    recordType: T,
    nodeType: NodeType,
    elementKey?: string,
  ) => NodeRecordTypeMap[T][];
  findNodeWithRecord: (nodeType: NodeType, recordType: keyof NodeRecordValueTypeMap) => Node[];
  findNodes: (nodeType: NodeType) => Node[];
  adaptRecord: AdaptRecordFunction;
  createRecord: (record: NodeRecord, nodeType: NodeType) => void;
  createNodeWithRecord: (record: NodeRecord, nodeType: NodeType, timestamp: number) => void;
  deleteRecord: (recordId: RecordId, nodeType: NodeType) => void;
  deleteNode: (nodeId: number) => void;
  setNodeTimestamp: (timestamp: number, nodeType: NodeType) => void;
  getNodeTimestamp: (nodeType: NodeType) => number | null;
  sharedData?: SharedData;
  trends?: Trends;
  patient?: Patient;
  patientUpdate?: (patient: Patient) => void;
  hidePatientSaveButton?: boolean;
  missionData?: MissionData;
  missionDataUpdate?: (missionData: MissionData) => void;
  acronyms?: AcronymGroup;
  timers?: Timer[];
  startTimer?: StartTimerFunction;
  manualEventTypes?: ManualEventType[] | null;
  manualEventEmit: ManualEventEmitFunction;
}

const reportsAPIContextDefaultValue: ReportsAPIContextType = {
  adaptRecord: () => {
    throw new Error('Uninitialized');
  },
  createRecord: () => {
    throw new Error('Uninitialized');
  },
  createNodeWithRecord: () => {
    throw new Error('Uninitialized');
  },
  deleteRecord: () => {
    throw new Error('Uninitialized');
  },
  deleteNode: () => {
    throw new Error('Uninitialized');
  },
  findRecordOrDefault: () => {
    throw new Error('Uninitialized');
  },
  findNodeWithRecord: () => {
    throw new Error('Uninitialized');
  },
  findNodes: () => {
    throw new Error('Uninitialized');
  },
  setNodeTimestamp: () => {
    throw new Error('Uninitialized');
  },
  getNodeTimestamp: () => {
    throw new Error('Uninitialized');
  },
  findMultipleRecords: () => {
    throw new Error('Uninitialized');
  },
  patientUpdate: () => {
    throw new Error('Uninitialized');
  },
  manualEventEmit: () => {
    throw new Error('Uninitialized');
  },
};

export interface ReportsAPIProviderProps {
  children: ReactNode;
  serverNodes?: readonly Node[];
  onNodesUpdated: (nodes: readonly Node[], updates: NodeChange[]) => void;
  sharedData?: SharedData;
  trends?: Trends;
  patient?: Patient;
  onPatientUpdate?: (patient: Patient) => void;
  hidePatientSaveButton?: boolean;
  missionData?: MissionData;
  acronyms?: AcronymGroup;
  timers: Timer[];
  onStartTimer?: StartTimerFunction;
  manualEventTypes?: ManualEventType[] | null;
  onManualEventEmit: ManualEventEmitFunction;
}

export const ReportsAPIContext = createContext<ReportsAPIContextType>(reportsAPIContextDefaultValue);

// The latency after which a pending update will be deleted even if it was not confirmed.
// Doing so might result in a date update getting lost.
// It is necessary to eventually delete them, since updates from other clients will not show while the update is pending.
const MAX_SERVER_RESPONSE_LATENCY_SECONDS = 2;

let optimisticRecordNewId = 1;

function ensureHasElementKey(record: NodeRecord) {
  if (record.elementKey == null) {
    return { ...record, elementKey: record.type } as NodeRecord;
  }
  return record;
}

function getNodeAndIndex(nodes: readonly Node[], nodeType: NodeType | number): [Node, number] | [undefined, -1] {
  const index =
    typeof nodeType === 'number'
      ? nodes.findIndex((node) => node.id === nodeType)
      : nodes.findIndex(
          (node) =>
            node.type === nodeType &&
            !node.records.some(
              (record) =>
                record.type === 'medication' ||
                record.type === 'oxygen' ||
                (nodeType === NodeType.GENERIC && record.type === 'vitalParameters'),
            ),
        );
  return [nodes[index], index];
}

function findNodeByType(nodes: readonly Node[], nodeType: NodeType): Node | undefined {
  return getNodeAndIndex(nodes, nodeType)[0];
}

export function ReportsAPIProvider({
  children,
  serverNodes: serverNodesWithLinks,
  onNodesUpdated,
  sharedData: sharedDataWithLinks,
  trends: trendsWithLinks,
  patient: patientWithLinks,
  onPatientUpdate,
  hidePatientSaveButton,
  missionData: missionDataWithLinks,
  acronyms: acronymsWithLinks,
  timers: timersWithLinks,
  manualEventTypes: manualEventTypesWithLinks,
  onManualEventEmit,
  onStartTimer,
}: ReportsAPIProviderProps) {
  const [nodes, nodesRef] = useStateRefCombo<readonly Node[]>(() => []);

  const nodeUpdateEvents = useRef<NodeChange[]>([]);

  const [warningMessage, setWarningMessage] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const showWarningMessage = useCallback((message: string) => {
    setWarningMessage(message);
    setShowWarning(true);
  }, []);

  const pendingOptimisticRecords = useRef<
    {
      nodeId: number;
      pendingRecord: NodeRecord;
      waitingRecord?: NodeRecord;
      timestamp: number;
      wasDeleted: boolean;
      waitingForDelete: boolean;
    }[]
  >([]);

  // Remove _links from all objects we get from the backend (via corpuls frontend).
  // For most functions it should not matter, but they were not developed with _links in mind.
  // None of these objects should every have a _links property regularly.
  const serverNodes = useMemo(() => removeLinksPropertyRecursively(serverNodesWithLinks), [serverNodesWithLinks]);
  const sharedData = useMemo(() => removeLinksPropertyRecursively(sharedDataWithLinks), [sharedDataWithLinks]);
  const trends = useMemo(() => removeLinksPropertyRecursively(trendsWithLinks), [trendsWithLinks]);
  const patient = useMemo(() => removeLinksPropertyRecursively(patientWithLinks), [patientWithLinks]);
  const missionData = useMemo(() => removeLinksPropertyRecursively(missionDataWithLinks), [missionDataWithLinks]);
  const acronyms = useMemo(() => removeLinksPropertyRecursively(acronymsWithLinks), [acronymsWithLinks]);
  const timers = useMemo(() => removeLinksPropertyRecursively(timersWithLinks), [timersWithLinks]);
  const manualEventTypes = useMemo(
    () => removeLinksPropertyRecursively(manualEventTypesWithLinks),
    [manualEventTypesWithLinks],
  );

  useEffect(() => {
    const timerId = window.setInterval(() => {
      _.remove(pendingOptimisticRecords.current, (optimisticRecord) => {
        if (Date.now() - optimisticRecord.timestamp > MAX_SERVER_RESPONSE_LATENCY_SECONDS * 1000) {
          if (!optimisticRecord.wasDeleted) {
            console.warn(
              `Server did not confirm optimistic update in ${MAX_SERVER_RESPONSE_LATENCY_SECONDS}s: ${JSON.stringify(optimisticRecord)}`,
            );
            showWarningMessage(
              `Der Server hat die Änderung nicht innerhalb von ${MAX_SERVER_RESPONSE_LATENCY_SECONDS}s bestätigt. Bitte prüfen Sie manuell, ob die Änderung gespeichert worden ist.`,
            );
          }
          return true;
        }
        return false;
      });
    }, 1000);
    return () => window.clearInterval(timerId);
  }, [showWarningMessage]);

  const notifyDebounce = useMemo(
    () =>
      debounce((newNodes: readonly Node[]) => {
        if (onNodesUpdated != null && serverNodes != null) {
          onNodesUpdated(newNodes, nodeUpdateEvents.current);
          nodeUpdateEvents.current = [];
        }
      }, 1),
    [onNodesUpdated, serverNodes],
  );

  const notifyChange = useCallback(
    (newNodes: readonly Node[]) => {
      notifyDebounce(newNodes);
      return newNodes;
    },
    [notifyDebounce],
  );

  const setNodeTimestamp = useCallback(
    (timestamp: number, nodeType: NodeType) => {
      const [oldNode, oldNodeIndex] = getNodeAndIndex(nodesRef.current, nodeType);
      if (oldNode == null || oldNodeIndex === -1) {
        throw new Error(`Can't find node of type ${nodeType}`);
      }

      const newNodes = nodesRef.current.slice();
      if (timestamp !== oldNode.effectiveTimestamp) {
        newNodes[oldNodeIndex] = { ...oldNode, effectiveTimestamp: timestamp };

        nodeUpdateEvents.current.push({
          kind: NodeChangeKind.updateNode,
          newNode: newNodes[oldNodeIndex],
        });

        nodesRef.current = newNodes;
        notifyChange(newNodes);
      }
    },
    [notifyChange, nodeUpdateEvents, nodesRef],
  );

  const getNodeTimestamp = useCallback(
    (nodeType: NodeType) => {
      return nodes.find((node) => node.type === nodeType)?.effectiveTimestamp ?? null;
    },
    [nodes],
  );

  const findRecordOrDefaultRef = useCallback(
    <T extends keyof NodeRecordValueTypeMap>(
      recordType: T,
      nodeType: NodeType | number,
      filter?: string | ((record: NodeRecordValueTypeMap[T]) => boolean),
    ): NodeRecordTypeMap[T] => {
      let result: NodeRecordTypeMap[T] | undefined;
      const node =
        typeof nodeType === 'number'
          ? nodesRef.current.find((node) => node.id === nodeType)
          : findNodeByType(nodesRef.current, nodeType);
      if (typeof filter === 'function') {
        result = node?.records?.find(
          (record): record is NodeRecordTypeMap[typeof recordType] =>
            record.type === recordType && filter(record as NodeRecordTypeMap[typeof recordType]),
        );
      } else {
        result = node?.records?.find(
          (record): record is NodeRecordTypeMap[typeof recordType] =>
            record.type === recordType && (record.type !== 'generic' || record.elementKey === filter),
        );
      }

      if (result != null) {
        return result;
      }
      if (recordType === 'generic') {
        return {
          type: 'generic',
          inputState: InputState.ENTERED,
          elementKey: filter!,
          values: {},
        } as NodeRecordTypeMap[typeof recordType];
      }
      return defaultRecords[recordType];
    },
    [nodesRef],
  );

  const updateOrCreateRecord = useCallback(
    (record: NodeRecord, nodeType: NodeType | number) => {
      let [oldNode, oldNodeIndex] = getNodeAndIndex(nodesRef.current, nodeType);
      if (oldNode == null || oldNodeIndex === -1) {
        throw Error(`Can't find node of type ${nodeType}`);
      }

      record = ensureHasElementKey(record);

      if (record.id == null) {
        // If we have to create a new record, but we are currently waiting to delete a record of the same type, drop the deletion and reuse the record.
        // This minimizes the risk that the creation overtakes the deletion, which would cause a 409 conflict response from the server.
        const optimisticRecord = pendingOptimisticRecords.current.find(
          (value) =>
            value.nodeId === oldNode!.id &&
            value.pendingRecord.type === record.type &&
            value.pendingRecord.elementKey === record.elementKey &&
            !value.wasDeleted &&
            value.waitingForDelete,
        );
        if (optimisticRecord) {
          optimisticRecord.waitingForDelete = false;
          record = produce(record, (draft) => {
            draft.id = optimisticRecord.pendingRecord.id;
          });
          nodesRef.current = produce(nodesRef.current, (draft) => {
            draft[oldNodeIndex].records.push(castDraft(optimisticRecord.pendingRecord));
          });
          oldNode = nodesRef.current[oldNodeIndex];
        }
      }

      if (record.id != null) {
        const oldRecordIndex = oldNode.records.findIndex((oldRecord) => _.isEqual(oldRecord.id, record.id));

        if (oldRecordIndex === -1) {
          throw new Error('If a record with id is passed, there has to be a matching existing record');
        }

        // Early return if nothing changed
        if (_.isEqual(oldNode.records[oldRecordIndex], record)) {
          return;
        }

        // Update an existing record:
        // - Take the record id from the nodes
        // - Check if the record is already in the pending list
        //   => Update the list entry
        // - If not
        //    => Add it to the pending list and create the updateRecord UpdateEvent
        // - Always, update the currently visible records
        const optimisticIndex = pendingOptimisticRecords.current.findIndex(
          (value) =>
            value.nodeId === oldNode!.id &&
            _.isEqual(value.pendingRecord.id, record.id) &&
            !value.wasDeleted &&
            !value.waitingForDelete,
        );
        if (optimisticIndex >= 0) {
          const currentPendingRecord = pendingOptimisticRecords.current[optimisticIndex];
          if (!_.isEqual(currentPendingRecord.waitingRecord ?? currentPendingRecord.pendingRecord, record)) {
            currentPendingRecord.waitingRecord = record;
          }
        } else {
          if (typeof record.id === 'object') {
            throw new Error('Update of optimistic records should always find a pending record');
          }

          pendingOptimisticRecords.current.push({
            nodeId: oldNode.id,
            pendingRecord: record,
            timestamp: Date.now(),
            wasDeleted: false,
            waitingForDelete: false,
          });
          const newChange: NodeChange = {
            kind: NodeChangeKind.updateRecord,
            nodeId: oldNode.id,
            newRecord: record,
          };
          if (nodeUpdateEvents.current.some((change) => _.isEqual(change, newChange))) {
            console.warn('Duplicate record update event', newChange);
          }
          nodeUpdateEvents.current.push(newChange);
        }

        nodesRef.current = produce(nodesRef.current, (draft) => {
          draft[oldNodeIndex].records[oldRecordIndex] = castDraft(record);
        });
        notifyChange(nodesRef.current);
      } else {
        // Create a new record:
        // - Add the record with an optimistic id to the pending records
        // - Create the createRecord UpdateEvent
        // - Add the record to the currently visible records with the optimistic id
        const optimisticRecord = { ...record, id: { optimistic: optimisticRecordNewId } };
        optimisticRecordNewId += 1;

        pendingOptimisticRecords.current.push({
          nodeId: oldNode.id,
          pendingRecord: optimisticRecord,
          timestamp: Date.now(),
          wasDeleted: false,
          waitingForDelete: false,
        });

        const newChange: NodeChange = {
          kind: NodeChangeKind.createRecord,
          nodeId: oldNode.id,
          newRecord: record,
        };
        if (nodeUpdateEvents.current.some((change) => _.isEqual(change, newChange))) {
          console.warn('Duplicate record creation event', newChange);
        }
        nodeUpdateEvents.current.push(newChange);

        nodesRef.current = produce(nodesRef.current, (draft) => {
          draft[oldNodeIndex].records.push(castDraft(optimisticRecord));
        });
        notifyChange(nodesRef.current);
      }
    },
    [nodeUpdateEvents, notifyChange, nodesRef],
  );

  const createRecord = useCallback(
    (record: NodeRecord, nodeType: NodeType) => {
      const [oldNode, oldNodeIndex] = getNodeAndIndex(nodesRef.current, nodeType);
      record = ensureHasElementKey(record);
      if (oldNode == null || oldNodeIndex === -1) {
        throw Error(`Can't find node of type ${nodeType}, if you want to create it use createNodeWithRecord instead`);
      }
      const newChange: NodeChange = {
        kind: NodeChangeKind.createRecord,
        nodeId: oldNode.id,
        newRecord: record,
      };
      if (nodeUpdateEvents.current.some((change) => _.isEqual(change, newChange))) {
        console.warn('Duplicate record creation event', newChange);
      }
      nodeUpdateEvents.current.push(newChange);

      const newRecord = { ...record, id: { optimistic: optimisticRecordNewId } };
      optimisticRecordNewId += 1;

      pendingOptimisticRecords.current.push({
        nodeId: oldNode.id,
        pendingRecord: newRecord,
        timestamp: Date.now(),
        wasDeleted: false,
        waitingForDelete: false,
      });

      nodesRef.current = mutateArray(nodesRef.current, oldNodeIndex, {
        ...oldNode,
        records: [...oldNode.records, newRecord],
      });

      return notifyChange(nodesRef.current);
    },
    [nodeUpdateEvents, notifyChange, nodesRef],
  );

  const createNodeWithRecord = useCallback(
    (record: NodeRecord, nodeType: NodeType, timestamp: number) => {
      record = ensureHasElementKey(record);
      const newChange: NodeChange = {
        kind: NodeChangeKind.createNode,
        newNode: {
          type: nodeType,
          effectiveTimestamp: timestamp,
          records: [record],
        },
      };
      if (nodeUpdateEvents.current.some((change) => _.isEqual(change, newChange))) {
        console.warn('Duplicate node (with record) creation event', newChange);
      }
      nodeUpdateEvents.current.push(newChange);
      nodesRef.current = [...nodesRef.current, { id: 0, owner: 0, ...newChange.newNode }];

      return notifyChange(nodesRef.current);
    },
    [nodeUpdateEvents, notifyChange, nodesRef],
  );

  const deleteRecord = useCallback(
    (recordId: RecordId, nodeType: NodeType | number) => {
      // Update an existing record:
      // - Check if the record is already in the pending list
      //   => Update the list entry
      // - If not
      //    => Create the deleteRecord UpdateEvent
      // - Always, delete it from the currently visible records
      const [oldNode, oldNodeIndex] = getNodeAndIndex(nodesRef.current, nodeType);
      if (oldNodeIndex === -1 || oldNode == null) {
        throw Error('Valid node does not exist even though it should.');
      }

      const optimisticIndex = pendingOptimisticRecords.current.findIndex(
        (value) => value.nodeId === oldNode.id && _.isEqual(value.pendingRecord.id, recordId),
      );
      if (optimisticIndex >= 0) {
        pendingOptimisticRecords.current[optimisticIndex].waitingForDelete = true;
      } else {
        if (typeof recordId === 'object') {
          throw new Error('Deletion of optimistic records should always find a pending record');
        }

        const oldRecord = oldNode.records.find((record) => _.isEqual(record.id, recordId));

        if (oldRecord != null) {
          pendingOptimisticRecords.current.push({
            nodeId: oldNode.id,
            pendingRecord: oldRecord,
            timestamp: Date.now(),
            wasDeleted: true,
            waitingForDelete: false,
          });
        }

        const newChange: NodeChange = {
          kind: NodeChangeKind.deleteRecord,
          nodeId: oldNode.id,
          recordId: recordId,
        };
        if (!nodeUpdateEvents.current.some((change) => _.isEqual(change, newChange))) {
          // React debugging stuff can cause this function to be called twice
          nodeUpdateEvents.current.push(newChange);
        }
      }

      nodesRef.current = mutateArray(nodesRef.current, oldNodeIndex, {
        ...oldNode,
        records: oldNode.records.filter((r) => r.id !== recordId),
      });

      notifyChange(nodesRef.current);
    },
    [nodeUpdateEvents, notifyChange, nodesRef],
  );

  const deleteNode = useCallback(
    (nodeId: number) => {
      const newChange: NodeChange = {
        kind: NodeChangeKind.deleteNode,
        nodeId: nodeId,
      };
      nodeUpdateEvents.current.push(newChange);

      nodesRef.current = nodesRef.current.filter((node) => node.id !== nodeId);

      notifyChange(nodesRef.current);
    },
    [nodeUpdateEvents, notifyChange, nodesRef],
  );

  const adaptRecord: AdaptRecordFunction = useCallback(
    <RECORD_TYPE_NAME extends keyof NodeRecordValueTypeMap, RECORD_TYPE extends NodeRecordTypeMap[RECORD_TYPE_NAME]>(
      recordType: RECORD_TYPE_NAME,
      nodeType: NodeType | number,
      changeFunc: (draft: Draft<RECORD_TYPE>, deleteRecord: () => void) => void,
      filter?: string | ((record: NodeRecordValueTypeMap[RECORD_TYPE_NAME]) => boolean),
    ) => {
      const oldRecord = findRecordOrDefaultRef(recordType, nodeType, filter);
      let shouldDelete = false;
      const changeFuncWrapped = (draft: Draft<RECORD_TYPE>) => {
        return changeFunc(draft, () => {
          shouldDelete = true;
        });
      };

      const newRecord = produce(oldRecord, changeFuncWrapped);

      if (!shouldDelete) {
        updateOrCreateRecord(newRecord, nodeType);
      } else if (oldRecord.id != null) {
        deleteRecord(oldRecord.id, nodeType);
      }
    },
    [deleteRecord, updateOrCreateRecord, findRecordOrDefaultRef],
  );

  const findRecordOrDefault = useCallback(
    <T extends keyof NodeRecordValueTypeMap>(
      recordType: T,
      nodeType: NodeType,
      filter?: string | ((record: NodeRecordTypeMap[T]) => boolean),
    ): NodeRecordTypeMap[T] => {
      let result: NodeRecordTypeMap[T] | undefined;
      if (typeof filter === 'function') {
        result = findNodeByType(nodes, nodeType)?.records?.find(
          (record): record is NodeRecordTypeMap[typeof recordType] =>
            record.type === recordType && filter(record as NodeRecordTypeMap[typeof recordType]),
        );
      } else {
        result = findNodeByType(nodes, nodeType)?.records?.find(
          (record): record is NodeRecordTypeMap[typeof recordType] =>
            record.type === recordType && (record.type !== 'generic' || record.elementKey === filter),
        );
      }
      if (result != null) {
        return result;
      }
      if (recordType === 'generic') {
        if (recordType === 'generic') {
          return {
            type: 'generic',
            inputState: InputState.ENTERED,
            elementKey: filter!,
            values: {},
          } as NodeRecordTypeMap[typeof recordType];
        }
      }
      return defaultRecords[recordType];
    },
    [nodes],
  );

  const findMultipleRecords = useCallback(
    <T extends keyof NodeRecordValueTypeMap>(recordType: T, nodeType: NodeType, elementKey?: string) => {
      return nodes
        .filter((node) => node.type === nodeType)
        .map((node) => {
          const nodeRecords = node.records ?? [];
          return nodeRecords.filter(
            (record): record is NodeRecordTypeMap[T] =>
              record.type === recordType && (record.type !== 'generic' || record.elementKey === elementKey),
          );
        })
        .flat();
    },
    [nodes],
  );

  const findNodeWithRecord = useCallback(
    (nodeType: NodeType, recordType: keyof NodeRecordValueTypeMap) => {
      return nodes.filter(
        (node) => node.type === nodeType && node.records.some((record) => record.type === recordType),
      );
    },
    [nodes],
  );

  const findNodes = useCallback(
    (nodeType: NodeType) => {
      return nodes.filter(
        (node) =>
          node.type === nodeType &&
          !node.records.some(
            (record) =>
              record.type === 'medication' ||
              record.type === 'oxygen' ||
              (nodeType === NodeType.GENERIC && record.type === 'vitalParameters'),
          ),
      );
    },
    [nodes],
  );

  const reportsAPIContextValue = useMemo((): ReportsAPIContextType => {
    return {
      findRecordOrDefault,
      findMultipleRecords,
      findNodeWithRecord,
      findNodes,
      adaptRecord,
      createRecord,
      createNodeWithRecord,
      deleteRecord,
      deleteNode,
      setNodeTimestamp,
      getNodeTimestamp,
      sharedData,
      trends,
      patient,
      patientUpdate: onPatientUpdate,
      hidePatientSaveButton,
      missionData,
      acronyms,
      timers,
      startTimer: onStartTimer,
      manualEventTypes,
      manualEventEmit: onManualEventEmit,
    };
  }, [
    findRecordOrDefault,
    findMultipleRecords,
    findNodeWithRecord,
    findNodes,
    adaptRecord,
    createRecord,
    createNodeWithRecord,
    deleteRecord,
    deleteNode,
    setNodeTimestamp,
    getNodeTimestamp,
    sharedData,
    trends,
    patient,
    onPatientUpdate,
    hidePatientSaveButton,
    missionData,
    acronyms,
    timers,
    onStartTimer,
    manualEventTypes,
    onManualEventEmit,
  ]);

  useEffect(() => {
    if (serverNodes != null) {
      const newUpdates: { record: NodeRecord; nodeType: NodeType }[] = [];
      const toDelete: { nodeType: NodeType; recordId: RecordId }[] = [];

      const optimisticServerNodes = produce(serverNodes, (draftServerNodes) => {
        // For every pending record:
        // - If the record is part of the server response and matches what we sent and was not deleted
        //   => If there is another update/deletion enqueued, perform it now with the id we got from the server
        //   => Delete the old pending entry
        // - If the record was deleted
        //   => Delete the visible record
        // - If the record is part of the sever response, but does not match what we sent (yet)
        //   => Replace the visible record with the one we want
        // - If the record is not part of the server response
        //   => Add it to the visible records

        for (let i = 0; i < pendingOptimisticRecords.current.length; i += 1) {
          const { nodeId, pendingRecord, waitingRecord, wasDeleted, waitingForDelete } =
            pendingOptimisticRecords.current[i];
          const node = draftServerNodes.find((nodeIterator) => nodeIterator.id === nodeId);

          if (node == null) {
            continue;
          }

          // If we already have an id, we match using the id. Otherwise, we match by content.
          // For the content matches we do not consider any nodes that already have a corresponding pending record, since there can only be one pending record per record.
          // We also ignore records that are already part of toDelete or newUpdates for the same reason.
          const newRecordIndex =
            typeof pendingRecord.id === 'number'
              ? node.records.findIndex((record) => _.isEqual(record.id, pendingRecord.id))
              : node.records.findIndex(
                  (record) =>
                    isEqualSoftNullIgnoreId(record, pendingRecord) &&
                    !pendingOptimisticRecords.current.some((onePending) =>
                      _.isEqual(onePending.pendingRecord.id, record.id),
                    ) &&
                    !toDelete.some((oneToDelete) => _.isEqual(oneToDelete.recordId, record.id)) &&
                    !newUpdates.some((oneToUpdate) => _.isEqual(oneToUpdate.record.id, record.id)),
                );

          if (newRecordIndex >= 0) {
            if (isEqualSoftNullIgnoreId(node.records[newRecordIndex], pendingRecord) && !wasDeleted) {
              if (waitingForDelete) {
                toDelete.push({ nodeType: node.type, recordId: node.records[newRecordIndex].id! });
              } else if (waitingRecord) {
                const recordForUpdate = produce(waitingRecord!, (draft) => {
                  draft.id = node.records[newRecordIndex].id;
                });
                newUpdates.push({ record: recordForUpdate, nodeType: node.type });
              }
              pendingOptimisticRecords.current.splice(i, 1);
              i -= 1; // Ensure that we do not miss any entries after deleting the current one.
            } else if (wasDeleted || waitingForDelete) {
              node.records.splice(newRecordIndex, 1);
            } else {
              node.records[newRecordIndex] = castDraft(waitingRecord ?? pendingRecord);
            }
          } else {
            if (!wasDeleted && !waitingForDelete) {
              node.records.push(castDraft(waitingRecord ?? pendingRecord));
            }
          }
        }
      });

      nodesRef.current = optimisticServerNodes;
      for (const update of newUpdates) {
        updateOrCreateRecord(update.record, update.nodeType);
      }
      for (const { nodeType, recordId } of toDelete) {
        deleteRecord(recordId, nodeType);
      }
    }
  }, [serverNodes, updateOrCreateRecord, deleteRecord, nodesRef]);

  return (
    <ReportsAPIContext.Provider value={reportsAPIContextValue}>
      <SnackbarAlert
        open={showWarning}
        onClose={() => setShowWarning(false)}
        severity={'warning'}
        message={warningMessage}
      />
      {children}
    </ReportsAPIContext.Provider>
  );
}
