import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { Typography } from '@mui/material';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import {
  EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
  ExtendedOperationalDataRecord,
  getMissionTypeLabelTuplesFor,
  MissionTypeKeys,
} from '../../../../models/genericElements/operationalData/extendedOperationalData';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface MissionTypeProps {
  nodeType: NodeType;
}

const firstLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.primaereinsatz,
  MissionTypeKeys.folgeeinsatz,
  MissionTypeKeys.fehleinsatz,
  MissionTypeKeys.notarztnachforderung,
  MissionTypeKeys.sekundaereinsatz,
  MissionTypeKeys.bereitstellung,
]);

const secondLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.keinpatient,
  MissionTypeKeys.abbestellt,
  MissionTypeKeys.abtransportiert,
  MissionTypeKeys.boeswillig,
]);

const thirdLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.technisch,
  MissionTypeKeys.wetter,
  MissionTypeKeys.sonstiges,
]);

export default function MissionType({ nodeType }: MissionTypeProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedOperationalDataRecord;

  const selectedMissionType =
    record.inputState === InputState.ENTERED ? (record.values?.missionType?.option ?? []) : [];

  const selectedMissionFailure =
    record.inputState === InputState.ENTERED ? (record.values?.missionFailure?.option ?? []) : [];

  function changeMissionType(value: string | null) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedOperationalDataRecord>, deleteRecord) => {
        if (value !== MissionTypeKeys.fehleinsatz) {
          delete draft?.values?.missionFailure;
        }
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            missionType: {
              fieldType: 'singleSelect',
              option: value as MissionTypeKeys,
            },
          };
        } else {
          delete draft.values?.missionType;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  }

  function changeMissionFailure(value: string | null) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedOperationalDataRecord>) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            missionType: {
              fieldType: 'singleSelect',
              option: MissionTypeKeys.fehleinsatz,
            },
            missionFailure: {
              fieldType: 'singleSelect',
              option: value as MissionTypeKeys,
            },
          };
        } else {
          delete draft.values?.missionFailure;
        }
      },
      EXTENDED_OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.MissionType}>
      <div className='symptoms-list'>
        <RadioList items={firstLevelRadioItems.slice(0, 3)} value={selectedMissionType} onChange={changeMissionType} />
        <div className='indented'>
          <RadioList items={secondLevelRadioItems} value={selectedMissionFailure} onChange={changeMissionFailure} />
          <Typography variant='body1' className='list-subheading'>
            {' '}
            Einsatzabbruch
          </Typography>
          <div className='indented'>
            <RadioList items={thirdLevelRadioItems} value={selectedMissionFailure} onChange={changeMissionFailure} />
          </div>
        </div>
        <RadioList items={firstLevelRadioItems.slice(3)} value={selectedMissionType} onChange={changeMissionType} />
      </div>
    </DiviFieldCard>
  );
}
