import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { Dayjs } from 'dayjs';
import { CheckCircleOutline } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './/AddVitalParamDialog.scss?inline';
import WarningIcon from '@mui/icons-material/Warning';
import { TrendInfoMap } from '../../../../models/trends';
import { hhmmToCorrectedDateJS } from '../../../../utils/util';
import { TimePicker } from '../../../customs/timePicker/TimePicker';

export interface AddVitalParamDialogResponse {
  timestamp: Dayjs;
  puls: number | null;
  nibpSys?: number | null;
  nibpDia?: number | null;
  spo2?: number | null;
  etco2?: number | null;
}

interface AddVitalParamDialogProps {
  isDialogOpen: boolean;
  onOpenChanged: (open: boolean) => void;
  onConfirm: (vitalParam: AddVitalParamDialogResponse) => void;
  manualData: TrendInfoMap;
}

export function AddVitalParamDialog({ isDialogOpen, onOpenChanged, onConfirm, manualData }: AddVitalParamDialogProps) {
  useCSS(style);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [pulsValue, setPulsValue] = useState<number | null>(null);
  const [nibpSysValue, setNibpSysValue] = useState<number | null>(null);
  const [nibpDiaValue, setNibpDiaValue] = useState<number | null>(null);
  const [spo2Value, setSpo2Value] = useState<number | null>(null);
  const [etco2Value, setEtco2Value] = useState<number | null>(null);

  const onPulsValueChanged = (value: string) => {
    setPulsValue(value !== '' ? Number(value) : null);
  };
  const onNibpSysValueChanged = (value: string) => {
    setNibpSysValue(value !== '' ? Number(value) : null);
  };
  const onNibpDiaValueChanged = (value: string) => {
    setNibpDiaValue(value !== '' ? Number(value) : null);
  };
  const onSpo2ValueChanged = (value: string) => {
    setSpo2Value(value !== '' ? Number(value) : null);
  };
  const onEtco2ValueChanged = (value: string) => {
    setEtco2Value(value !== '' ? Number(value) : null);
  };

  const [timestamp, setTimestamp] = useState('');

  function handleClose() {
    onOpenChanged(false);
    setTimeout(() => {
      setPulsValue(null);
      setNibpSysValue(null);
      setNibpDiaValue(null);
      setSpo2Value(null);
      setEtco2Value(null);
      setTimestamp('');
      setIsConfirmed(false);
    }, 200);
  }

  function handleAdd() {
    if (!isAddable()) {
      return;
    }

    onConfirm({
      timestamp: hhmmToCorrectedDateJS(timestamp),
      puls: pulsValue,
      nibpSys: nibpSysValue,
      nibpDia: nibpDiaValue,
      spo2: spo2Value,
      etco2: etco2Value,
    });

    setIsConfirmed(true);
    setTimeout(handleClose, 1000);
  }

  function isAddable(): boolean {
    return (
      timestamp !== '' &&
      !manualData.has(timestamp) &&
      isNibpValid() &&
      (pulsValue != null || etco2Value != null || spo2Value != null || nibpSysValue != null || nibpDiaValue != null)
    );
  }

  function isNibpValid() {
    return (nibpSysValue == null) == (nibpDiaValue == null);
  }

  return (
    <Dialog open={isDialogOpen} className='add-trends-dialog' closeAfterTransition={false}>
      <DialogTitle>Vitalparameter hinzufügen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-trends-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div>
            <TimePicker label='Zeitpunkt' required={true} value={timestamp} onChange={setTimestamp} />
            <DebouncedTextField
              fullWidth
              type='number'
              label='Puls [1/min]'
              variant='outlined'
              value={pulsValue?.toString() ?? ''}
              onDebounceChange={onPulsValueChanged}
              debounceTime={10}
              inputProps={{ step: 1, min: 1 }}
            />
            <div className='row'>
              <DebouncedTextField
                fullWidth
                type='number'
                label='NIBP [mmHg]'
                variant='outlined'
                value={nibpSysValue?.toString() ?? ''}
                onDebounceChange={onNibpSysValueChanged}
                debounceTime={10}
                inputProps={{ step: 1, min: 1 }}
                error={!!nibpDiaValue && nibpSysValue == null}
              />
              <Typography className='center-slash'>/</Typography>
              <DebouncedTextField
                fullWidth
                type='number'
                label='NIBP [mmHg]'
                variant='outlined'
                value={nibpDiaValue?.toString() ?? ''}
                onDebounceChange={onNibpDiaValueChanged}
                debounceTime={10}
                inputProps={{ step: 1, min: 1 }}
                error={!!nibpSysValue && nibpDiaValue == null}
              />
            </div>
            <DebouncedTextField
              fullWidth
              type='number'
              label={
                <>
                  SpO<sub>2</sub> [%]
                </>
              }
              variant='outlined'
              value={spo2Value?.toString() ?? ''}
              onDebounceChange={onSpo2ValueChanged}
              debounceTime={10}
              inputProps={{ step: 1, min: 1 }}
            />
            <DebouncedTextField
              fullWidth
              type='number'
              label='etCO2 [mmHg]'
              variant='outlined'
              value={etco2Value?.toString() ?? ''}
              onDebounceChange={onEtco2ValueChanged}
              debounceTime={10}
              inputProps={{ step: 1, min: 1 }}
            />
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          {timestamp === '' ? (
            <Tooltip arrow title='Bitte geben Sie einen Zeitpunkt an.'>
              <WarningIcon color='warning' />
            </Tooltip>
          ) : manualData.has(timestamp) ? (
            <Tooltip
              arrow
              title='Für den gewünschten Zeitpunkt ist bereits ein Eintrag vorhanden. Bitte passen Sie den Zeitpunkt an oder löschen Sie den bereits vorhanden Eintrag.'
            >
              <WarningIcon color='warning' />
            </Tooltip>
          ) : (
            !isNibpValid() && (
              <Tooltip
                arrow
                title='NIBP ist nicht vollständig befüllt. Bitte befüllen sie beide Werte, sofern sie eine Eintragung wünschen.'
              >
                <WarningIcon color='warning' />
              </Tooltip>
            )
          )}
          <Button className='cancel-button' variant='outlined' onClick={handleClose}>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} autoFocus disabled={!isAddable()} variant='contained'>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
