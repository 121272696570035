import { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogContent, IconButton, TextField } from '@mui/material';
import style from './AddTimerDialog.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { Add, CheckCircleOutline, PlayCircle } from '@mui/icons-material';
import dayjs from 'dayjs';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { TimePicker } from '../../../customs/timePicker/TimePicker';

interface AddTimerDialogProps {
  suggestedName: string;
  correlatedRecordId?: number;
  open: boolean;
  onOpenChanged: (open: boolean) => void;
}

export function AddTimerDialog({ open, onOpenChanged, suggestedName, correlatedRecordId }: AddTimerDialogProps) {
  useCSS(style);

  const reportsApi = useContext(ReportsAPIContext);

  const [name, setName] = useState<string | null>(null);
  const [duration, setDuration] = useState('00:00:00');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const durationSplit = duration.split(':');
  const hour = Number(durationSplit[0] ?? 0);
  const minute = Number(durationSplit[1] ?? 0);
  const second = Number(durationSplit[2] ?? 0);
  const totalSeconds = hour * 3600 + minute * 60 + second;

  const isValidDuration = duration !== '' && totalSeconds > 0;
  const isPlus1ValidDuration = duration !== '' && totalSeconds + 60 < 24 * 3600;

  const add1Min = () => {
    const newHour = Math.floor((totalSeconds + 60) / 3600);
    const newMinute = Math.floor((totalSeconds + 60) / 60) % 60;

    setDuration(
      `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`,
    );
  };

  const close = () => {
    onOpenChanged(false);

    setTimeout(() => {
      setName(null);
      setDuration('00:00:00');
      setIsConfirmed(false);
    }, 200);
  };

  const createTimer = () => {
    if (isValidDuration && reportsApi.startTimer) {
      reportsApi.startTimer(name ?? suggestedName, dayjs().add(totalSeconds, 's').valueOf(), totalSeconds, {
        recordId: correlatedRecordId,
      });
      setIsConfirmed(true);

      setTimeout(close, 1000);
    }
  };

  return (
    <Dialog open={open} onClose={close} className='add-timer-dialog' closeAfterTransition={false}>
      <DialogTitle>Timer anlegen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-timer-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div className='add-timer-dialog-grid'>
            <TextField
              className='textfield-name'
              label='Name'
              value={name ?? suggestedName}
              onChange={(event) => setName(event.target.value)}
            ></TextField>
            <div className='textfield-duration'>
              <TimePicker label='Dauer (hh:mm:ss)' value={duration} onChange={setDuration} required={true} step={1} />
            </div>
            <Button
              className='button-add-1min'
              variant='outlined'
              startIcon={<Add />}
              disabled={!isPlus1ValidDuration}
              onClick={add1Min}
            >
              1 min
            </Button>
            <IconButton className='button-create' color='primary' disabled={!isValidDuration} onClick={createTimer}>
              <PlayCircle className='icon' />
            </IconButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
