import _ from 'lodash';
import { ManualEventRecord } from '../genericElements/events/manualEvent';

export enum PredefinedEvent {
  amiodaron = 'Amiodaron',
  defibrillation = 'Defibrillation',
  extubation = 'Extubation',
  hdm = 'Start HDM',
  intubation = 'Intubation',
  suprarenin = 'Suprarenin',
  transportStart = 'Transport Start',
  transportEnd = 'Transport Ende',
  arrivedAtPatient = 'Ankunft an Patient',
  firstCommunication = 'Erstkontakt Patient',
}

export const predefinedEventsWithQuickButton = new Set([
  PredefinedEvent.defibrillation,
  PredefinedEvent.extubation,
  PredefinedEvent.hdm,
  PredefinedEvent.intubation,
  PredefinedEvent.transportStart,
  PredefinedEvent.transportEnd,
  PredefinedEvent.arrivedAtPatient,
  PredefinedEvent.firstCommunication,
]);

// These events are should only exist once. If there are multiple only the one with the highest id wins.
export const uniqueEvents = [
  PredefinedEvent.transportStart,
  PredefinedEvent.transportEnd,
  PredefinedEvent.arrivedAtPatient,
  PredefinedEvent.firstCommunication,
];

// Remove duplicate unique events inplace by only keeping the one with the largest id.
export function removeDuplicateUniquePredefinedEvents(eventRecords: ManualEventRecord[]) {
  for (const uniqueEvent of uniqueEvents) {
    const eventsOfType = eventRecords.filter((record) => record.values?.eventName?.text === uniqueEvent);
    if (eventsOfType.length > 1) {
      _.remove(eventRecords, (record) => record.values?.eventName?.text === uniqueEvent);
      eventRecords.push(
        eventsOfType.reduce((maxRecord, currentRecord) =>
          (currentRecord?.id ?? -Infinity) > (maxRecord?.id ?? -Infinity) ? currentRecord : maxRecord,
        ),
      );
    }
  }
}
