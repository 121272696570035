import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './InjuriesAccidentMechanism.scss?inline';
import { NodeType } from '../../../../backendModels/report.model';
import {
  getInjuriesAdditionTuplesFor,
  INJURIES_ADDITION_RECORD_ELEMENT_KEY,
  InjuriesAdditionKeys,
  InjuriesAdditionRecord,
} from '../../../../models/genericElements/injuries/injuriesAdditional';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const traumaItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.traumastumpf,
  InjuriesAdditionKeys.traumapenetrierend,
]);

const fallItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.sturzebenerdig,
  InjuriesAdditionKeys.sturzunter3M,
  InjuriesAdditionKeys.sturzueber3M,
]);

const roadUsersItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.fussgaenger,
  InjuriesAdditionKeys.escooter,
  InjuriesAdditionKeys.fahrrad,
  InjuriesAdditionKeys.ebike,
  InjuriesAdditionKeys.motorrad,
  InjuriesAdditionKeys.pkw,
  InjuriesAdditionKeys.lkw,
  InjuriesAdditionKeys.bus,
]);

const useOfViolenceItems = getInjuriesAdditionTuplesFor([
  InjuriesAdditionKeys.schlag,
  InjuriesAdditionKeys.schuss,
  InjuriesAdditionKeys.stich,
  InjuriesAdditionKeys.gewaltverbrechen,
  InjuriesAdditionKeys.gewaltanwendungsonstige,
]);

interface InjuryAccidentMechanismProps {
  nodeType: NodeType;
}

export default function InjuriesAccidentMechanism({ nodeType }: InjuryAccidentMechanismProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    INJURIES_ADDITION_RECORD_ELEMENT_KEY,
  ) as InjuriesAdditionRecord;

  const changeFall = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            sturz: {
              fieldType: 'singleSelect',
              option: value as InjuriesAdditionKeys,
            },
          };
        } else {
          delete draft.values?.sturz;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  const changeTrauma = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            unfallmechanismus: {
              fieldType: 'singleSelect',
              option: value as InjuriesAdditionKeys,
            },
          };
        } else {
          delete draft.values?.unfallmechanismus;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  const changeRoadUsers = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            verkehrsteilnehmer: {
              fieldType: 'singleSelect',
              option: value as InjuriesAdditionKeys,
            },
          };
        } else {
          delete draft.values?.verkehrsteilnehmer;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  const changeUseOfViolence = (values: InjuriesAdditionKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesAdditionRecord>, deleteRecord) => {
        if (values.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            gewaltanwendung: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        } else {
          delete draft.values?.gewaltanwendung;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.InjuryAccidentMechanism}
      nodeType={nodeType}
      recordType='generic'
      elementKey={INJURIES_ADDITION_RECORD_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, INJURIES_ADDITION_RECORD_ELEMENT_KEY)
      }
    >
      <div className='row symptoms-list'>
        <div>
          <div className='trauma'>
            <Typography>Trauma</Typography>
            <div className='indented'>
              <RadioList
                items={traumaItems}
                onChange={changeTrauma}
                value={
                  record.inputState === InputState.ENTERED ? (record.values.unfallmechanismus?.option ?? null) : null
                }
              />
            </div>
          </div>
          <div className='fall add-margin-top'>
            <Typography>Sturz</Typography>
            <div className='indented'>
              <RadioList
                items={fallItems}
                onChange={changeFall}
                value={record.inputState === InputState.ENTERED ? (record.values.sturz?.option ?? null) : null}
              />
            </div>
          </div>
        </div>
        <div className='road-user'>
          <Typography>Verkehrsteilnehmer</Typography>
          <div className='indented'>
            <RadioList
              items={roadUsersItems}
              onChange={changeRoadUsers}
              value={
                record.inputState === InputState.ENTERED ? (record.values.verkehrsteilnehmer?.option ?? null) : null
              }
            />
          </div>
        </div>
        <div className='use-of-violence'>
          <Typography>Gewaltanwendung</Typography>
          <div className='indented'>
            <CheckboxList
              items={useOfViolenceItems}
              onValuesChange={changeUseOfViolence}
              selectedValues={
                record.inputState === InputState.ENTERED ? (record.values?.gewaltanwendung?.options ?? []) : []
              }
            />
          </div>
        </div>
      </div>
    </DiviFieldCard>
  );
}
