import React, { useCallback, useContext, useEffect } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { NodeType } from '../../../../backendModels/report.model';
import {
  CONTROL_CENTER_RECORD_ELEMENT_KEY,
  ControlCenterPropertyKeys,
  ControlCenterRecord,
} from '../../../../models/genericElements/controlCenter';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import _ from 'lodash';
import { isGenericRecordDeletable } from '../../../../models/generic';

export interface MissionProps {
  nodeType: NodeType;
}

export default function Mission({ nodeType }: MissionProps) {
  const { findRecordOrDefault, findNodes, adaptRecord, controlCenter } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, CONTROL_CENTER_RECORD_ELEMENT_KEY) as ControlCenterRecord;

  function handleUntouchedTextFieldUpdates<T extends ControlCenterPropertyKeys>(
    draft: Draft<ControlCenterRecord>,
    property: T,
    value: string | null | undefined,
  ): void {
    if (!draft.values?.touched?.options.includes(property)) {
      if (value != null && value !== '') {
        draft.values = {
          ...draft.values,
          [property]: {
            text: value,
            fieldType: 'text',
          },
        };
      } else {
        delete draft.values?.[property];
      }
    }
  }

  useEffect(() => {
    if (controlCenter != null && findNodes(nodeType).length > 0) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<ControlCenterRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedTextFieldUpdates(draft, ControlCenterPropertyKeys.missionNumber, controlCenter.missionNumber);
          handleUntouchedTextFieldUpdates(draft, ControlCenterPropertyKeys.location, controlCenter.location);
          handleUntouchedTextFieldUpdates(
            draft,
            ControlCenterPropertyKeys.personnelNumber,
            controlCenter.personnelNumber,
          );
          handleUntouchedTextFieldUpdates(draft, ControlCenterPropertyKeys.controlCenter, controlCenter.controlCenter);

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        CONTROL_CENTER_RECORD_ELEMENT_KEY,
      );
    }
  }, [controlCenter, adaptRecord, findNodes, nodeType]);

  const handleChangedProperty = useFreshCallback((property, value) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ControlCenterRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (!_.isEqual(value, draft.values?.[property])) {
          draft.values = {
            ...draft.values,
            touched: { options: _.union([property], draft.values?.touched?.options ?? []), fieldType: 'multiSelect' },
          };
        }

        if (value != null) {
          draft.values = {
            ...draft.values,
            [property]: value,
          };
        } else {
          delete draft.values?.[property];
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      CONTROL_CENTER_RECORD_ELEMENT_KEY,
    );
  });

  const getTouchedClassName = useCallback(
    (property: ControlCenterPropertyKeys) => {
      return !record.values?.touched?.options.includes(property) && record.values?.[property] != null
        ? 'prefilled'
        : '';
    },
    [record],
  );

  return (
    <DiviFieldCard cardType={CardId.Mission}>
      <div className='row'>
        <DebouncedTextField
          className={getTouchedClassName(ControlCenterPropertyKeys.missionNumber)}
          label='Einsatz Nr.'
          value={record.values?.missionNumber?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedProperty(
              ControlCenterPropertyKeys.missionNumber,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(ControlCenterPropertyKeys.location)}
          label='Standort'
          value={record.values?.location?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedProperty(
              ControlCenterPropertyKeys.location,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(ControlCenterPropertyKeys.personnelNumber)}
          label='Pers. Nr.'
          value={record.values?.personnelNumber?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedProperty(
              ControlCenterPropertyKeys.personnelNumber,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(ControlCenterPropertyKeys.controlCenter)}
          label='Leitstelle (KFZ-Kennz.)'
          value={record.values?.controlCenter?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedProperty(
              ControlCenterPropertyKeys.controlCenter,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
      </div>
    </DiviFieldCard>
  );
}
