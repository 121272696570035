import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl, Typography } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Defibrillation.scss?inline';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  DEFIBRILLATION_RECORD_ELEMENT_KEY,
  DefibrillationKeys,
  DefibrillationRecord,
  getDefibrillationTuplesFor,
} from '../../../../models/genericElements/treatments/defibrillation';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { isGenericRecordDeletable } from '../../../../models/generic';
import RadioList from '../../../customs/radioList/RadioList';
import _ from 'lodash';
import { TimePicker } from '../../../customs/timePicker/TimePicker';

const checkboxAedItem: [DefibrillationKeys, string][] = getDefibrillationTuplesFor([
  DefibrillationKeys.defibrillationaed,
]);

const checkboxDefiItem: [DefibrillationKeys, string][] = getDefibrillationTuplesFor([
  DefibrillationKeys.defibrillationdefi,
]);

const firstUseItems: [DefibrillationKeys, string][] = getDefibrillationTuplesFor([
  DefibrillationKeys.defibrillationerstanwendunglaien,
  DefibrillationKeys.defibrillationerstanwendungfirstresponder,
  DefibrillationKeys.defibrillationerstanwendungrd,
  DefibrillationKeys.defibrillationerstanwendungarzt,
]);

const impulseTypeItems: [DefibrillationKeys, string][] = getDefibrillationTuplesFor([
  DefibrillationKeys.defibrillationimpulstypmono,
  DefibrillationKeys.defibrillationimpulstypbi,
]);

interface DefibrillationProps {
  nodeType: NodeType;
}

export default function Defibrillation({ nodeType }: DefibrillationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, DEFIBRILLATION_RECORD_ELEMENT_KEY) as DefibrillationRecord;

  const types = record.inputState === InputState.ENTERED ? (record.values.defibrillationTyp?.options ?? []) : [];
  const firstUse =
    record.inputState === InputState.ENTERED ? (record.values.defibrillationErstanwendung?.option ?? []) : [];
  const impulse = record.inputState === InputState.ENTERED ? (record.values.defibrillationImpulsTyp?.option ?? []) : [];
  const timeShockString =
    record.inputState == InputState.ENTERED ? (record.values.defibrillationErsterSchock?.text ?? '') : '';
  const timeRoscString =
    record.inputState == InputState.ENTERED ? (record.values.defibrillationErsterRosc?.text ?? '') : '';

  const changeType = (values: DefibrillationKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length <= 0) {
          deleteRecord();
        }
        if (!values.includes(DefibrillationKeys.defibrillationaed)) {
          delete draft.values?.defibrillationErstanwendung;
        } else if (!values.includes(DefibrillationKeys.defibrillationdefi)) {
          delete draft.values?.defibrillationImpulsTyp;
          delete draft.values?.defibrillationErsterSchock;
          delete draft.values?.defibrillationErsterRosc;
          delete draft.values?.defibrillationAnzahlSchocks;
          delete draft.values?.defibrillationMaxEnergie;
        }
        draft.values = {
          ...draft.values,
          defibrillationTyp: {
            fieldType: 'multiSelect',
            options: values,
          },
        };
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeFirstUse = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            defibrillationErstanwendung: {
              fieldType: 'singleSelect',
              option: value as DefibrillationKeys,
            },
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(draft.values?.defibrillationTyp?.options, [DefibrillationKeys.defibrillationaed]),
            },
          };
        } else {
          delete draft.values?.defibrillationErstanwendung;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeImpulsType = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            defibrillationImpulsTyp: {
              fieldType: 'singleSelect',
              option: value as DefibrillationKeys,
            },
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(draft.values?.defibrillationTyp?.options, [DefibrillationKeys.defibrillationdefi]),
            },
          };
        } else {
          delete draft.values?.defibrillationImpulsTyp;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeTimeShock = (newTime: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newTime !== '') {
          draft.values = {
            ...draft.values,
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(types, [DefibrillationKeys.defibrillationdefi]),
            },
            defibrillationErsterSchock: {
              fieldType: 'text',
              text: newTime,
            },
          };
        } else {
          delete draft.values?.defibrillationErsterSchock;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeTimeRosc = (newTime: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newTime !== '') {
          draft.values = {
            ...draft.values,
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(types, [DefibrillationKeys.defibrillationdefi]),
            },
            defibrillationErsterRosc: {
              fieldType: 'text',
              text: newTime,
            },
          };
        } else {
          delete draft.values?.defibrillationErsterRosc;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeDefiNumber = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(types, [DefibrillationKeys.defibrillationdefi]),
            },
            defibrillationAnzahlSchocks: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.defibrillationAnzahlSchocks;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeEnergy = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DefibrillationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            defibrillationTyp: {
              fieldType: 'multiSelect',
              options: _.union(types, [DefibrillationKeys.defibrillationdefi]),
            },
            defibrillationMaxEnergie: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.defibrillationMaxEnergie;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      DEFIBRILLATION_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.TreatmentsDefibrillation}>
      <div className='measures-defibrillation symptoms-list'>
        <FormControl fullWidth>
          <div className='row two-row'>
            <div>
              <CheckboxList items={checkboxAedItem} selectedValues={types} onValuesChange={changeType} />
              <div className='indented'>
                <Typography className='no-margin-top subheader'>Erstanwendung</Typography>
                <RadioList
                  name='measuresdefibrillation-aed-group'
                  items={firstUseItems}
                  value={firstUse}
                  onChange={changeFirstUse}
                />
              </div>
            </div>
            <div>
              <CheckboxList items={checkboxDefiItem} selectedValues={types} onValuesChange={changeType} />
              <div className='indented'>
                <RadioList
                  name='measures-defibrillation-defi-group'
                  items={impulseTypeItems}
                  value={impulse}
                  onChange={changeImpulsType}
                />
              </div>
              <div className='defibrillation-wireframe no-margin-top'>
                <TimePicker label='1. Defibrillation (Zeitpunkt)' value={timeShockString} onChange={changeTimeShock} />
                <TimePicker label='1. ROSC (Zeitpunkt)' value={timeRoscString} onChange={changeTimeRosc} />
                <DebouncedTextField
                  fullWidth
                  type='number'
                  inputProps={{ step: 1, min: 1 }}
                  label='Anzahl Defi insgesamt'
                  variant='outlined'
                  value={
                    record.inputState === InputState.ENTERED
                      ? (record.values.defibrillationAnzahlSchocks?.number.toString() ?? '')
                      : ''
                  }
                  onDebounceChange={changeDefiNumber}
                />
                <DebouncedTextField
                  fullWidth
                  type='number'
                  inputProps={{ step: 1, min: 1 }}
                  label='Energie max (Joule)'
                  variant='outlined'
                  value={
                    record.inputState === InputState.ENTERED
                      ? (record.values.defibrillationMaxEnergie?.number.toString() ?? '')
                      : ''
                  }
                  onDebounceChange={changeEnergy}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </div>
    </DiviFieldCard>
  );
}
