import { Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import { TrendParameter, TrendSelection } from '../../../../../models/trendsRecord';
import { VitalParameterCode } from '../../../../../backendModels/trends.model';

export type ExtendedTrendEntry =
  | { timestamp: string; isManuallyAdded: false; originalIndex: number; parameters: TrendParameter[] }
  | { timestamp: string; isManuallyAdded: true; parameters: { type: VitalParameterCode; value: number }[] };

interface CellTemplateProps {
  trendInfo: ExtendedTrendEntry;
  manualTrendInfo?: { parameters: { type: VitalParameterCode; value: number }[] };
  updateChecked: (index: number, trendTypes: VitalParameterCode[], checked: boolean) => void;
  trendTypes: VitalParameterCode[];
}

export default function CellTemplate({ trendInfo, manualTrendInfo, updateChecked, trendTypes }: CellTemplateProps) {
  const hasValue = trendTypes.some((oneTrendType) =>
    trendInfo.parameters.some((oneParameter) => oneParameter.type === oneTrendType),
  );

  const hasManualMatch = trendTypes.some((oneTrendType) =>
    manualTrendInfo?.parameters.some((oneParameter) => oneParameter.type === oneTrendType),
  );

  let checkboxNode: ReactNode;

  if (trendInfo.isManuallyAdded) {
    checkboxNode = <CheckIcon className={`trends-check-icon`} color='primary' />;
  } else {
    const isAutomaticChecked = trendTypes.some((oneTrendType) =>
      trendInfo.parameters.some(
        (oneParameter) =>
          oneParameter.type === oneTrendType && oneParameter.selection === TrendSelection.parameterIsSelected,
      ),
    );

    checkboxNode = (
      <Checkbox
        checked={isAutomaticChecked && !hasManualMatch}
        disabled={hasManualMatch}
        onChange={(event) => updateChecked(trendInfo.originalIndex, trendTypes, event.target.checked)}
      />
    );
  }

  return (
    <TableCell>
      <div className='trends-table-cell'>
        <div className={hasValue ? '' : 'hide-check'}>{checkboxNode}</div>
        <div className={!trendInfo.isManuallyAdded && hasManualMatch ? 'overwritten-text' : ''}>
          {trendTypes
            .map(
              (oneTrendType) =>
                trendInfo.parameters
                  .find((oneParameter) => oneParameter.type === oneTrendType)
                  ?.value?.toLocaleString() ?? '--',
            )
            .join(' / ')}
        </div>
      </div>
    </TableCell>
  );
}
