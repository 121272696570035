import React, { useContext, useMemo } from 'react';
import { createTheme, Divider, ThemeProvider } from '@mui/material';
import corpulsTheme from '../Theme/CorpulsTheme';
import style from './App.scss';
import globalStyle from '../global.scss';
import DiviNavigation from './DiviNavigation/DiviNavigation';
import HeaderBar from './HeaderBar/HeaderBar';
import { PageRouter } from './Pages/PageRouter';
import { CSSContext, useCSS } from '../provider/CSSProvider';
import _ from 'lodash';
import SideBar from './SideBar/SideBar';

function App() {
  useCSS(style);
  useCSS(globalStyle);

  const { additionalThemeComponents } = useContext(CSSContext);

  const theme = useMemo(
    () => createTheme({ ...corpulsTheme, components: _.merge(corpulsTheme.components!, additionalThemeComponents) }),
    [additionalThemeComponents],
  );

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <div className='wireframe-container'>
          <div className='header'>
            <HeaderBar />
          </div>
          <div className='body-content'>
            <div className='navigation'>
              <DiviNavigation />
            </div>
            <div className='center-area'>
              <PageRouter />
            </div>
            <div className='right-content'>
              <SideBar />
              <Divider variant='fullWidth' />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
