import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getLyseTherapyTuplesFor,
  LYSE_THERAPY_RECORD_ELEMENT_KEY,
  LyseTherapyKeys,
  LyseTherapyRecord,
} from '../../../../models/genericElements/lysetherapy';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import { TimePicker } from '../../../customs/timePicker/TimePicker';

const lyseTherapyItems = getLyseTherapyTuplesFor([
  LyseTherapyKeys.lysetherapieestartvorstillstand,
  LyseTherapyKeys.lysetherapiestartwaehrendstillstand,
  LyseTherapyKeys.lysetherapiestartnachrosc,
]);

interface LysisTherapyProps {
  nodeType: NodeType;
}

export default function LysisTherapy({ nodeType }: LysisTherapyProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, LYSE_THERAPY_RECORD_ELEMENT_KEY) as LyseTherapyRecord;

  const timeString = record.inputState == InputState.ENTERED ? (record.values.lyseTherapieStartZeit?.text ?? '') : '';

  const changeStart = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<LyseTherapyRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            lyseTherapieStart: {
              fieldType: 'singleSelect',
              option: value as LyseTherapyKeys,
            },
          };
        } else {
          delete draft.values?.lyseTherapieStart;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      LYSE_THERAPY_RECORD_ELEMENT_KEY,
    );
  };

  const handleStartTime = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<LyseTherapyRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            lyseTherapieStartZeit: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.lyseTherapieStartZeit;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      LYSE_THERAPY_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.LysisTherapy}>
      <div className='symptoms-list'>
        <RadioList
          items={lyseTherapyItems}
          name='lysis-group'
          value={record.inputState === InputState.ENTERED ? (record.values.lyseTherapieStart?.option ?? null) : null}
          onChange={changeStart}
        />
      </div>
      <TimePicker label='Beginn' value={timeString} onChange={handleStartTime}></TimePicker>
    </DiviFieldCard>
  );
}
