import React, { useContext, useEffect } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import {
  getMissionLocationNameTuplesFor,
  LocationPropertyKeys,
  mapStringToMissionLocationKey,
  MissionLocationKeys,
  OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
  OperationalDataLocationRecord,
} from '../../../../models/genericElements/operationalData/missionLocation';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import { OperationalDataTransportLocationRecord } from '../../../../models/genericElements/operationalData/transportLocation';
import {
  changePropertyAndMarkTouched,
  getTouchedClassName,
  handleUntouchedSingleSelectFieldUpdates,
  handleUntouchedTextFieldUpdates,
} from '../../../../utils/recordWithTouched';
import { NUMBER_LANGUAGE } from '../../../../utils/util';

const locationItems = getMissionLocationNameTuplesFor([
  MissionLocationKeys.wohnung,
  MissionLocationKeys.altenheim,
  MissionLocationKeys.arbeitsplatz,
  MissionLocationKeys.sportstaette,
  MissionLocationKeys.arztpraxis,
  MissionLocationKeys.krankenhaus,
  MissionLocationKeys.geburtshaus,
  MissionLocationKeys.oeffentlicherraum,
  MissionLocationKeys.strasse,
  MissionLocationKeys.schule,
  MissionLocationKeys.bildungseinrichtung,
  MissionLocationKeys.massenveranstaltung,
  MissionLocationKeys.sonstige,
]);

interface MissionLocationProps {
  nodeType: NodeType;
}

export default function MissionLocation({ nodeType }: MissionLocationProps) {
  const { findRecordOrDefault, adaptRecord, missionData, findNodes } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
  ) as OperationalDataLocationRecord;

  useEffect(() => {
    if (missionData != null && findNodes(nodeType).length > 0) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<OperationalDataTransportLocationRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedTextFieldUpdates(
            draft,
            LocationPropertyKeys.missionStreetGPSLatitude,
            missionData.missionLocation?.address?.street ??
              missionData.missionLocation?.position?.latitude?.toLocaleString(NUMBER_LANGUAGE),
          );
          handleUntouchedTextFieldUpdates(
            draft,
            LocationPropertyKeys.missionCityGPSLongitude,
            missionData.missionLocation?.address?.city ??
              missionData.missionLocation?.position?.longitude?.toLocaleString(NUMBER_LANGUAGE),
          );
          handleUntouchedTextFieldUpdates(
            draft,
            LocationPropertyKeys.missionPostcode,
            missionData.missionLocation?.address?.postalCode,
          );
          handleUntouchedSingleSelectFieldUpdates(
            draft,
            LocationPropertyKeys.missionLocationType,
            mapStringToMissionLocationKey(missionData.missionLocation?.type),
          );

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
      );
    }
  }, [missionData, findNodes, nodeType, adaptRecord]);

  function changeStreetOrLongitude(value: string) {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
      LocationPropertyKeys.missionStreetGPSLatitude,
      value !== '' ? { fieldType: 'text', text: value } : undefined,
    );
  }

  function changeCityOrLatitude(value: string) {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
      LocationPropertyKeys.missionCityGPSLongitude,
      value !== '' ? { fieldType: 'text', text: value } : undefined,
    );
  }

  function changePostalCode(value: string) {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
      LocationPropertyKeys.missionPostcode,
      value !== '' ? { fieldType: 'text', text: value } : undefined,
    );
  }

  const changeLocationType = useFreshCallback((value: string | null) => {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY,
      LocationPropertyKeys.missionLocationType,
      value != null ? { fieldType: 'singleSelect', option: value } : undefined,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.MissionLocation}>
      <DebouncedTextField
        label='Straße oder GPS-Breitengrad'
        className={`no-margin-top ${getTouchedClassName(record, LocationPropertyKeys.missionStreetGPSLatitude)}`}
        fullWidth
        value={record.values?.missionStreetGPSLatitude?.text ?? ''}
        onDebounceChange={changeStreetOrLongitude}
      />
      <DebouncedTextField
        label='Ort oder GPS-Längengrad'
        className={getTouchedClassName(record, LocationPropertyKeys.missionCityGPSLongitude)}
        fullWidth
        value={record.values?.missionCityGPSLongitude?.text ?? ''}
        onDebounceChange={changeCityOrLatitude}
      />
      <DebouncedTextField
        label='PLZ'
        className={getTouchedClassName(record, LocationPropertyKeys.missionPostcode)}
        fullWidth
        value={record.values?.missionPostcode?.text ?? ''}
        onDebounceChange={changePostalCode}
      />
      <RadioList
        className={`symptoms-list add-margin-top ${getTouchedClassName(record, LocationPropertyKeys.missionLocationType)}`}
        items={locationItems}
        value={record.values?.missionLocationType?.option ?? null}
        onChange={changeLocationType}
      />
    </DiviFieldCard>
  );
}
