import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './InjuriesActivity.scss?inline';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getInjuriesConnectionTuplesFor,
  INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
  InjuriesConnectionKeys,
  InjuriesConnectionRecord,
} from '../../../../models/genericElements/injuries/injuriesConnection';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import RadioList from '../../../customs/radioList/RadioList';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const injuryJobItems: [InjuriesConnectionKeys, string][] = getInjuriesConnectionTuplesFor([
  InjuriesConnectionKeys.verletzungzusammenhangarbeitja,
  InjuriesConnectionKeys.verletzungzusammenhangarbeitnein,
]);

const injurySportItems: [InjuriesConnectionKeys, string][] = getInjuriesConnectionTuplesFor([
  InjuriesConnectionKeys.verletzungzusammenhangsportja,
  InjuriesConnectionKeys.verletzungzusammenhangsportnein,
]);

interface InjuryActivityProps {
  nodeType: NodeType;
}

export default function InjuriesActivity({ nodeType }: InjuryActivityProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
  ) as InjuriesConnectionRecord;

  const handleChangeSport = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesConnectionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            verletzungZusammenhangSport: {
              fieldType: 'singleSelect',
              option: value as InjuriesConnectionKeys,
            },
          };
        } else {
          delete draft.values?.verletzungZusammenhangSport;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
    );
  };

  const handleChangeJob = (value: string | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesConnectionRecord>, deleteRecord) => {
        if (value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            verletzungZusammenhangArbeit: {
              fieldType: 'singleSelect',
              option: value as InjuriesConnectionKeys,
            },
          };
        } else {
          delete draft.values?.verletzungZusammenhangArbeit;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.InjuryActivity}
      nodeType={nodeType}
      recordType='generic'
      elementKey={INJURIES_CONNECTION_RECORD_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, INJURIES_CONNECTION_RECORD_ELEMENT_KEY)
      }
    >
      <div>
        <Typography variant='subtitle2'>Zusammenhang mit</Typography>
        <div key='radio-injury-sport' className='row two-row add-margin-top'>
          <Typography variant='subtitle2'>sportliche Aktivität</Typography>
          <RadioList
            items={injurySportItems}
            name='injury-sport-group'
            value={
              record.inputState === InputState.ENTERED
                ? (record.values.verletzungZusammenhangSport?.option ?? null)
                : null
            }
            onChange={handleChangeSport}
          />
        </div>
      </div>
      <div>
        <div key='radio-injury-job' className='row two-row'>
          <Typography variant='subtitle2'>berufliche Aktivität</Typography>
          <RadioList
            items={injuryJobItems}
            name='injury-job-group'
            value={
              record.inputState === InputState.ENTERED
                ? (record.values.verletzungZusammenhangArbeit?.option ?? null)
                : null
            }
            onChange={handleChangeJob}
          />
        </div>
      </div>
    </DiviFieldCard>
  );
}
