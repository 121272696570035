import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import style from './FirstResponder.scss';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  FIRST_RESPONDER_RECORD_ELEMENT_KEY,
  FirstResponderKeys,
  FirstResponderRecord,
  getFirstResponderTuple,
} from '../../../../models/genericElements/firstResponder';
import { NodeType } from '../../../../backendModels/report.model';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import dayjs from 'dayjs';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';

interface FirstResponderProps {
  nodeType: NodeType;
}

export default function FirstResponder({ nodeType }: FirstResponderProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, FIRST_RESPONDER_RECORD_ELEMENT_KEY) as FirstResponderRecord;

  const timeString = record.inputState == InputState.ENTERED ? record.values.firstResponderTime?.text ?? null : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  function handleFirstResponderCheckbox(value: FirstResponderKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<FirstResponderRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            firstResponder: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.firstResponder;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      FIRST_RESPONDER_RECORD_ELEMENT_KEY,
    );
  }

  const handleTimeInfo = useFreshCallback((newTimestamp: dayjs.Dayjs | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<FirstResponderRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newTimestamp != null && newTimestamp.isValid()) {
          draft.values = {
            ...draft.values,
            firstResponderTime: {
              fieldType: 'text',
              text: newTimestamp.format('HH:mm'),
            },
          };
        } else {
          delete draft.values?.firstResponderTime;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      FIRST_RESPONDER_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.FirstResponder}>
      <CheckboxList
        className='symptoms-list'
        items={[getFirstResponderTuple(FirstResponderKeys.firstrespondervorort)]}
        selectedValues={record.inputState === InputState.ENTERED ? record.values?.firstResponder?.options ?? [] : []}
        onValuesChange={handleFirstResponderCheckbox}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          className='time-of-arrival'
          label={getFirstResponderTuple(FirstResponderKeys.firstrespondertime)[1]}
          format='HH:mm'
          value={timeDayjs}
          onChange={handleTimeInfo}
        />
      </LocalizationProvider>
    </DiviFieldCard>
  );
}
